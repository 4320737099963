import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import { applyDataAttr, DropdownEvents, DropdownMode, DropdownSize, getDropdownPositionStyles, Icon, IconNames, useOnClickOutside, } from '@sovryn/ui';
import { useIsMobile } from '../../../../../hooks/useIsMobile';
import styles from './NavDropdown.module.css';
export const NavDropdown = forwardRef(({ text, children, mode = DropdownMode.sameWidth, size = DropdownSize.large, onOpen, onClose, className, dataAttribute, dropdownClassName, closeOnClick, active, }, ref) => {
    const { isMobile } = useIsMobile();
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isOpen, setOpen] = useState(!!active);
    const [coords, setCoords] = useState(null);
    useImperativeHandle(ref, () => buttonRef.current);
    const isAccordionActive = useMemo(() => isMobile && isOpen, [isMobile, isOpen]);
    const onButtonClick = useCallback(() => setOpen(prevValue => !prevValue), [setOpen]);
    const getCoords = useCallback(() => {
        var _a, _b;
        const button = (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const dropdownWidth = (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width;
        const windowWidth = document.body.getBoundingClientRect().width;
        if (button && dropdownWidth) {
            const { top, left, right, width, height } = button;
            return {
                top: top + height,
                left: left,
                right: right,
                buttonWidth: width,
                windowWidth: windowWidth,
                dropdownWidth: dropdownWidth,
            };
        }
        return null;
    }, []);
    const dropdownStyles = useMemo(() => {
        if (coords) {
            return getDropdownPositionStyles(coords, mode);
        }
        if (isAccordionActive) {
            return {};
        }
    }, [coords, isAccordionActive, mode]);
    const classNamesComplete = useMemo(() => classNames(styles.button, styles[size], className, {
        [styles.isOpen]: isOpen,
        [styles.isActive]: isAccordionActive,
    }), [size, className, isOpen, isAccordionActive]);
    const useClickedOutside = useCallback(() => {
        if (!isMobile) {
            setOpen(false);
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    }, [onClose, isMobile]);
    useOnClickOutside([buttonRef, dropdownRef], useClickedOutside);
    const updateCoords = useCallback(() => setCoords(getCoords()), [getCoords, setCoords]);
    useEffect(() => {
        Object.values(DropdownEvents).forEach(event => window.addEventListener(event, updateCoords));
        return () => {
            Object.values(DropdownEvents).forEach(event => window.removeEventListener(event, updateCoords));
        };
    }, [updateCoords]);
    useEffect(() => {
        if (isOpen) {
            updateCoords();
            onOpen === null || onOpen === void 0 ? void 0 : onOpen();
            return () => {
                setOpen(false);
                onClose === null || onClose === void 0 ? void 0 : onClose();
            };
        }
    }, [isOpen, updateCoords, onOpen, mode, onClose]);
    useEffect(() => {
        if (isMobile) {
            setOpen(!!active);
        }
        else {
            setOpen(false);
        }
    }, [active, isMobile]);
    const renderDropdown = useMemo(() => (React.createElement("div", { className: classNames(styles.dropdown, dropdownClassName, {
            [styles.isVisible]: dropdownStyles,
        }), onClick: closeOnClick ? onButtonClick : undefined, style: dropdownStyles, ref: dropdownRef }, children)), [
        dropdownClassName,
        dropdownStyles,
        closeOnClick,
        onButtonClick,
        children,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", Object.assign({ className: classNames(classNamesComplete) }, applyDataAttr(dataAttribute), { onClick: onButtonClick, type: "button", ref: buttonRef }),
            text,
            React.createElement(Icon, { icon: IconNames.ARROW_DOWN, size: 10, className: classNames(styles.icon, {
                    [styles.isOpen]: isOpen || isAccordionActive,
                }) })),
        (isOpen || isAccordionActive) && React.createElement(React.Fragment, null, renderDropdown)));
});
