export var NotificationMessageType;
(function (NotificationMessageType) {
    NotificationMessageType["ZeroBelowCcr"] = "ZeroBelowCcr";
    NotificationMessageType["ZeroCcr"] = "ZeroCcr";
    NotificationMessageType["ZeroCriticalIcrNormal"] = "ZeroCriticalIcrNormal";
    NotificationMessageType["ZeroCriticalIcrRecovery"] = "ZeroCriticalIcrRecovery";
    NotificationMessageType["ZeroGain"] = "ZeroGain";
    NotificationMessageType["ZeroLiquidation"] = "ZeroLiquidation";
    NotificationMessageType["ZeroLiquidationSurplus"] = "ZeroLiquidationSurplus";
    NotificationMessageType["ZeroLowTcr"] = "ZeroLowTcr";
    NotificationMessageType["ZeroRecovery"] = "ZeroRecovery";
    NotificationMessageType["ZeroRedemptionFull"] = "ZeroRedemptionFull";
    NotificationMessageType["ZeroRedemptionPartial"] = "ZeroRedemptionPartial";
    NotificationMessageType["LoanMarginCall"] = "LoanMarginCall";
    NotificationMessageType["LoanLiquidation"] = "LoanLiquidation";
    NotificationMessageType["LoanMarginCallUndercollateralized"] = "LoanMarginCallUndercollateralized";
    NotificationMessageType["BitocracyVote"] = "BitocracyVote";
})(NotificationMessageType || (NotificationMessageType = {}));
export var AlertGroup;
(function (AlertGroup) {
    AlertGroup["MarginCalls"] = "MarginCalls";
    AlertGroup["Liquidations"] = "Liquidations";
    AlertGroup["StabilityPool"] = "StabilityPool";
    AlertGroup["System"] = "System";
    AlertGroup["BitocracyVote"] = "BitocracyVote";
})(AlertGroup || (AlertGroup = {}));
export const AlertGroupToNotificationsMapping = {
    MarginCalls: [
        NotificationMessageType.ZeroBelowCcr,
        NotificationMessageType.ZeroCcr,
        NotificationMessageType.ZeroCriticalIcrNormal,
        NotificationMessageType.ZeroCriticalIcrRecovery,
        NotificationMessageType.LoanMarginCall,
        NotificationMessageType.LoanMarginCallUndercollateralized,
    ],
    Liquidations: [
        NotificationMessageType.ZeroLiquidation,
        NotificationMessageType.ZeroLiquidationSurplus,
        NotificationMessageType.ZeroRedemptionFull,
        NotificationMessageType.ZeroRedemptionPartial,
        NotificationMessageType.LoanLiquidation,
    ],
    StabilityPool: [NotificationMessageType.ZeroGain],
    System: [
        NotificationMessageType.ZeroLowTcr,
        NotificationMessageType.ZeroRecovery,
    ],
    BitocracyVote: [NotificationMessageType.BitocracyVote],
};
export const defaultSubscriptionsArray = [
    {
        notification: NotificationMessageType.ZeroBelowCcr,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroCcr,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroCriticalIcrNormal,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroCriticalIcrRecovery,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroGain,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroLiquidation,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroLiquidationSurplus,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroLowTcr,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroRecovery,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroRedemptionFull,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.ZeroRedemptionPartial,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.LoanMarginCall,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.LoanMarginCallUndercollateralized,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.LoanLiquidation,
        isSubscribed: false,
    },
    {
        notification: NotificationMessageType.BitocracyVote,
        isSubscribed: false,
    },
];
