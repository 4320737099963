var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { ErrorBadge, ErrorLevel, NotificationType, OrderDirection, Pagination, Paragraph, ParagraphSize, Table, } from '@sovryn/ui';
import { RSK_CHAIN_ID } from '../../../../../config/chains';
import { AmountRenderer } from '../../../../2_molecules/AmountRenderer/AmountRenderer';
import { ExportCSV } from '../../../../2_molecules/ExportCSV/ExportCSV';
import { TableFilter } from '../../../../2_molecules/TableFilter/TableFilter';
import { TxIdWithNotification } from '../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { useLiquityBaseParams } from '../../../../5_pages/ZeroPage/hooks/useLiquityBaseParams';
import { BITCOIN, BTC_RENDER_PRECISION, TOKEN_RENDER_PRECISION, } from '../../../../../constants/currencies';
import { LIQUIDATION_RESERVE_AMOUNT, DEFAULT_HISTORY_FRAME_PAGE_SIZE, EXPORT_RECORD_LIMIT, } from '../../../../../constants/general';
import { getTokenDisplayName } from '../../../../../constants/tokens';
import { useNotificationContext } from '../../../../../contexts/NotificationContext';
import { useAccount } from '../../../../../hooks/useAccount';
import { useBlockNumber } from '../../../../../hooks/useBlockNumber';
import { useMaintenance } from '../../../../../hooks/useMaintenance';
import { translations } from '../../../../../locales/i18n';
import { COMMON_SYMBOLS } from '../../../../../utils/asset';
import { getChainById } from '../../../../../utils/chain';
import { zeroClient } from '../../../../../utils/clients';
import { TroveOperation, useGetTroveLazyQuery, } from '../../../../../utils/graphql/zero/generated';
import { dateFormat } from '../../../../../utils/helpers';
import { useGetTroves } from './hooks/useGetTroves';
import { renderSign } from './utils';
const pageSize = DEFAULT_HISTORY_FRAME_PAGE_SIZE;
export const TransactionHistoryFrame = ({ children, }) => {
    var _a;
    const { account } = useAccount();
    const { addNotification } = useNotificationContext();
    const [page, setPage] = useState(0);
    const chain = getChainById(RSK_CHAIN_ID);
    const [filters, setFilters] = useState({});
    const { value: block } = useBlockNumber();
    const { checkMaintenance, States } = useMaintenance();
    const exportLocked = checkMaintenance(States.ZERO_EXPORT_CSV);
    const { minBorrowingFeeRate } = useLiquityBaseParams();
    const [orderOptions, setOrderOptions] = useState({
        orderBy: 'sequenceNumber',
        orderDirection: OrderDirection.Desc,
    });
    const getTroveType = useCallback((trove) => {
        switch (trove) {
            case TroveOperation.OpenTrove:
                return t(translations.transactionHistory.troveTypes.open);
            case TroveOperation.CloseTrove:
                return t(translations.transactionHistory.troveTypes.close);
            case TroveOperation.AdjustTrove:
                return t(translations.transactionHistory.troveTypes.adjust);
            case TroveOperation.RedeemCollateral:
                return t(translations.transactionHistory.troveTypes.redemption);
            case TroveOperation.LiquidateInNormalMode:
                return t(translations.transactionHistory.troveTypes.liquidation);
            case TroveOperation.LiquidateInRecoveryMode:
                return t(translations.transactionHistory.troveTypes.liquidationRecovery);
            case TroveOperation.AccrueRewards:
                return t(translations.transactionHistory.troveTypes.redistribution);
            case TroveOperation.TransferGainToLineOfCredit:
                return t(translations.transactionHistory.troveTypes.transferGainToLineOfCredit);
            default:
                return '';
        }
    }, []);
    const transactionTypeFilters = useMemo(() => {
        return Object.keys(TroveOperation).map(key => {
            var _a;
            return ({
                label: getTroveType(TroveOperation[key]),
                filter: 'troveOperation_in',
                value: TroveOperation[key],
                checked: filters && ((_a = filters['troveOperation_in']) === null || _a === void 0 ? void 0 : _a.includes(TroveOperation[key]))
                    ? true
                    : false,
            });
        });
    }, [filters, getTroveType]);
    const collateralChangeFilters = useMemo(() => [
        {
            label: t(translations.transactionHistory.filters.increasedCollateral),
            filter: 'collateralChange_gte',
            value: 0,
            checked: (filters || {}).hasOwnProperty('collateralChange_gte'),
        },
        {
            label: t(translations.transactionHistory.filters.decreasedCollateral),
            filter: 'collateralChange_lte',
            value: 0,
            checked: (filters || {}).hasOwnProperty('collateralChange_lte'),
        },
    ], [filters]);
    const debtChangeFilters = useMemo(() => [
        {
            label: t(translations.transactionHistory.filters.increasedDebt),
            filter: 'debtChange_gte',
            value: 0,
            checked: (filters || {}).hasOwnProperty('debtChange_gte'),
        },
        {
            label: t(translations.transactionHistory.filters.decreasedDebt),
            filter: 'debtChange_lte',
            value: 0,
            checked: (filters || {}).hasOwnProperty('debtChange_lte'),
        },
    ], [filters]);
    /**
     * @description
     * This function is used to remove the filters that are not needed in the query,
     * if they are all checked inside of the filter group.
     * For example, if all the filters in collateralChangeFilters are checked,
     * then we don't need to send the collateralChange_gte and collateralChange_lte filters to the query.
     */
    const getFinalFilters = useCallback(() => {
        const newFilters = Object.assign({}, filters);
        if (collateralChangeFilters.every(filter => filter.checked)) {
            collateralChangeFilters.forEach(item => delete newFilters[item.filter]);
        }
        if (debtChangeFilters.every(filter => filter.checked)) {
            debtChangeFilters.forEach(item => delete newFilters[item.filter]);
        }
        if (transactionTypeFilters.every(filter => filter.checked)) {
            transactionTypeFilters.forEach(item => delete newFilters[item.filter]);
        }
        return newFilters;
    }, [
        filters,
        collateralChangeFilters,
        debtChangeFilters,
        transactionTypeFilters,
    ]);
    const { data, loading, refetch } = useGetTroves(account, pageSize, page, getFinalFilters(), orderOptions);
    useEffect(() => {
        refetch();
    }, [refetch, block]);
    const [getTroves] = useGetTroveLazyQuery({
        client: zeroClient,
    });
    const noDataLabel = useMemo(() => Object.keys(filters || {}).length > 0
        ? t(translations.common.tables.noDataWithFilters)
        : t(translations.common.tables.noData), [filters]);
    const troves = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.trove) === null || _a === void 0 ? void 0 : _a.changes) || []; }, [(_a = data === null || data === void 0 ? void 0 : data.trove) === null || _a === void 0 ? void 0 : _a.changes]);
    const renderLiquidationReserve = useCallback((trove, isCsvExport) => {
        const { troveOperation, redemption } = trove;
        const operations = [
            TroveOperation.LiquidateInNormalMode,
            TroveOperation.LiquidateInRecoveryMode,
            TroveOperation.CloseTrove,
        ];
        if (troveOperation === TroveOperation.OpenTrove) {
            return `+${LIQUIDATION_RESERVE_AMOUNT} ${isCsvExport ? '' : COMMON_SYMBOLS.ZUSD}`;
        }
        if (operations.some(item => item.includes(troveOperation)) ||
            (troveOperation === TroveOperation.RedeemCollateral &&
                (redemption === null || redemption === void 0 ? void 0 : redemption.partial) === true)) {
            return `-${LIQUIDATION_RESERVE_AMOUNT} ${isCsvExport ? '' : COMMON_SYMBOLS.ZUSD}`;
        }
        return '-';
    }, []);
    const updateFilters = useCallback((filterList) => {
        const previousFilters = Object.assign({}, filters);
        filterList.forEach(filter => {
            delete previousFilters[filter.filter];
        });
        const updatedFilters = filterList
            .filter(f => f.checked)
            .reduce((acc, filter) => (Object.assign(Object.assign({}, acc), { [filter.filter]: filter.filter === 'troveOperation_in'
                ? [...(acc[filter.filter] || []), filter.value]
                : filter.value })), {});
        setFilters(Object.assign(Object.assign({}, previousFilters), updatedFilters));
    }, [filters]);
    const renderDebtChange = useCallback((trove) => (React.createElement(React.Fragment, null, trove.debtChange.length ? (React.createElement(AmountRenderer, { value: trove.debtChange, suffix: COMMON_SYMBOLS.ZUSD, precision: TOKEN_RENDER_PRECISION, dataAttribute: "transaction-history-debt-change", prefix: renderSign(trove.debtChange, trove.troveOperation) })) : ('-'))), []);
    const renderNewDebt = useCallback((trove) => (React.createElement(React.Fragment, null, trove.debtAfter.length ? (React.createElement(AmountRenderer, { value: trove.debtAfter, suffix: COMMON_SYMBOLS.ZUSD, precision: TOKEN_RENDER_PRECISION, dataAttribute: "transaction-history-new-debt" })) : ('-'))), []);
    const renderCollateralChange = useCallback((trove) => (React.createElement(React.Fragment, null, trove.collateralChange.length ? (React.createElement(AmountRenderer, { value: trove.collateralChange, suffix: BITCOIN, precision: BTC_RENDER_PRECISION, dataAttribute: "transaction-history-collateral-change", prefix: renderSign(trove.collateralChange, trove.troveOperation) })) : ('-'))), []);
    const renderCollateralBalance = useCallback((trove) => (React.createElement(React.Fragment, null, trove.collateralAfter.length ? (React.createElement(AmountRenderer, { value: trove.collateralAfter, suffix: BITCOIN, precision: BTC_RENDER_PRECISION, dataAttribute: "transaction-history-collateral-balance" })) : ('-'))), []);
    const renderOriginationFee = useCallback(({ borrowingFee, debtChange }) => {
        if (!borrowingFee || Number(borrowingFee) === 0) {
            return '-';
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(AmountRenderer, { value: borrowingFee, suffix: COMMON_SYMBOLS.ZUSD, precision: TOKEN_RENDER_PRECISION, dataAttribute: "transaction-history-borrowing-fee" }),
            ' ',
            "(",
            minBorrowingFeeRate.mul(100).toString(),
            "%)"));
    }, [minBorrowingFeeRate]);
    const generateRowTitle = useCallback((trove) => (React.createElement(Paragraph, { size: ParagraphSize.small, className: "text-left" },
        getTroveType(trove.troveOperation),
        ' - ',
        dateFormat(trove.transaction.timestamp))), [getTroveType]);
    const columns = useMemo(() => [
        {
            id: 'sequenceNumber',
            title: t(translations.common.tables.columnTitles.timestamp),
            cellRenderer: (item) => dateFormat(item.transaction.timestamp),
            sortable: true,
        },
        {
            id: 'transactionType',
            title: t(translations.common.tables.columnTitles.transactionType),
            cellRenderer: (item) => getTroveType(item.troveOperation),
            filter: (React.createElement(TableFilter, { filterList: transactionTypeFilters, onChange: updateFilters })),
        },
        {
            id: 'collateralChange',
            title: t(translations.transactionHistory.table.collateralChange),
            cellRenderer: (item) => renderCollateralChange(item),
            filter: (React.createElement(TableFilter, { filterList: collateralChangeFilters, onChange: updateFilters })),
        },
        {
            id: 'newCollateralBalance',
            title: t(translations.transactionHistory.table.newCollateralBalance),
            cellRenderer: (item) => renderCollateralBalance(item),
        },
        {
            id: 'debtChange',
            title: t(translations.transactionHistory.table.debtChange),
            cellRenderer: (item) => renderDebtChange(item),
            filter: (React.createElement(TableFilter, { filterList: debtChangeFilters, onChange: updateFilters })),
        },
        {
            id: 'liquidationReserve',
            title: t(translations.transactionHistory.table.liquidationReserve),
            cellRenderer: (item) => (React.createElement("div", { className: "uppercase" }, renderLiquidationReserve(item))),
        },
        {
            id: 'originationFee',
            title: t(translations.transactionHistory.table.originationFee),
            cellRenderer: (item) => renderOriginationFee(item),
        },
        {
            id: 'newDebt',
            title: t(translations.transactionHistory.table.newDebt),
            cellRenderer: (item) => renderNewDebt(item),
        },
        {
            id: 'transactionID',
            title: t(translations.common.tables.columnTitles.transactionID),
            cellRenderer: (item) => (React.createElement(TxIdWithNotification, { href: `${chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl}/tx/${item.transaction.id}`, value: item.transaction.id, dataAttribute: "history-address-id" })),
        },
    ], [
        chain,
        getTroveType,
        transactionTypeFilters,
        updateFilters,
        renderNewDebt,
        renderDebtChange,
        renderOriginationFee,
        renderCollateralBalance,
        renderCollateralChange,
        renderLiquidationReserve,
        collateralChangeFilters,
        debtChangeFilters,
    ]);
    const onPageChange = useCallback((value) => {
        if ((troves === null || troves === void 0 ? void 0 : troves.length) < pageSize && value > page) {
            return;
        }
        setPage(value);
    }, [page, troves]);
    const isNextButtonDisabled = useMemo(() => !loading && (!troves || (troves === null || troves === void 0 ? void 0 : troves.length) < pageSize), [loading, troves]);
    const exportData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const data = yield getTroves({
            variables: {
                skip: 0,
                pageSize: EXPORT_RECORD_LIMIT,
                user: account === null || account === void 0 ? void 0 : account.toLowerCase(),
                orderBy: orderOptions.orderBy || undefined,
                orderDirection: orderOptions.orderDirection || undefined,
                filters,
            },
            client: zeroClient,
        }).then(res => res.data);
        let troves = ((_b = data === null || data === void 0 ? void 0 : data.trove) === null || _b === void 0 ? void 0 : _b.changes) || [];
        if (!troves || !troves.length || troves.length === 0) {
            addNotification({
                type: NotificationType.warning,
                title: t(translations.common.tables.actions.noDataToExport),
                content: '',
                dismissible: true,
                id: nanoid(),
            });
        }
        return troves.map(tx => ({
            timestamp: dateFormat(tx.transaction.timestamp),
            transactionType: getTroveType(tx.troveOperation),
            collateralChange: tx.collateralChange,
            collateralChangeToken: BITCOIN,
            newCollateralBalance: tx.collateralAfter,
            newCollateralBalanceToken: BITCOIN,
            debtChange: tx.debtChange,
            debtChangeToken: getTokenDisplayName(COMMON_SYMBOLS.ZUSD),
            liquidationReserveAmount: renderLiquidationReserve(tx, true),
            liquidationReserveAmountToken: getTokenDisplayName(COMMON_SYMBOLS.ZUSD),
            newDebtBalance: tx.debtAfter,
            newDebtBalanceToken: getTokenDisplayName(COMMON_SYMBOLS.ZUSD),
            originationFee: tx.borrowingFee || '-',
            originationFeeToken: getTokenDisplayName(COMMON_SYMBOLS.ZUSD),
            transactionID: tx.transaction.id,
        }));
    }), [
        getTroves,
        account,
        orderOptions.orderBy,
        orderOptions.orderDirection,
        filters,
        addNotification,
        getTroveType,
        renderLiquidationReserve,
    ]);
    useEffect(() => {
        setPage(0);
    }, [orderOptions, filters]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-row items-center gap-4 mb-7 flex justify-center lg:justify-start" },
            children,
            React.createElement("div", { className: "flex-row items-center ml-2 gap-4 hidden lg:inline-flex" },
                React.createElement(ExportCSV, { getData: exportData, filename: "transactions", disabled: !troves || (troves === null || troves === void 0 ? void 0 : troves.length) < 1 || exportLocked }),
                exportLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.featureDisabled) })))),
        React.createElement("div", { className: "bg-gray-80 py-4 px-4 rounded" },
            React.createElement(Table, { setOrderOptions: setOrderOptions, orderOptions: orderOptions, columns: columns, rows: troves, rowTitle: row => generateRowTitle(row), isLoading: loading, className: "bg-gray-80 text-gray-10 lg:px-6 lg:py-4", noData: noDataLabel, loadingData: t(translations.common.tables.loading), dataAttribute: "transaction-history-table" }),
            React.createElement(Pagination, { page: page, className: "lg:pb-6 mt-3 lg:mt-6 justify-center lg:justify-start", onChange: onPageChange, itemsPerPage: pageSize, isNextButtonDisabled: isNextButtonDisabled, dataAttribute: "transaction-history-pagination" }))));
};
