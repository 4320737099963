import React, { useState } from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';
import { getAssetData, getAssetDataByAddress } from '@sovryn/contracts';
import { ChainIds } from '@sovryn/ethers-provider';
import { applyDataAttr } from '@sovryn/ui';
import styles from './AssetRenderer.module.css';
export const AssetRenderer = ({ asset, chainId = ChainIds.RSK_MAINNET, address, showAssetLogo, assetClassName, className, dataAttribute, logoClassName, }) => {
    const [token, setToken] = useState(asset);
    const [logo, setLogo] = useState(undefined);
    useEffect(() => {
        if (asset && !address) {
            getAssetData(asset, chainId)
                .then(item => {
                setLogo(item.icon);
                setToken(item.symbol);
            })
                .catch(() => setLogo(''));
        }
    }, [address, asset, chainId, showAssetLogo]);
    useEffect(() => {
        if (address && !asset) {
            getAssetDataByAddress(address, chainId)
                .then(item => {
                setLogo(item.icon);
                setToken(item.symbol);
            })
                .catch(() => setLogo(''));
        }
    }, [address, asset, chainId, showAssetLogo]);
    return (React.createElement("div", Object.assign({ className: classNames(styles.container, className) }, applyDataAttr(dataAttribute)),
        showAssetLogo && logo && (React.createElement("div", { className: classNames(styles.assetLogo, logoClassName), dangerouslySetInnerHTML: { __html: logo } })),
        token && (React.createElement("span", { className: classNames(styles.asset, assetClassName) }, token))));
};
