import React, { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { noop } from '@sovryn/ui';
const defaultContextValue = {
    setServerSubscriptionsState: noop,
    subscriptions: [],
    setSubscriptions: noop,
    resetSubscriptions: noop,
    marginCallsToggle: false,
    setMarginCallsToggle: noop,
    liquidationsToggle: false,
    setLiquidationsToggle: noop,
    stabilityPoolToggle: false,
    setStabilityPoolToggle: noop,
    systemToggle: false,
    setSystemToggle: noop,
    bitocracyToggle: false,
    setBitocracyToggle: noop,
    haveSubscriptionsBeenUpdated: false,
};
const EmailNotificationSettingsContext = createContext(defaultContextValue);
export const useEmailNotificationSettingsContext = () => useContext(EmailNotificationSettingsContext);
export const EmailNotificationSettingsContextProvider = ({ children }) => {
    const [subscriptions, setSubscriptions] = useState(defaultContextValue.subscriptions);
    const [serverSubscriptionsState, setServerSubscriptionsState] = useState(defaultContextValue.subscriptions);
    const [haveSubscriptionsBeenUpdated, setHaveSubscriptionsBeenUpdated] = useState(defaultContextValue.haveSubscriptionsBeenUpdated);
    const [marginCallsToggle, setMarginCallsToggle] = useState(false);
    const [liquidationsToggle, setLiquidationsToggle] = useState(false);
    const [stabilityPoolToggle, setStabilityPoolToggle] = useState(false);
    const [systemToggle, setSystemToggle] = useState(false);
    const [bitocracyToggle, setBitocracyToggle] = useState(false);
    const resetSubscriptions = useCallback(() => {
        setSubscriptions(defaultContextValue.subscriptions);
    }, []);
    useEffect(() => {
        // New users or previously registered users without any subscriptions
        if (subscriptions.length > 0 && serverSubscriptionsState.length === 0) {
            const isSubscribedToSomething = subscriptions.some(item => item.isSubscribed);
            if (isSubscribedToSomething) {
                setHaveSubscriptionsBeenUpdated(true);
            }
            else if (!isSubscribedToSomething && haveSubscriptionsBeenUpdated) {
                setHaveSubscriptionsBeenUpdated(false);
            }
        }
        // Regular users with existing subscriptions
        if (subscriptions.length > 0 && serverSubscriptionsState.length > 0) {
            const haveSubscriptionsBeenUpdated = subscriptions.some(item => {
                var _a;
                return item.isSubscribed !==
                    ((_a = serverSubscriptionsState.find(serverItem => serverItem.notification === item.notification)) === null || _a === void 0 ? void 0 : _a.isSubscribed) || false;
            });
            setHaveSubscriptionsBeenUpdated(haveSubscriptionsBeenUpdated);
        }
    }, [subscriptions, serverSubscriptionsState, haveSubscriptionsBeenUpdated]);
    return (React.createElement(EmailNotificationSettingsContext.Provider, { value: {
            setServerSubscriptionsState,
            subscriptions,
            setSubscriptions,
            resetSubscriptions,
            marginCallsToggle,
            setMarginCallsToggle,
            liquidationsToggle,
            setLiquidationsToggle,
            stabilityPoolToggle,
            setStabilityPoolToggle,
            systemToggle,
            setSystemToggle,
            bitocracyToggle,
            setBitocracyToggle,
            haveSubscriptionsBeenUpdated,
        } }, children));
};
