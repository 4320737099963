import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown, Menu, MenuItem } from '@sovryn/ui';
import { APP_CHAIN_LIST } from '../../../config/chains';
import { useChainStore } from '../../../hooks/useChainStore';
import { getChainById } from '../../../utils/chain';
import styles from './NetworkPicker.module.css';
export const NetworkPicker = ({ className }) => {
    const { currentChainId, setCurrentChainId } = useChainStore();
    const selectedChain = useMemo(() => getChainById(currentChainId), [currentChainId]);
    return (React.createElement(Dropdown, { text: React.createElement(React.Fragment, null,
            React.createElement("img", { src: selectedChain === null || selectedChain === void 0 ? void 0 : selectedChain.icon, className: "w-5 h-5 opacity-50", alt: selectedChain === null || selectedChain === void 0 ? void 0 : selectedChain.label })), closeOnClick: true, className: classNames('h-8 min-w-0', className), dropdownClassName: "z-[10000000]" },
        React.createElement(Menu, null, APP_CHAIN_LIST.map(item => (React.createElement(MenuItem, { key: item.id, text: React.createElement("span", { className: styles.itemContent },
                React.createElement("img", { src: item.icon, className: styles.icon, alt: item.label }),
                ' ',
                item.label), isActive: item.id === currentChainId, onClick: setCurrentChainId.bind(null, item.id), className: styles.menuItem }))))));
};
