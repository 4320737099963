import { useEffect, useMemo, useState } from 'react';
import { observeCall, startCall, } from '../store/rxjs/provider-cache';
import { useBlockNumber } from './useBlockNumber';
import { useIsMounted } from './useIsMounted';
export const useCacheCall = (key, chain, callback, deps, defaultValue, options) => {
    const isMounted = useIsMounted();
    const { value: block } = useBlockNumber(chain);
    const [state, setState] = useState({
        value: defaultValue !== null && defaultValue !== void 0 ? defaultValue : null,
        loading: false,
        error: null,
    });
    useEffect(() => {
        if (!isMounted() || !block) {
            return;
        }
        let sub;
        sub = observeCall(`call:${chain}:${key}`).subscribe(e => {
            var _a, _b;
            if (isMounted()) {
                setState(Object.assign(Object.assign({}, e.result), { value: (_b = (_a = e.result.value) !== null && _a !== void 0 ? _a : defaultValue) !== null && _b !== void 0 ? _b : null }));
            }
        });
        startCall(`call:${chain}:${key}`, callback, Object.assign(Object.assign({}, options), { blockNumber: (options === null || options === void 0 ? void 0 : options.blockNumber) || block }));
        return () => {
            if (sub) {
                sub.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block, key, JSON.stringify(deps)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => state, [JSON.stringify(state)]);
};
