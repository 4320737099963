// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11380_252)">
<mask id="mask0_11380_252" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<path d="M20 0H0V20H20V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_11380_252)">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z" fill="url(#paint0_radial_11380_252)"/>
<mask id="mask1_11380_252" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z" fill="url(#paint1_radial_11380_252)"/>
</mask>
<g mask="url(#mask1_11380_252)">
<path opacity="0.2" d="M-0.333374 5.50001C-0.333374 5.27911 -0.154288 5.10001 0.066626 5.10001H5.53329C5.75419 5.10001 5.93329 5.27911 5.93329 5.50001V14.8C5.93329 15.0209 5.75419 15.2 5.53329 15.2H0.066626C-0.154288 15.2 -0.333374 15.0209 -0.333374 14.8V5.50001Z" fill="url(#paint2_linear_11380_252)"/>
<path opacity="0.2" d="M12.0667 13.5667C12.0667 13.3458 12.2458 13.1667 12.4667 13.1667H19.6C19.8209 13.1667 20 13.3458 20 13.5667V17.0333C20 17.2542 19.8209 17.4333 19.6 17.4333H12.4667C12.2458 17.4333 12.0667 17.2542 12.0667 17.0333V13.5667Z" fill="#E74310"/>
<path opacity="0.2" d="M16.7 14.6333C16.9209 14.6333 17.1 14.8124 17.1 15.0333V21.7667C17.1 21.9876 16.9209 22.1667 16.7 22.1667H14.0666C13.8457 22.1667 13.6666 21.9876 13.6666 21.7667V15.0333C13.6666 14.8124 13.8457 14.6333 14.0666 14.6333H16.7Z" fill="#DF3600"/>
<path opacity="0.1" d="M8.09994 6.5C8.32084 6.5 8.49994 6.6791 8.49994 6.9V12.2667C8.49994 12.4876 8.32084 12.6667 8.09994 12.6667H5.13328C4.91238 12.6667 4.73328 12.4876 4.73328 12.2667V6.9C4.73328 6.6791 4.91238 6.5 5.13328 6.5H8.09994Z" fill="url(#paint3_linear_11380_252)"/>
<path opacity="0.1" d="M1.90002 8.09998C1.90002 7.87908 2.07911 7.69998 2.30002 7.69998H7.16669C7.38759 7.69998 7.56669 7.87908 7.56669 8.09998V13.2C7.56669 13.4209 7.38759 13.6 7.16669 13.6H2.30002C2.07911 13.6 1.90002 13.4209 1.90002 13.2V8.09998Z" fill="url(#paint4_linear_11380_252)"/>
<path opacity="0.2" d="M20 2.73334C20.2209 2.73334 20.4 2.91242 20.4 3.13334V9.86667C20.4 10.0876 20.2209 10.2667 20 10.2667H13.8C13.5791 10.2667 13.4 10.0876 13.4 9.86667V3.13334C13.4 2.91242 13.5791 2.73334 13.8 2.73334H20Z" fill="#FF9776"/>
<path opacity="0.2" d="M16.2332 -1.73334C16.4541 -1.73334 16.6332 -1.55425 16.6332 -1.33334V6.06666C16.6332 6.28756 16.4541 6.46666 16.2332 6.46666H12.7666C12.5457 6.46666 12.3666 6.28756 12.3666 6.06666V-1.33334C12.3666 -1.55425 12.5457 -1.73334 12.7666 -1.73334H16.2332Z" fill="#FFCA8C"/>
<path opacity="0.2" d="M18.0333 -0.399994C18.2542 -0.399994 18.4333 -0.220908 18.4333 6.4671e-06V8.23334C18.4333 8.45424 18.2542 8.63334 18.0333 8.63334H10.5C10.2791 8.63334 10.1 8.45424 10.1 8.23334V6.34789e-06C10.1 -0.220908 10.2791 -0.399994 10.5 -0.399994H18.0333Z" fill="url(#paint5_linear_11380_252)"/>
<path opacity="0.1" d="M7.06666 -2.73334C7.28756 -2.73334 7.46666 -2.55425 7.46666 -2.33334V3.2C7.46666 3.4209 7.28756 3.6 7.06666 3.6H4.43333C4.21243 3.6 4.03333 3.4209 4.03333 3.2V-2.33334C4.03333 -2.55425 4.21243 -2.73334 4.43333 -2.73334H7.06666Z" fill="#DF3600"/>
<path opacity="0.1" d="M7.3666 14.4333C7.5875 14.4333 7.7666 14.6124 7.7666 14.8333V19.6C7.7666 19.8209 7.5875 20 7.3666 20H0.666602C0.445688 20 0.266602 19.8209 0.266602 19.6L0.266602 14.8333C0.266602 14.6124 0.445688 14.4333 0.666602 14.4333H7.3666Z" fill="#DF3600"/>
<path opacity="0.1" d="M13.2666 11.7667C13.4875 11.7667 13.6666 11.9458 13.6666 12.1667V19.6C13.6666 19.8209 13.4875 20 13.2666 20H5.59995C5.37905 20 5.19995 19.8209 5.19995 19.6V12.1667C5.19995 11.9458 5.37905 11.7667 5.59995 11.7667H13.2666Z" fill="#FFD494"/>
<path opacity="0.2" d="M10.7333 3.4969e-07C10.9542 3.59347e-07 11.1333 0.179086 11.1333 0.4V2.33333C11.1333 2.55425 10.9542 2.73333 10.7333 2.73333H2.73325C2.51234 2.73333 2.33325 2.55425 2.33325 2.33333V0.4C2.33325 0.179086 2.51234 -9.65647e-09 2.73325 0L10.7333 3.4969e-07Z" fill="#FFD494"/>
<path opacity="0.1" d="M4.42486 19.8052L19.4052 4.82495L18.8914 4.31118L3.91109 19.2915L4.42486 19.8052Z" fill="#DF3600"/>
<path opacity="0.1" d="M3.3012 18.7148L18.2815 3.73453L17.6126 3.06562L2.63229 18.0459L3.3012 18.7148Z" fill="#DF3600"/>
<path opacity="0.1" d="M2.66888 18.0159L17.6492 3.03558L16.9803 2.36667L1.99996 17.347L2.66888 18.0159Z" fill="#F45C2C"/>
<path opacity="0.2" d="M2.0689 17.2825L17.0492 2.30225L16.4987 1.75172L1.51837 16.732L2.0689 17.2825Z" fill="#F45C2C"/>
<path opacity="0.1" d="M1.51714 16.7308L16.4974 1.75052L15.9469 1.19999L0.966613 16.1803L1.51714 16.7308Z" fill="#FFBC6E"/>
<path opacity="0.1" d="M1.15057 15.9975L16.1309 1.01718L15.5803 0.466652L0.600036 15.4469L1.15057 15.9975Z" fill="#DF3600"/>
<path opacity="0.09" d="M-0.616177 11.5975L11.1959 -0.21463L10.6454 -0.76516L-1.16671 11.0469L-0.616177 11.5975Z" fill="#FFD5A4"/>
<path opacity="0.08" d="M-1.24948 10.9293L10.5626 -0.882812L10.0121 -1.43334L-1.80001 10.3788L-1.24948 10.9293Z" fill="#FFD5A4"/>
<path opacity="0.05" d="M-1.81613 10.1626L9.99597 -1.64948L9.44544 -2.2L-2.36666 9.61209L-1.81613 10.1626Z" fill="#FFD5A4"/>
<path opacity="0.2" d="M0.317204 13.2029L14.0363 -0.516144L13.4857 -1.06667L-0.233326 12.6524L0.317204 13.2029Z" fill="#FFD5A4"/>
<path opacity="0.2" d="M0.683781 14.0696L14.4028 0.350525L13.8523 -0.200005L0.133251 13.519L0.683781 14.0696Z" fill="#FFD5A4"/>
<path opacity="0.1" d="M0.0837915 12.1975L12.2599 0.0213928L11.7094 -0.529137L-0.466738 11.647L0.0837915 12.1975Z" fill="#FFD5A4"/>
<path opacity="0.1" d="M4.40276 19.8164L19.3831 4.83615L18.2847 3.73782L3.30443 18.7181L4.40276 19.8164Z" fill="#DF3600"/>
<path opacity="0.1" d="M4.40276 19.8164L19.3831 4.83615L18.2791 3.73222L3.29883 18.7125L4.40276 19.8164Z" fill="#DF3600"/>
</g>
<path d="M9.98517 12.1577L6.18054 9.90705L9.98517 3.59558L13.7864 9.90705L9.98517 12.1577Z" fill="white"/>
<path d="M9.98517 15.9897L6.18054 10.6311L9.98517 12.8783L13.7898 10.6311L9.98517 15.9897Z" fill="white"/>
</g>
</g>
<defs>
<radialGradient id="paint0_radial_11380_252" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.53333 4.78333) rotate(54.1675) scale(16.5694)">
<stop stop-color="#FFD794"/>
<stop offset="1" stop-color="#ED5A37"/>
</radialGradient>
<radialGradient id="paint1_radial_11380_252" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.53333 4.78333) rotate(54.1675) scale(16.5694)">
<stop stop-color="#FFD794"/>
<stop offset="1" stop-color="#ED5A37"/>
</radialGradient>
<linearGradient id="paint2_linear_11380_252" x1="2.79996" y1="5.10001" x2="2.79996" y2="15.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE090"/>
<stop offset="1" stop-color="#FFE090" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_11380_252" x1="6.59994" y1="6.5" x2="6.59994" y2="12.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#DF3600"/>
<stop offset="1" stop-color="#DF3600" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_11380_252" x1="1.90002" y1="10.6761" x2="7.13786" y2="10.6761" gradientUnits="userSpaceOnUse">
<stop stop-color="#DF3600"/>
<stop offset="1" stop-color="#DF3600" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_11380_252" x1="14.2666" y1="-0.399994" x2="14.2666" y2="8.63334" gradientUnits="userSpaceOnUse">
<stop stop-color="#DF3600"/>
<stop offset="1" stop-color="#DF3600" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_11380_252">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
