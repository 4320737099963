// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2950)">
<path d="M10 0C11.9778 0 13.9112 0.58649 15.5557 1.6853C17.2002 2.78412 18.4819 4.3459 19.2388 6.17317C19.9957 8.00043 20.1937 10.0111 19.8079 11.9509C19.422 13.8907 18.4696 15.6725 17.0711 17.0711C15.6725 18.4696 13.8907 19.422 11.9509 19.8079C10.0111 20.1937 8.00043 19.9957 6.17317 19.2388C4.3459 18.4819 2.78412 17.2002 1.6853 15.5557C0.58649 13.9112 0 11.9778 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0V0Z" fill="#E9EAE9"/>
<path d="M10.0979 3.20001L10.0048 3.5158V12.6763L10.0979 12.769L14.35 10.2553L10.0979 3.20001Z" fill="#525252"/>
<path d="M10.119 3.20001L5.86633 10.2558L10.119 12.7695V3.20001Z" fill="#B1B1B1"/>
<path d="M10.0979 13.5405L10.0453 13.6042V16.8674L10.0979 17.02L14.3527 11.0279L10.0979 13.5405Z" fill="#3E3E3E"/>
<path d="M10.119 17.0206V13.5406L5.86633 11.0284L10.119 17.0206Z" fill="#B1B1B1"/>
<path d="M10.0979 12.7511L14.35 10.2363L10.0979 8.30371V12.7511Z" fill="black"/>
<path d="M5.86633 10.2363L10.119 12.75V8.30475L5.86633 10.2363Z" fill="#6C6C6C"/>
</g>
<defs>
<clipPath id="clip0_9856_2950">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
