import { useCallback } from 'react';
import { AlertGroup, AlertGroupToNotificationsMapping, defaultSubscriptionsArray, NotificationMessageType, } from '../EmailNotificationSettingsDialog.types';
import { isSubscribedToGroup } from '../EmailNotificationSettingsDialog.utils';
import { useEmailNotificationSettingsContext } from '../contexts/EmailNotificationSettingsContext';
export const useHandleSubscriptions = () => {
    const { subscriptions, setSubscriptions, resetSubscriptions: resetSubscriptionsState, setMarginCallsToggle, setLiquidationsToggle, setStabilityPoolToggle, setSystemToggle, setServerSubscriptionsState, setBitocracyToggle, } = useEmailNotificationSettingsContext();
    const resetSubscriptions = useCallback(() => {
        resetSubscriptionsState();
        setMarginCallsToggle(false);
        setLiquidationsToggle(false);
        setStabilityPoolToggle(false);
        setSystemToggle(false);
        setBitocracyToggle(false);
    }, [
        resetSubscriptionsState,
        setMarginCallsToggle,
        setLiquidationsToggle,
        setStabilityPoolToggle,
        setSystemToggle,
        setBitocracyToggle,
    ]);
    const withDefaultSubscriptions = useCallback((subscriptions) => defaultSubscriptionsArray.map(item => {
        var _a;
        return (_a = subscriptions.find(subscription => subscription.notification === item.notification)) !== null && _a !== void 0 ? _a : item;
    }), []);
    const parseSubscriptionsResponse = useCallback((subscriptions) => {
        const parsedSubscriptions = withDefaultSubscriptions(subscriptions)
            .filter(item => Object.values(NotificationMessageType).includes(item.notification))
            .map(item => ({
            notification: item.notification,
            isSubscribed: item.isSubscribed,
        }));
        setSubscriptions(parsedSubscriptions);
        setServerSubscriptionsState(parsedSubscriptions);
        setMarginCallsToggle(isSubscribedToGroup(AlertGroup.MarginCalls, parsedSubscriptions));
        setLiquidationsToggle(isSubscribedToGroup(AlertGroup.Liquidations, parsedSubscriptions));
        setStabilityPoolToggle(isSubscribedToGroup(AlertGroup.StabilityPool, parsedSubscriptions));
        setSystemToggle(isSubscribedToGroup(AlertGroup.System, parsedSubscriptions));
        setBitocracyToggle(isSubscribedToGroup(AlertGroup.BitocracyVote, parsedSubscriptions));
    }, [
        withDefaultSubscriptions,
        setSubscriptions,
        setServerSubscriptionsState,
        setMarginCallsToggle,
        setLiquidationsToggle,
        setStabilityPoolToggle,
        setSystemToggle,
        setBitocracyToggle,
    ]);
    const updateSubscriptions = useCallback((group) => {
        let oldSubscriptionsState = subscriptions.length === 0
            ? defaultSubscriptionsArray
            : defaultSubscriptionsArray.map(item => {
                var _a;
                return (_a = subscriptions.find(subscription => subscription.notification === item.notification)) !== null && _a !== void 0 ? _a : item;
            });
        // fixes bug which caused group to always be disabled,
        // when new subscription type is added to the group and it's not subscribed to
        const checked = oldSubscriptionsState
            .filter(item => AlertGroupToNotificationsMapping[group].includes(item.notification))
            .some(item => !item.isSubscribed);
        const newSubscriptionsState = oldSubscriptionsState.map(item => {
            if (AlertGroupToNotificationsMapping[group].includes(item.notification)) {
                return {
                    notification: item.notification,
                    isSubscribed: checked,
                };
            }
            return item;
        });
        setSubscriptions(newSubscriptionsState);
    }, [subscriptions, setSubscriptions]);
    const marginCallsToggleHandler = useCallback(() => {
        updateSubscriptions(AlertGroup.MarginCalls);
        setMarginCallsToggle(prevValue => !prevValue);
    }, [updateSubscriptions, setMarginCallsToggle]);
    const liquidationsToggleHandler = useCallback(() => {
        updateSubscriptions(AlertGroup.Liquidations);
        setLiquidationsToggle(prevValue => !prevValue);
    }, [updateSubscriptions, setLiquidationsToggle]);
    const stabilityPoolToggleHandler = useCallback(() => {
        updateSubscriptions(AlertGroup.StabilityPool);
        setStabilityPoolToggle(prevValue => !prevValue);
    }, [updateSubscriptions, setStabilityPoolToggle]);
    const systemToggleHandler = useCallback(() => {
        updateSubscriptions(AlertGroup.System);
        setSystemToggle(prevValue => !prevValue);
    }, [updateSubscriptions, setSystemToggle]);
    const bitocracyToggleHandler = useCallback(() => {
        updateSubscriptions(AlertGroup.BitocracyVote);
        setBitocracyToggle(prevValue => !prevValue);
    }, [updateSubscriptions, setBitocracyToggle]);
    return {
        resetSubscriptions,
        parseSubscriptionsResponse,
        marginCallsToggleHandler,
        liquidationsToggleHandler,
        stabilityPoolToggleHandler,
        systemToggleHandler,
        bitocracyToggleHandler,
    };
};
