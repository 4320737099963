import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var Change_OrderBy;
(function (Change_OrderBy) {
    Change_OrderBy["Id"] = "id";
    Change_OrderBy["SequenceNumber"] = "sequenceNumber";
    Change_OrderBy["SystemStateAfter"] = "systemStateAfter";
    Change_OrderBy["SystemStateBefore"] = "systemStateBefore";
    Change_OrderBy["Transaction"] = "transaction";
})(Change_OrderBy || (Change_OrderBy = {}));
export var CollSurplusChange_OrderBy;
(function (CollSurplusChange_OrderBy) {
    CollSurplusChange_OrderBy["CollSurplusAfter"] = "collSurplusAfter";
    CollSurplusChange_OrderBy["CollSurplusBefore"] = "collSurplusBefore";
    CollSurplusChange_OrderBy["CollSurplusChange"] = "collSurplusChange";
    CollSurplusChange_OrderBy["Id"] = "id";
    CollSurplusChange_OrderBy["SequenceNumber"] = "sequenceNumber";
    CollSurplusChange_OrderBy["SystemStateAfter"] = "systemStateAfter";
    CollSurplusChange_OrderBy["SystemStateBefore"] = "systemStateBefore";
    CollSurplusChange_OrderBy["Transaction"] = "transaction";
    CollSurplusChange_OrderBy["User"] = "user";
})(CollSurplusChange_OrderBy || (CollSurplusChange_OrderBy = {}));
export var Global_OrderBy;
(function (Global_OrderBy) {
    Global_OrderBy["ChangeCount"] = "changeCount";
    Global_OrderBy["CurrentLiquidation"] = "currentLiquidation";
    Global_OrderBy["CurrentRedemption"] = "currentRedemption";
    Global_OrderBy["CurrentSystemState"] = "currentSystemState";
    Global_OrderBy["Id"] = "id";
    Global_OrderBy["LiquidationCount"] = "liquidationCount";
    Global_OrderBy["NumberOfLiquidatedTroves"] = "numberOfLiquidatedTroves";
    Global_OrderBy["NumberOfOpenTroves"] = "numberOfOpenTroves";
    Global_OrderBy["NumberOfRedeemedTroves"] = "numberOfRedeemedTroves";
    Global_OrderBy["NumberOfTrovesClosedByOwner"] = "numberOfTrovesClosedByOwner";
    Global_OrderBy["RawTotalRedistributedCollateral"] = "rawTotalRedistributedCollateral";
    Global_OrderBy["RawTotalRedistributedDebt"] = "rawTotalRedistributedDebt";
    Global_OrderBy["RedemptionCount"] = "redemptionCount";
    Global_OrderBy["SystemStateCount"] = "systemStateCount";
    Global_OrderBy["TmpDepositUpdate"] = "tmpDepositUpdate";
    Global_OrderBy["TotalBorrowingFeesPaidRbtc"] = "totalBorrowingFeesPaidRBTC";
    Global_OrderBy["TotalBorrowingFeesPaidZusd"] = "totalBorrowingFeesPaidZUSD";
    Global_OrderBy["TotalLiquidationCompensation"] = "totalLiquidationCompensation";
    Global_OrderBy["TotalLiquidationVolume"] = "totalLiquidationVolume";
    Global_OrderBy["TotalNumberOfTroves"] = "totalNumberOfTroves";
    Global_OrderBy["TotalRedemptionFeesPaidRbtc"] = "totalRedemptionFeesPaidRBTC";
    Global_OrderBy["TotalRedemptionFeesPaidZusd"] = "totalRedemptionFeesPaidZUSD";
    Global_OrderBy["TotalStabilityPoolProfits"] = "totalStabilityPoolProfits";
    Global_OrderBy["TransactionCount"] = "transactionCount";
})(Global_OrderBy || (Global_OrderBy = {}));
export var Liquidation_OrderBy;
(function (Liquidation_OrderBy) {
    Liquidation_OrderBy["CollGasCompensation"] = "collGasCompensation";
    Liquidation_OrderBy["Id"] = "id";
    Liquidation_OrderBy["LiquidatedCollateral"] = "liquidatedCollateral";
    Liquidation_OrderBy["LiquidatedDebt"] = "liquidatedDebt";
    Liquidation_OrderBy["Liquidator"] = "liquidator";
    Liquidation_OrderBy["SequenceNumber"] = "sequenceNumber";
    Liquidation_OrderBy["TokenGasCompensation"] = "tokenGasCompensation";
    Liquidation_OrderBy["Transaction"] = "transaction";
    Liquidation_OrderBy["TroveChanges"] = "troveChanges";
})(Liquidation_OrderBy || (Liquidation_OrderBy = {}));
/** Defines the order direction, either ascending or descending */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export var PriceChange_OrderBy;
(function (PriceChange_OrderBy) {
    PriceChange_OrderBy["Id"] = "id";
    PriceChange_OrderBy["PriceChange"] = "priceChange";
    PriceChange_OrderBy["SequenceNumber"] = "sequenceNumber";
    PriceChange_OrderBy["SystemStateAfter"] = "systemStateAfter";
    PriceChange_OrderBy["SystemStateBefore"] = "systemStateBefore";
    PriceChange_OrderBy["Transaction"] = "transaction";
})(PriceChange_OrderBy || (PriceChange_OrderBy = {}));
export var RedemptionRaw_OrderBy;
(function (RedemptionRaw_OrderBy) {
    RedemptionRaw_OrderBy["RbtcFee"] = "_RBTCFee";
    RedemptionRaw_OrderBy["RbtcSent"] = "_RBTCSent";
    RedemptionRaw_OrderBy["ActualZusdAmount"] = "_actualZUSDAmount";
    RedemptionRaw_OrderBy["AttemptedZusdAmount"] = "_attemptedZUSDAmount";
    RedemptionRaw_OrderBy["Id"] = "id";
})(RedemptionRaw_OrderBy || (RedemptionRaw_OrderBy = {}));
export var Redemption_OrderBy;
(function (Redemption_OrderBy) {
    Redemption_OrderBy["CollateralRedeemed"] = "collateralRedeemed";
    Redemption_OrderBy["Fee"] = "fee";
    Redemption_OrderBy["Id"] = "id";
    Redemption_OrderBy["Partial"] = "partial";
    Redemption_OrderBy["Redeemer"] = "redeemer";
    Redemption_OrderBy["SequenceNumber"] = "sequenceNumber";
    Redemption_OrderBy["TokensActuallyRedeemed"] = "tokensActuallyRedeemed";
    Redemption_OrderBy["TokensAttemptedToRedeem"] = "tokensAttemptedToRedeem";
    Redemption_OrderBy["Transaction"] = "transaction";
    Redemption_OrderBy["TroveChanges"] = "troveChanges";
})(Redemption_OrderBy || (Redemption_OrderBy = {}));
export var RevenueDaily_OrderBy;
(function (RevenueDaily_OrderBy) {
    RevenueDaily_OrderBy["BorrowFeeRbtc"] = "borrowFeeRBTC";
    RevenueDaily_OrderBy["BorrowFeeZusd"] = "borrowFeeZUSD";
    RevenueDaily_OrderBy["Id"] = "id";
    RevenueDaily_OrderBy["LiquidationCompensation"] = "liquidationCompensation";
    RevenueDaily_OrderBy["LiquidationVolume"] = "liquidationVolume";
    RevenueDaily_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    RevenueDaily_OrderBy["RedemptionFeeRbtc"] = "redemptionFeeRBTC";
    RevenueDaily_OrderBy["RedemptionFeeZusd"] = "redemptionFeeZUSD";
    RevenueDaily_OrderBy["StabilityPoolProfit"] = "stabilityPoolProfit";
})(RevenueDaily_OrderBy || (RevenueDaily_OrderBy = {}));
export var RevenueWeekly_OrderBy;
(function (RevenueWeekly_OrderBy) {
    RevenueWeekly_OrderBy["BorrowFeeRbtc"] = "borrowFeeRBTC";
    RevenueWeekly_OrderBy["BorrowFeeZusd"] = "borrowFeeZUSD";
    RevenueWeekly_OrderBy["Id"] = "id";
    RevenueWeekly_OrderBy["LiquidationCompensation"] = "liquidationCompensation";
    RevenueWeekly_OrderBy["LiquidationVolume"] = "liquidationVolume";
    RevenueWeekly_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    RevenueWeekly_OrderBy["RedemptionFeeRbtc"] = "redemptionFeeRBTC";
    RevenueWeekly_OrderBy["RedemptionFeeZusd"] = "redemptionFeeZUSD";
    RevenueWeekly_OrderBy["StabilityPoolProfit"] = "stabilityPoolProfit";
})(RevenueWeekly_OrderBy || (RevenueWeekly_OrderBy = {}));
export var SovDistribution_OrderBy;
(function (SovDistribution_OrderBy) {
    SovDistribution_OrderBy["Amount"] = "amount";
    SovDistribution_OrderBy["Id"] = "id";
    SovDistribution_OrderBy["Timestamp"] = "timestamp";
    SovDistribution_OrderBy["User"] = "user";
})(SovDistribution_OrderBy || (SovDistribution_OrderBy = {}));
export var StabilityDepositChange_OrderBy;
(function (StabilityDepositChange_OrderBy) {
    StabilityDepositChange_OrderBy["BlockNumber"] = "blockNumber";
    StabilityDepositChange_OrderBy["CollateralGain"] = "collateralGain";
    StabilityDepositChange_OrderBy["DepositedAmountAfter"] = "depositedAmountAfter";
    StabilityDepositChange_OrderBy["DepositedAmountBefore"] = "depositedAmountBefore";
    StabilityDepositChange_OrderBy["DepositedAmountChange"] = "depositedAmountChange";
    StabilityDepositChange_OrderBy["Id"] = "id";
    StabilityDepositChange_OrderBy["SequenceNumber"] = "sequenceNumber";
    StabilityDepositChange_OrderBy["StabilityDeposit"] = "stabilityDeposit";
    StabilityDepositChange_OrderBy["StabilityDepositOperation"] = "stabilityDepositOperation";
    StabilityDepositChange_OrderBy["SystemStateAfter"] = "systemStateAfter";
    StabilityDepositChange_OrderBy["SystemStateBefore"] = "systemStateBefore";
    StabilityDepositChange_OrderBy["Transaction"] = "transaction";
})(StabilityDepositChange_OrderBy || (StabilityDepositChange_OrderBy = {}));
export var StabilityDepositOperation;
(function (StabilityDepositOperation) {
    StabilityDepositOperation["DepositTokens"] = "depositTokens";
    StabilityDepositOperation["WithdrawCollateralGain"] = "withdrawCollateralGain";
    StabilityDepositOperation["WithdrawGainToLineOfCredit"] = "withdrawGainToLineOfCredit";
    StabilityDepositOperation["WithdrawTokens"] = "withdrawTokens";
})(StabilityDepositOperation || (StabilityDepositOperation = {}));
export var StabilityDeposit_OrderBy;
(function (StabilityDeposit_OrderBy) {
    StabilityDeposit_OrderBy["Changes"] = "changes";
    StabilityDeposit_OrderBy["DepositedAmount"] = "depositedAmount";
    StabilityDeposit_OrderBy["Id"] = "id";
    StabilityDeposit_OrderBy["Owner"] = "owner";
})(StabilityDeposit_OrderBy || (StabilityDeposit_OrderBy = {}));
export var SystemState_OrderBy;
(function (SystemState_OrderBy) {
    SystemState_OrderBy["Cause"] = "cause";
    SystemState_OrderBy["CollSurplusPoolBalance"] = "collSurplusPoolBalance";
    SystemState_OrderBy["Id"] = "id";
    SystemState_OrderBy["Price"] = "price";
    SystemState_OrderBy["SequenceNumber"] = "sequenceNumber";
    SystemState_OrderBy["TokensInStabilityPool"] = "tokensInStabilityPool";
    SystemState_OrderBy["TotalCollateral"] = "totalCollateral";
    SystemState_OrderBy["TotalCollateralRatio"] = "totalCollateralRatio";
    SystemState_OrderBy["TotalDebt"] = "totalDebt";
})(SystemState_OrderBy || (SystemState_OrderBy = {}));
export var TempDepositUpdate_OrderBy;
(function (TempDepositUpdate_OrderBy) {
    TempDepositUpdate_OrderBy["Amount"] = "amount";
    TempDepositUpdate_OrderBy["Id"] = "id";
})(TempDepositUpdate_OrderBy || (TempDepositUpdate_OrderBy = {}));
export var TokenAllowance_OrderBy;
(function (TokenAllowance_OrderBy) {
    TokenAllowance_OrderBy["Id"] = "id";
    TokenAllowance_OrderBy["Owner"] = "owner";
    TokenAllowance_OrderBy["Spender"] = "spender";
    TokenAllowance_OrderBy["Token"] = "token";
    TokenAllowance_OrderBy["Value"] = "value";
})(TokenAllowance_OrderBy || (TokenAllowance_OrderBy = {}));
export var TokenBalance_OrderBy;
(function (TokenBalance_OrderBy) {
    TokenBalance_OrderBy["Balance"] = "balance";
    TokenBalance_OrderBy["Id"] = "id";
    TokenBalance_OrderBy["Owner"] = "owner";
    TokenBalance_OrderBy["Token"] = "token";
})(TokenBalance_OrderBy || (TokenBalance_OrderBy = {}));
export var Token_OrderBy;
(function (Token_OrderBy) {
    Token_OrderBy["Allowances"] = "allowances";
    Token_OrderBy["Balances"] = "balances";
    Token_OrderBy["Id"] = "id";
    Token_OrderBy["Name"] = "name";
    Token_OrderBy["Symbol"] = "symbol";
    Token_OrderBy["TotalSupply"] = "totalSupply";
})(Token_OrderBy || (Token_OrderBy = {}));
export var Transaction_OrderBy;
(function (Transaction_OrderBy) {
    Transaction_OrderBy["BlockNumber"] = "blockNumber";
    Transaction_OrderBy["Changes"] = "changes";
    Transaction_OrderBy["From"] = "from";
    Transaction_OrderBy["FunctionSignature"] = "functionSignature";
    Transaction_OrderBy["GasLimit"] = "gasLimit";
    Transaction_OrderBy["GasPrice"] = "gasPrice";
    Transaction_OrderBy["GasUsed"] = "gasUsed";
    Transaction_OrderBy["Id"] = "id";
    Transaction_OrderBy["Index"] = "index";
    Transaction_OrderBy["SequenceNumber"] = "sequenceNumber";
    Transaction_OrderBy["Timestamp"] = "timestamp";
    Transaction_OrderBy["To"] = "to";
    Transaction_OrderBy["Value"] = "value";
})(Transaction_OrderBy || (Transaction_OrderBy = {}));
export var TroveChange_OrderBy;
(function (TroveChange_OrderBy) {
    TroveChange_OrderBy["BorrowingFee"] = "borrowingFee";
    TroveChange_OrderBy["CollateralAfter"] = "collateralAfter";
    TroveChange_OrderBy["CollateralBefore"] = "collateralBefore";
    TroveChange_OrderBy["CollateralChange"] = "collateralChange";
    TroveChange_OrderBy["CollateralRatioAfter"] = "collateralRatioAfter";
    TroveChange_OrderBy["CollateralRatioBefore"] = "collateralRatioBefore";
    TroveChange_OrderBy["DebtAfter"] = "debtAfter";
    TroveChange_OrderBy["DebtBefore"] = "debtBefore";
    TroveChange_OrderBy["DebtChange"] = "debtChange";
    TroveChange_OrderBy["Id"] = "id";
    TroveChange_OrderBy["Liquidation"] = "liquidation";
    TroveChange_OrderBy["Redemption"] = "redemption";
    TroveChange_OrderBy["SequenceNumber"] = "sequenceNumber";
    TroveChange_OrderBy["SystemStateAfter"] = "systemStateAfter";
    TroveChange_OrderBy["SystemStateBefore"] = "systemStateBefore";
    TroveChange_OrderBy["Transaction"] = "transaction";
    TroveChange_OrderBy["Trove"] = "trove";
    TroveChange_OrderBy["TroveOperation"] = "troveOperation";
})(TroveChange_OrderBy || (TroveChange_OrderBy = {}));
export var TroveOperation;
(function (TroveOperation) {
    TroveOperation["AccrueRewards"] = "accrueRewards";
    TroveOperation["AdjustTrove"] = "adjustTrove";
    TroveOperation["CloseTrove"] = "closeTrove";
    TroveOperation["LiquidateInNormalMode"] = "liquidateInNormalMode";
    TroveOperation["LiquidateInRecoveryMode"] = "liquidateInRecoveryMode";
    TroveOperation["OpenTrove"] = "openTrove";
    TroveOperation["RedeemCollateral"] = "redeemCollateral";
    TroveOperation["TransferGainToLineOfCredit"] = "transferGainToLineOfCredit";
})(TroveOperation || (TroveOperation = {}));
export var TroveStatus;
(function (TroveStatus) {
    TroveStatus["ClosedByLiquidation"] = "closedByLiquidation";
    TroveStatus["ClosedByOwner"] = "closedByOwner";
    TroveStatus["ClosedByRedemption"] = "closedByRedemption";
    TroveStatus["Open"] = "open";
})(TroveStatus || (TroveStatus = {}));
export var Trove_OrderBy;
(function (Trove_OrderBy) {
    Trove_OrderBy["Changes"] = "changes";
    Trove_OrderBy["Collateral"] = "collateral";
    Trove_OrderBy["CollateralRatioSortKey"] = "collateralRatioSortKey";
    Trove_OrderBy["CollateralRatioSortKeyLegacy"] = "collateralRatioSortKey_legacy";
    Trove_OrderBy["Debt"] = "debt";
    Trove_OrderBy["Id"] = "id";
    Trove_OrderBy["Owner"] = "owner";
    Trove_OrderBy["RawCollateral"] = "rawCollateral";
    Trove_OrderBy["RawDebt"] = "rawDebt";
    Trove_OrderBy["RawSnapshotOfTotalRedistributedCollateral"] = "rawSnapshotOfTotalRedistributedCollateral";
    Trove_OrderBy["RawSnapshotOfTotalRedistributedDebt"] = "rawSnapshotOfTotalRedistributedDebt";
    Trove_OrderBy["RawStake"] = "rawStake";
    Trove_OrderBy["Status"] = "status";
})(Trove_OrderBy || (Trove_OrderBy = {}));
export var User_OrderBy;
(function (User_OrderBy) {
    User_OrderBy["Allowances"] = "allowances";
    User_OrderBy["Balances"] = "balances";
    User_OrderBy["CollSurplus"] = "collSurplus";
    User_OrderBy["CollSurplusChanges"] = "collSurplusChanges";
    User_OrderBy["Id"] = "id";
    User_OrderBy["Liquidations"] = "liquidations";
    User_OrderBy["Redemptions"] = "redemptions";
    User_OrderBy["StabilityDeposit"] = "stabilityDeposit";
    User_OrderBy["Trove"] = "trove";
})(User_OrderBy || (User_OrderBy = {}));
export var _SubgraphErrorPolicy_;
(function (_SubgraphErrorPolicy_) {
    /** Data will be returned even if the subgraph has indexing errors */
    _SubgraphErrorPolicy_["Allow"] = "allow";
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    _SubgraphErrorPolicy_["Deny"] = "deny";
})(_SubgraphErrorPolicy_ || (_SubgraphErrorPolicy_ = {}));
export const GetCollSurplusChangesDocument = gql `
  query getCollSurplusChanges(
    $skip: Int!
    $pageSize: Int!
    $orderBy: CollSurplusChange_orderBy
    $orderDirection: OrderDirection
    $filters: CollSurplusChange_filter
  ) {
    collSurplusChanges(
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $filters
    ) {
      id
      sequenceNumber
      collSurplusChange
      user {
        id
      }
      transaction {
        id
        timestamp
      }
    }
  }
`;
/**
 * __useGetCollSurplusChangesQuery__
 *
 * To run a query within a React component, call `useGetCollSurplusChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollSurplusChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollSurplusChangesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCollSurplusChangesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCollSurplusChangesDocument, options);
}
export function useGetCollSurplusChangesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCollSurplusChangesDocument, options);
}
export const GetRedemptionsDocument = gql `
  query getRedemptions(
    $skip: Int!
    $pageSize: Int!
    $orderBy: Redemption_orderBy
    $orderDirection: OrderDirection
    $filters: Redemption_filter
  ) {
    redemptions(
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $filters
    ) {
      id
      fee
      partial
      sequenceNumber
      collateralRedeemed
      tokensActuallyRedeemed
      tokensAttemptedToRedeem
      transaction {
        id
        timestamp
      }
    }
  }
`;
/**
 * __useGetRedemptionsQuery__
 *
 * To run a query within a React component, call `useGetRedemptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedemptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedemptionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRedemptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRedemptionsDocument, options);
}
export function useGetRedemptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRedemptionsDocument, options);
}
export const GetStabilityDepositChangesDocument = gql `
  query getStabilityDepositChanges(
    $skip: Int!
    $pageSize: Int!
    $orderBy: StabilityDepositChange_orderBy
    $orderDirection: OrderDirection
    $filters: StabilityDepositChange_filter
  ) {
    stabilityDepositChanges(
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $filters
    ) {
      sequenceNumber
      transaction {
        id
        timestamp
      }
      depositedAmountChange
      stabilityDepositOperation
      collateralGain
    }
  }
`;
/**
 * __useGetStabilityDepositChangesQuery__
 *
 * To run a query within a React component, call `useGetStabilityDepositChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStabilityDepositChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStabilityDepositChangesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetStabilityDepositChangesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStabilityDepositChangesDocument, options);
}
export function useGetStabilityDepositChangesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStabilityDepositChangesDocument, options);
}
export const GetStabilityPoolDocument = gql `
  query getStabilityPool(
    $user: ID!
    $skip: Int!
    $pageSize: Int!
    $orderBy: StabilityDepositChange_orderBy
    $orderDirection: OrderDirection
    $filters: StabilityDepositChange_filter
  ) {
    stabilityDeposits(where: { id: $user }) {
      changes(
        first: $pageSize
        skip: $skip
        orderBy: $orderBy
        orderDirection: $orderDirection
        where: $filters
      ) {
        depositedAmountChange
        depositedAmountAfter
        stabilityDepositOperation
        transaction {
          id
          timestamp
        }
        sequenceNumber
      }
    }
  }
`;
/**
 * __useGetStabilityPoolQuery__
 *
 * To run a query within a React component, call `useGetStabilityPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStabilityPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStabilityPoolQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetStabilityPoolQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStabilityPoolDocument, options);
}
export function useGetStabilityPoolLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStabilityPoolDocument, options);
}
export const GetSubsidyDocument = gql `
  query getSubsidy(
    $user: Bytes!
    $skip: Int!
    $pageSize: Int!
    $orderBy: SOVDistribution_orderBy
    $orderDirection: OrderDirection
  ) {
    sovdistributions(
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { user: $user }
    ) {
      id
      amount
      timestamp
    }
  }
`;
/**
 * __useGetSubsidyQuery__
 *
 * To run a query within a React component, call `useGetSubsidyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubsidyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubsidyQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetSubsidyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetSubsidyDocument, options);
}
export function useGetSubsidyLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetSubsidyDocument, options);
}
export const GetGlobalsEntityDocument = gql `
  query getGlobalsEntity {
    globals {
      rawTotalRedistributedDebt
      rawTotalRedistributedCollateral
    }
  }
`;
/**
 * __useGetGlobalsEntityQuery__
 *
 * To run a query within a React component, call `useGetGlobalsEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalsEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalsEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalsEntityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetGlobalsEntityDocument, options);
}
export function useGetGlobalsEntityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetGlobalsEntityDocument, options);
}
export const GetTroveDocument = gql `
  query getTrove(
    $user: ID!
    $skip: Int!
    $pageSize: Int!
    $orderBy: TroveChange_orderBy
    $orderDirection: OrderDirection
    $filters: TroveChange_filter
  ) {
    trove(id: $user) {
      debt
      id
      collateralRatioSortKey
      collateral
      status
      changes(
        first: $pageSize
        skip: $skip
        orderBy: $orderBy
        orderDirection: $orderDirection
        where: $filters
      ) {
        transaction {
          id
          timestamp
        }
        collateralBefore
        collateralChange
        collateralAfter
        debtBefore
        debtChange
        debtAfter
        borrowingFee
        troveOperation
        redemption {
          partial
        }
      }
    }
  }
`;
/**
 * __useGetTroveQuery__
 *
 * To run a query within a React component, call `useGetTroveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTroveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTroveQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTroveQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTroveDocument, options);
}
export function useGetTroveLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTroveDocument, options);
}
export const GetTrovesDocument = gql `
  query getTroves($first: Int!) {
    troves(
      first: $first
      where: { status: open }
      orderBy: collateralRatioSortKey_legacy
      orderDirection: desc
    ) {
      id
      collateral
      debt
      status
      collateralRatioSortKey
      collateralRatioSortKey_legacy
      rawCollateral
      rawDebt
      rawSnapshotOfTotalRedistributedCollateral
      rawSnapshotOfTotalRedistributedDebt
      rawStake
    }
  }
`;
/**
 * __useGetTrovesQuery__
 *
 * To run a query within a React component, call `useGetTrovesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrovesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrovesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetTrovesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTrovesDocument, options);
}
export function useGetTrovesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTrovesDocument, options);
}
export const GetUserOpenTroveDocument = gql `
  query getUserOpenTrove($user: ID!) {
    trove(id: $user) {
      id
      collateral
      debt
      status
      collateralRatioSortKey
      collateralRatioSortKey_legacy
      rawCollateral
      rawDebt
      rawSnapshotOfTotalRedistributedCollateral
      rawSnapshotOfTotalRedistributedDebt
      rawStake
      changes(
        where: { troveOperation: openTrove }
        orderBy: sequenceNumber
        orderDirection: desc
      ) {
        id
        sequenceNumber
        transaction {
          id
          sequenceNumber
        }
        trove {
          id
          collateralRatioSortKey
          collateral
          debt
          status
        }
      }
    }
  }
`;
/**
 * __useGetUserOpenTroveQuery__
 *
 * To run a query within a React component, call `useGetUserOpenTroveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOpenTroveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOpenTroveQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetUserOpenTroveQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserOpenTroveDocument, options);
}
export function useGetUserOpenTroveLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserOpenTroveDocument, options);
}
