import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, ErrorBadge, ErrorLevel, Heading, HeadingType, } from '@sovryn/ui';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { translations } from '../../../../../../locales/i18n';
import { getBtcExplorerUrl } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
import { useSendFlowContext } from '../../../contexts/sendflow';
import { useChainDetails } from '../../../hooks/useChainDetails';
import { useRuneBridgeLocked } from '../../../hooks/useRuneBridgeLocked';
const translation = translations.runeBridge.send.confirmationScreens;
const btcExplorerUrl = getBtcExplorerUrl();
export const ReviewScreen = ({ amount, onConfirm, feesPaid, receiveAmount, from, to, }) => {
    const runeBridgeLocked = useRuneBridgeLocked();
    const { selectedToken } = useSendFlowContext();
    const { explorerUrl, baseCurrency } = useChainDetails();
    const items = useMemo(() => [
        {
            label: t(translation.from),
            value: (React.createElement(TxIdWithNotification, { value: from, href: `${explorerUrl}/address/${from}` })),
        },
        {
            label: t(translation.to),
            value: (React.createElement(TxIdWithNotification, { value: to, href: `${btcExplorerUrl}/address/${to}` })),
        },
        {
            label: t(translation.sending),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(amount), 8),
                " ",
                selectedToken.symbol)),
        },
        {
            label: t(translation.serviceFee),
            value: (React.createElement(React.Fragment, null,
                formatValue(feesPaid.rune, 8),
                " ",
                selectedToken.symbol,
                " +",
                ' ',
                formatValue(feesPaid.baseCurrency, 8),
                " ",
                baseCurrency)),
        },
        {
            label: t(translation.receiving),
            value: (React.createElement(React.Fragment, null,
                formatValue(receiveAmount, 8),
                " ",
                selectedToken.symbol)),
        },
    ], [
        amount,
        baseCurrency,
        feesPaid.baseCurrency,
        feesPaid.rune,
        from,
        receiveAmount,
        selectedToken.symbol,
        to,
        explorerUrl,
    ]);
    return (React.createElement("div", { className: "text-center" },
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-8" }, t(translation.reviewTitle)),
        React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                'mb-3': index !== items.length - 1,
            }), key: label },
            React.createElement("span", null,
                label,
                " "),
            React.createElement("span", null, value))))),
        React.createElement("div", { className: "mt-8" },
            React.createElement(Button, { text: t(translations.common.buttons.confirm), onClick: onConfirm, disabled: runeBridgeLocked, className: "w-full", dataAttribute: "funding-send-confirm" }),
            runeBridgeLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.runeBridge) })))));
};
