var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { APP_CHAIN_LIST, DEFAULT_CHAIN_ID } from '../config/chains';
import { onboard } from '../lib/connector';
export const useChainStore = create()(persist(set => ({
    currentChainId: DEFAULT_CHAIN_ID,
    setCurrentChainId: (chainId = DEFAULT_CHAIN_ID) => __awaiter(void 0, void 0, void 0, function* () {
        if (onboard.state.get().wallets.length > 0) {
            // todo: should disconnect wallets which does not support network changes
            yield onboard.setChain({ chainId });
        }
        set({ currentChainId: chainId });
    }),
}), {
    name: 'chain-storage',
    storage: createJSONStorage(() => localStorage),
    onRehydrateStorage: () => {
        return (s, e) => {
            if (e) {
                console.warn('chain store rehydration error', e);
                return;
            }
            // reset to default chain if current chain is not in the list
            if ((s === null || s === void 0 ? void 0 : s.currentChainId) &&
                !APP_CHAIN_LIST.find(item => item.id === s.currentChainId)) {
                s.setCurrentChainId(APP_CHAIN_LIST[0].id);
            }
        };
    },
}));
export const useCurrentChain = () => useChainStore(state => state.currentChainId);
export const getCurrentChain = () => useChainStore.getState().currentChainId;
export const setCurrentChain = (chainId = DEFAULT_CHAIN_ID) => useChainStore.getState().setCurrentChainId(chainId);
