import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, Heading, HeadingType, Icon, IconNames, StatusType, } from '@sovryn/ui';
import { StatusIcon } from '../../../../../2_molecules/StatusIcon/StatusIcon';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BITCOIN } from '../../../../../../constants/currencies';
import { useBlockNumber } from '../../../../../../hooks/useBlockNumber';
import { translations } from '../../../../../../locales/i18n';
import { useGetBitcoinTxIdQuery } from '../../../../../../utils/graphql/rsk/generated';
import { getBtcExplorerUrl, getRskExplorerUrl, } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
const translation = translations.fastBtc.send.confirmationScreens;
const getTitle = (status) => {
    switch (status) {
        case StatusType.error:
            return t(translation.statusTitleFailed);
        case StatusType.success:
            return t(translation.statusTitleComplete);
        default:
            return t(translation.statusTitleProcessing);
    }
};
const rskExplorerUrl = getRskExplorerUrl();
const btcExplorerUrl = getBtcExplorerUrl();
export const StatusScreen = ({ from, to, amount, feesPaid, receiveAmount, txHash, txStatus, onClose, onRetry, }) => {
    const { value: block } = useBlockNumber();
    const { data, refetch } = useGetBitcoinTxIdQuery({
        variables: { createdAtTx: txHash || '' },
    });
    const bitcoinTxHash = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.bitcoinTransfers) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.bitcoinTxHash; }, [data]);
    useEffect(() => {
        refetch();
    }, [refetch, txHash, block]);
    const items = useMemo(() => [
        {
            label: t(translation.from),
            value: (React.createElement(TxIdWithNotification, { value: from, href: `${rskExplorerUrl}/address/${from}` })),
        },
        {
            label: t(translation.to),
            value: (React.createElement(TxIdWithNotification, { value: to, href: `${btcExplorerUrl}/address/${to}` })),
        },
        {
            label: t(translation.sending),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(amount), 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.serviceFee),
            value: (React.createElement(React.Fragment, null,
                formatValue(feesPaid, 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.receiving),
            value: (React.createElement(React.Fragment, null,
                formatValue(receiveAmount, 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.hash),
            value: txHash ? (React.createElement(TxIdWithNotification, { value: txHash, href: `${rskExplorerUrl}/tx/${txHash}` })) : (React.createElement(Icon, { icon: IconNames.PENDING })),
        },
        {
            label: t(translation.bitcoinTxId),
            value: bitcoinTxHash ? (React.createElement(TxIdWithNotification, { value: bitcoinTxHash, href: `${btcExplorerUrl}/tx/${bitcoinTxHash}` })) : (React.createElement(Icon, { icon: IconNames.PENDING })),
        },
    ], [amount, bitcoinTxHash, feesPaid, from, receiveAmount, to, txHash]);
    const status = useMemo(() => {
        if (txStatus !== StatusType.success) {
            return txStatus;
        }
        if (!bitcoinTxHash) {
            return StatusType.pending;
        }
        return StatusType.success;
    }, [bitcoinTxHash, txStatus]);
    const hasTransactionFailed = useMemo(() => status === StatusType.error, [status]);
    const isDoneButtonDisabled = useMemo(() => status === StatusType.pending, [status]);
    return (React.createElement("div", { className: "text-center" },
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-6" }, getTitle(status)),
        React.createElement("div", { className: "mb-6" },
            React.createElement(StatusIcon, { status: status, dataAttribute: "funding-send-status" })),
        React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                'mb-3': index !== items.length - 1,
            }), key: label },
            React.createElement("span", null,
                label,
                " "),
            React.createElement("span", null, value))))),
        React.createElement(Button, { text: t(translations.common.buttons[hasTransactionFailed ? 'retry' : 'done']), onClick: hasTransactionFailed ? onRetry : onClose, disabled: isDoneButtonDisabled, className: "mt-8 w-full", dataAttribute: `funding-send-${hasTransactionFailed ? 'retry' : 'done'}` })));
};
