import React, { useCallback, useEffect, useMemo } from 'react';
import { t } from 'i18next';
import { Paragraph, NotificationType, Button, ButtonStyle, Link, } from '@sovryn/ui';
import { WIKI_LINKS } from '../constants/links';
import { useNotificationContext } from '../contexts/NotificationContext';
import { getChainById } from '../utils/chain';
import { useCurrentChain } from './useChainStore';
import { useWalletConnect } from './useWalletConnect';
const WrongNetworkSwitcherId = 'WrongNetworkSwitcher';
export const useWrongNetworkCheck = () => {
    const chainId = useCurrentChain();
    const { addNotification, removeNotification } = useNotificationContext();
    const { wallets, switchNetwork } = useWalletConnect();
    const isWrongChain = useMemo(() => {
        var _a, _b;
        return (((_b = (_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address) && wallets[0].chains[0].id !== chainId);
    }, [wallets, chainId]);
    const switchChain = useCallback(() => {
        switchNetwork(chainId);
    }, [chainId, switchNetwork]);
    useEffect(() => {
        if (isWrongChain) {
            const expectedChain = getChainById(chainId);
            addNotification({
                type: NotificationType.warning,
                title: t('wrongNetworkSwitcher.title'),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(Paragraph, null, t('wrongNetworkSwitcher.description', {
                        network: expectedChain === null || expectedChain === void 0 ? void 0 : expectedChain.label,
                    })),
                    React.createElement(Link, { text: t('wrongNetworkSwitcher.learnMore'), href: WIKI_LINKS.WALLET_SETUP, openNewTab: true, className: "block mt-1" }),
                    React.createElement(Button, { className: "mb-2 mt-3", style: ButtonStyle.secondary, text: t('common.buttons.switch'), onClick: switchChain }))),
                dismissible: false,
                id: WrongNetworkSwitcherId,
            }, 0);
        }
        else {
            removeNotification(WrongNetworkSwitcherId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWrongChain]);
};
