import { ChainIds } from '@sovryn/ethers-provider';
import { APP_CHAIN_LIST } from '../config/chains';
import { isMainnet } from './helpers';
/** @deprecated use RSK_CHAIN_ID */
export const getRskChainId = () => isMainnet() ? ChainIds.RSK_MAINNET : ChainIds.RSK_TESTNET;
export const isRskChain = (chainId) => [ChainIds.RSK_MAINNET, ChainIds.RSK_TESTNET].includes(chainId);
export const isBscChain = (chainId) => [ChainIds.BSC_MAINNET, ChainIds.BSC_TESTNET].includes(chainId);
export const isBobChain = (chainId) => [ChainIds.BOB_MAINNET, ChainIds.BOB_TESTNET].includes(chainId);
export const isEthChain = (chainId) => [ChainIds.MAINNET, ChainIds.SEPOLIA, ChainIds.ROPSTEN].includes(chainId);
export const getChainById = (chainId) => APP_CHAIN_LIST.find(chain => chain.id === chainId);
export const getChainLabel = (chainId) => { var _a; return (_a = getChainById(chainId)) === null || _a === void 0 ? void 0 : _a.label; };
