import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { NotificationStack } from '@sovryn/ui';
import { DEFAULT_TIMEOUT_SECONDS } from '../constants/general';
const NotificationContext = createContext(null);
export const useNotificationContext = () => {
    return useContext(NotificationContext);
};
export const NotificationProvider = ({ children, }) => {
    const [time, setTime] = useState(Date.now());
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(Date.now());
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const addNotification = useCallback((notification, timeout = DEFAULT_TIMEOUT_SECONDS) => {
        if (notifications.find(item => item.id === notification.id)) {
            return;
        }
        const prvNotifications = [...notifications];
        prvNotifications.unshift(Object.assign(Object.assign({}, notification), { timestamp: Date.now(), timeout }));
        setNotifications(prvNotifications);
    }, [notifications]);
    const removeNotification = useCallback((id) => {
        const prvNotifications = [...notifications];
        setNotifications(prvNotifications.filter(notification => notification.id !== id));
    }, [notifications]);
    const notificationList = useMemo(() => {
        return notifications.filter(notification => !notification.timeout ||
            notification.timestamp + notification.timeout > time);
    }, [notifications, time]);
    return (React.createElement(NotificationContext.Provider, { value: {
            addNotification,
            removeNotification,
        } },
        React.createElement(NotificationStack, { className: "top-16", items: notificationList, onClose: removeNotification }),
        children));
};
