import React from 'react';
import { noop } from '@sovryn/ui';
export const defaultValue = {
    tokenBalances: [],
    runeBridgeContract: undefined,
    depositAddress: '',
    set: noop,
};
export const tokenABI = [
    'function balanceOf(address) view returns (uint)',
    'function symbol() view returns (string)',
    'function name() view returns (string)',
    'function decimals() view returns (uint)',
];
export const RuneContext = React.createContext(defaultValue);
export const useRuneContext = () => {
    return React.useContext(RuneContext);
};
