import React, { createContext, useContext, useState } from 'react';
export const defaultValue = {
    transactions: [],
    setTransactions: () => { },
    isOpen: false,
    setIsOpen: () => { },
    title: '',
    setTitle: () => { },
    txTrigger: '',
    setTxTrigger: () => { },
};
export const TransactionContext = createContext(defaultValue);
export const useTransactionContext = () => {
    return useContext(TransactionContext);
};
export const TransactionProvider = ({ children }) => {
    const [transactions, setTransactions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [txTrigger, setTxTrigger] = useState('');
    return (React.createElement(TransactionContext.Provider, { value: {
            transactions,
            setTransactions,
            isOpen,
            setIsOpen,
            title,
            setTitle,
            txTrigger,
            setTxTrigger,
        } }, children));
};
