export const ACTIVE_CLASSNAME = 'border-t-primary-30';
export const DEPOSIT_FEE_RUNE_PERCENTAGE = 0.4;
export const MIN_POSTAGE_SATS = 10000;
export const MIN_POSTAGE_BTC = MIN_POSTAGE_SATS / 1e8;
export const GAS_LIMIT_RUNE_BRIDGE_WITHDRAW = 200000;
export const ORD_WALLET_LINK = 'https://github.com/ordinals/ord';
export const DEPOSIT_ADDRESS_PATH = 'deposit-addresses/';
export const LAST_SCANNED_BTC_BLOCK_PATH = 'last-scanned-btc-block/';
export const DEPOSITS_PATH = 'deposits';
export const RUNES_RSK_BRIDGE_NAME = 'runes';
export const RUNES_BOB_BRIDGE_NAME = 'runesbob';
