import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, Heading, HeadingType, Icon, IconNames, StatusType, } from '@sovryn/ui';
import { StatusIcon } from '../../../../../2_molecules/StatusIcon/StatusIcon';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { useAccount } from '../../../../../../hooks/useAccount';
import { translations } from '../../../../../../locales/i18n';
import { getBtcExplorerUrl } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
import { ReceiveflowStep, } from '../../../contexts/receiveflow';
import { useReceiveFlowContext } from '../../../contexts/receiveflow';
import { useChainDetails } from '../../../hooks/useChainDetails';
const translation = translations.runeBridge.receive.statusScreen;
const btcExplorerUrl = getBtcExplorerUrl();
const formatTxStatus = (status, chainName) => {
    switch (status) {
        case 'detected':
        case 'seen':
        case 'sent_to_evm':
        case 'confirmed':
            return t(translation.transferStatus[status], { chainName });
        default:
            return status;
    }
};
export const StatusScreen = ({ onClose }) => {
    const { account } = useAccount();
    const { step, depositTx } = useReceiveFlowContext();
    const { explorerUrl, chainName } = useChainDetails();
    const isProcessing = useMemo(() => step === ReceiveflowStep.PROCESSING, [step]);
    const items = useMemo(() => {
        var _a;
        const { currentTX } = depositTx;
        const runeSymbol = (_a = currentTX.runeSymbol) !== null && _a !== void 0 ? _a : currentTX.runeName;
        const fee = Number(currentTX.feeDecimal || 0);
        const amount = Number(currentTX.amountDecimal || 0);
        const receiveAmount = Number(currentTX.receiveAmountDecimal || 0);
        return [
            {
                label: t(translation.to),
                value: (React.createElement(TxIdWithNotification, { value: account, href: `${explorerUrl}/address/${account}` })),
            },
            {
                label: t(translation.sending),
                value: amount ? (React.createElement(React.Fragment, null,
                    formatValue(amount, 8),
                    " ",
                    runeSymbol)) : (React.createElement(Icon, { icon: IconNames.PENDING })),
            },
            {
                label: t(translation.serviceFee),
                value: fee ? (React.createElement(React.Fragment, null,
                    formatValue(fee, 8),
                    " ",
                    runeSymbol)) : (React.createElement(Icon, { icon: IconNames.PENDING })),
            },
            {
                label: t(translation.receiving),
                value: receiveAmount ? (React.createElement(React.Fragment, null,
                    formatValue(receiveAmount, 8),
                    " ",
                    runeSymbol)) : (React.createElement(Icon, { icon: IconNames.PENDING })),
            },
            {
                label: t(translation.bitcoinTxId),
                value: currentTX ? (React.createElement(React.Fragment, null,
                    React.createElement(TxIdWithNotification, { value: currentTX.btcDepositTxid, href: `${btcExplorerUrl}/tx/${currentTX.btcDepositTxid}` }))) : (React.createElement(Icon, { icon: IconNames.PENDING })),
            },
            {
                label: t(translation.currentChainTxId, { chainName }),
                value: currentTX ? (React.createElement(React.Fragment, null,
                    React.createElement(TxIdWithNotification, { value: currentTX.evmTransferTxHash, href: `${explorerUrl}/tx/${currentTX.evmTransferTxHash}` }))) : (React.createElement(Icon, { icon: IconNames.PENDING })),
            },
        ].filter(x => x);
    }, [account, chainName, depositTx, explorerUrl]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-center" },
            React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-6" }, isProcessing
                ? t(translation.statusTitleProcessing)
                : t(translation.statusTitleComplete)),
            React.createElement("div", { className: "mb-6" },
                React.createElement(StatusIcon, { status: isProcessing ? StatusType.pending : StatusType.success, dataAttribute: "funding-receive-status" })),
            React.createElement("div", { className: "mb-6" }, formatTxStatus(depositTx.currentTX.status, chainName)),
            React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                    'mb-3': index !== items.length - 1,
                }), key: label },
                React.createElement("span", null,
                    label,
                    " "),
                React.createElement("span", null, value)))))),
        React.createElement(Button, { text: t(translations.common.buttons.done), onClick: onClose, disabled: isProcessing, className: "mt-8 w-full", dataAttribute: "funding-receive-done" })));
};
