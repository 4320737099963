import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { Accordion } from '@sovryn/ui';
import { translations } from '../../../../locales/i18n';
import { RuneExplorerLink } from './RuneExplorerLink';
const translation = translations.runeBridge.limits;
export const TransferPolicies = ({ minimumAmount, maximumAmount, serviceFee, minimumPostage, supportedRunes, className, }) => {
    const [open, setOpen] = useState(true);
    const onClick = useCallback((toOpen) => setOpen(toOpen), []);
    return (React.createElement(Accordion, { label: t(translation.title), children: React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" },
            minimumAmount && (React.createElement("div", { className: "flex justify-between mb-3" },
                React.createElement("span", null, t(translation.minimumAmount)),
                React.createElement("span", null, minimumAmount))),
            maximumAmount && (React.createElement("div", { className: "flex justify-between mb-3" },
                React.createElement("span", null, t(translation.maximumAmount)),
                React.createElement("span", null, maximumAmount))),
            minimumPostage && (React.createElement("div", { className: "flex justify-between mb-3" },
                React.createElement("span", null, t(translation.minimumPostage)),
                React.createElement("span", null, minimumPostage))),
            serviceFee && (React.createElement("div", { className: "flex justify-between" },
                React.createElement("span", null, t(translation.serviceFee)),
                React.createElement("span", null, serviceFee))),
            supportedRunes && (React.createElement("div", { className: "flex justify-between mt-3" },
                React.createElement("span", null, t(translation.supportedRunes)),
                React.createElement("ul", null, supportedRunes.map(rune => (React.createElement("li", { key: rune },
                    React.createElement(RuneExplorerLink, { rune: rune })))))))), className: className, open: open, onClick: onClick }));
};
