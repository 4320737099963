import { useCallback, useMemo } from 'react';
import { useMaintenanceModeContext } from '../contexts/MaintenanceModeContext';
import { isStaging } from '../utils/helpers';
var States;
(function (States) {
    // D1 states left here for reference
    States["FULL"] = "full";
    States["OPEN_MARGIN_TRADES"] = "openMarginTrades";
    States["ADD_TO_MARGIN_TRADES"] = "addToMarginTrades";
    States["CLOSE_MARGIN_TRADES"] = "closeMarginTrades";
    States["MARGIN_LIMIT"] = "openMarginLimit";
    States["CLOSE_MARGIN_LIMIT"] = "closeMarginLimit";
    States["SPOT_TRADES"] = "spotTrades";
    States["SPOT_LIMIT"] = "openSpotLimit";
    States["CLOSE_SPOT_LIMIT"] = "closeSpotLimit";
    States["SWAP_TRADES"] = "swapTrades";
    States["DEPOSIT_LEND"] = "depositLend";
    States["WITHDRAW_LEND"] = "withdrawLend";
    States["START_BORROW"] = "startBorrow";
    States["STOP_BORROW"] = "stopBorrow";
    States["ADD_LIQUIDITY"] = "addLiquidity";
    States["REMOVE_LIQUIDITY"] = "removeLiquidity";
    States["TRANSAK"] = "transak";
    States["BRIDGE"] = "bridge";
    States["ETH_BRIDGE"] = "ethBridge";
    States["BSC_BRIDGE"] = "bscBridge";
    States["ETH_BRIDGE_DEPOSIT"] = "ethBridgeDeposit";
    States["ETH_BRIDGE_WITHDRAW"] = "ethBridgeWithdraw";
    States["BSC_BRIDGE_DEPOSIT"] = "bscBridgeDeposit";
    States["BSC_BRIDGE_WITHDRAW"] = "bscBridgeWithdraw";
    States["BRIDGE_SOV_DEPOSIT"] = "bridgeSOVDeposit";
    States["BRIDGE_SOV_WITHDRAW"] = "bridgeSOVWithdraw";
    States["BRIDGE_XUSD_DEPOSIT"] = "bridgeXUSDDeposit";
    States["BRIDGE_XUSD_WITHDRAW"] = "bridgeXUSDWithdraw";
    States["BRIDGE_ETH_DEPOSIT"] = "bridgeETHDeposit";
    States["BRIDGE_ETH_WITHDRAW"] = "bridgeETHWithdraw";
    States["BRIDGE_BNB_DEPOSIT"] = "bridgeBNBDeposit";
    States["BRIDGE_BNB_WITHDRAW"] = "bridgeBNBWithdraw";
    States["STAKING"] = "staking";
    States["UNSTAKING"] = "unstaking";
    States["WITHDRAW_FEES"] = "withdrawEarnedFees";
    States["DELEGATE_STAKES"] = "delegateStakes";
    States["DELEGATE_VESTS"] = "delegateVests";
    States["WITHDRAW_VESTS"] = "withdrawVests";
    States["CLAIM_REWARDS"] = "claimRewards";
    States["CLAIM_REWARD_SOV"] = "claimRewardSov";
    States["CLAIM_LIQUID_SOV"] = "claimLiquidSov";
    States["CLAIM_FEES_EARNED"] = "claimFeesEarned";
    States["PERPETUALS"] = "perpetuals";
    States["PERPETUALS_ACCOUNT_FUND"] = "perpetualsAccountFund";
    States["PERPETUALS_ACCOUNT_WITHDRAW"] = "perpetualsAccountWithdraw";
    States["PERPETUALS_ACCOUNT_TRANSFER"] = "perpetualsAccountTransfer";
    States["PERPETUALS_TRADE"] = "perpetualsTrade";
    States["PERPETUALS_GSN"] = "perpetualsGsn";
    // D2 states
    States["FULLD2"] = "fullD2";
    States["FASTBTC_SEND"] = "fastBTCSend";
    States["FASTBTC_RECEIVE"] = "fastBTCReceive";
    States["ZERO_CONVERT"] = "zeroConvert";
    States["ZERO_CONVERT_SRC_MOC"] = "zeroConvertSrcMOC";
    States["ZERO_CONVERT_SRC_MYNT"] = "zeroConvertSrcMYNT";
    States["ZERO_CONVERT_SRC_SOV"] = "zeroConvertSrcSOV";
    States["ZERO_CONVERT_SRC_BNBSRBTC"] = "zeroConvertSrcSOVxBNBSRBTC";
    States["ZERO_CONVERT_SRC_DLLRRBTC"] = "zeroConvertSrcSOVxDLLRRBTC";
    States["ZERO_CONVERT_SRC_ETHSRBTC"] = "zeroConvertSrcSOVxETHSRBTC";
    States["ZERO_CONVERT_SRC_FISHRBTC"] = "zeroConvertSrcSOVxFISHRBTC";
    States["ZERO_CONVERT_SRC_MOCRBTC"] = "zeroConvertSrcSOVxMOCRBTC";
    States["ZERO_CONVERT_SRC_RIFRBTC"] = "zeroConvertSrcSOVxRIFRBTC";
    States["ZERO_CONVERT_SRC_SOVRBTC"] = "zeroConvertSrcSOVxSOVRBTC";
    States["ZERO_OPEN_LOC"] = "zeroOpenLOC";
    States["ZERO_ADJUST_LOC"] = "zeroAdjustLOC";
    States["ZERO_ADJUST_LOC_BORROW"] = "zeroAdjustLOCBorrow";
    States["ZERO_CLOSE_LOC"] = "zeroCloseLOC";
    States["ZERO_STABILITY_ADD"] = "zeroStabilityAdd";
    States["ZERO_STABILITY_REMOVE"] = "zeroStabilityRemove";
    States["ZERO_STABILITY_CLAIM"] = "zeroStabilityClaim";
    States["ZERO_REDEMPTIONS"] = "zeroRedemptions";
    States["ZERO_DLLR"] = "zeroDLLR";
    States["ZERO_EXPORT_CSV"] = "zeroExportCSV";
    States["REWARDS_FULL"] = "rewardsFull";
    States["REWARDS_STAKING"] = "rewardsStaking";
    States["STAKING_FULL"] = "stakingFull";
    States["STAKING_STAKE_SOV"] = "stakingStakeSOV";
    States["STAKING_NEW"] = "stakingNew";
    States["STAKING_INCREASE"] = "stakingIncrease";
    States["STAKING_EXTEND"] = "stakingExtend";
    States["STAKING_DECREASE"] = "stakingDecrease";
    States["STAKING_DELEGATE"] = "stakingVestingDelegate";
    States["BORROW_FULL"] = "borrowFull";
    States["D2_BORROW_BTC_NEW_LOANS"] = "d2BorrowBTCxNewLoans";
    States["D2_BORROW_BTC_BORROW"] = "d2BorrowBTCxBorrow";
    States["D2_BORROW_BTC_REPAY"] = "d2BorrowBTCxRepay";
    States["D2_BORROW_BTC_CLOSE"] = "d2BorrowBTCxClose";
    States["D2_BORROW_BTC_EXTEND"] = "d2BorrowBTCxExtend";
    States["D2_BORROW_BTC_ADD_COLLATERAL"] = "d2BorrowBTCxAddCollateral";
    States["D2_BORROW_BTC_WITHDRAW_COLLATERAL"] = "d2BorrowBTCxWithdrawCollateral";
    States["D2_BORROW_DLLR_NEW_LOANS"] = "d2BorrowDLLRxNewLoans";
    States["D2_BORROW_DLLR_BORROW"] = "d2BorrowDLLRxBorrow";
    States["D2_BORROW_DLLR_REPAY"] = "d2BorrowDLLRxRepay";
    States["D2_BORROW_DLLR_CLOSE"] = "d2BorrowDLLRxClose";
    States["D2_BORROW_DLLR_EXTEND"] = "d2BorrowDLLRxExtend";
    States["D2_BORROW_DLLR_ADD_COLLATERAL"] = "d2BorrowDLLRxAddCollateral";
    States["D2_BORROW_DLLR_WITHDRAW_COLLATERAL"] = "d2BorrowDLLRxWithdrawCollateral";
    States["D2_BORROW_XUSD_NEW_LOANS"] = "d2BorrowXUSDxNewLoans";
    States["D2_BORROW_XUSD_BORROW"] = "d2BorrowXUSDxBorrow";
    States["D2_BORROW_XUSD_REPAY"] = "d2BorrowXUSDxRepay";
    States["D2_BORROW_XUSD_CLOSE"] = "d2BorrowXUSDxClose";
    States["D2_BORROW_XUSD_EXTEND"] = "d2BorrowXUSDxExtend";
    States["D2_BORROW_XUSD_ADD_COLLATERAL"] = "d2BorrowXUSDxAddCollateral";
    States["D2_BORROW_XUSD_WITHDRAW_COLLATERAL"] = "d2BorrowXUSDxWithdrawCollateral";
    States["D2_BORROW_DOC_NEW_LOANS"] = "d2BorrowDOCxNewLoans";
    States["D2_BORROW_DOC_BORROW"] = "d2BorrowDOCxBorrow";
    States["D2_BORROW_DOC_REPAY"] = "d2BorrowDOCxRepay";
    States["D2_BORROW_DOC_CLOSE"] = "d2BorrowDOCxClose";
    States["D2_BORROW_DOC_EXTEND"] = "d2BorrowDOCxExtend";
    States["D2_BORROW_DOC_ADD_COLLATERAL"] = "d2BorrowDOCxAddCollateral";
    States["D2_BORROW_DOC_WITHDRAW_COLLATERAL"] = "d2BorrowDOCxWithdrawCollateral";
    States["D2_BORROW_USDT_NEW_LOANS"] = "d2BorrowUSDTxNewLoans";
    States["D2_BORROW_USDT_BORROW"] = "d2BorrowUSDTxBorrow";
    States["D2_BORROW_USDT_REPAY"] = "d2BorrowUSDTxRepay";
    States["D2_BORROW_USDT_CLOSE"] = "d2BorrowUSDTxClose";
    States["D2_BORROW_USDT_EXTEND"] = "d2BorrowUSDTxExtend";
    States["D2_BORROW_USDT_ADD_COLLATERAL"] = "d2BorrowUSDTxAddCollateral";
    States["D2_BORROW_USDT_WITHDRAW_COLLATERAL"] = "d2BorrowUSDTxWithdrawCollateral";
    States["D2_BORROW_BPRO_NEW_LOANS"] = "d2BorrowBPROxNewLoans";
    States["D2_BORROW_BPRO_BORROW"] = "d2BorrowBPROxBorrow";
    States["D2_BORROW_BPRO_REPAY"] = "d2BorrowBPROxRepay";
    States["D2_BORROW_BPRO_CLOSE"] = "d2BorrowBPROxClose";
    States["D2_BORROW_BPRO_EXTEND"] = "d2BorrowBPROxExtend";
    States["D2_BORROW_BPRO_ADD_COLLATERAL"] = "d2BorrowBPROxAddCollateral";
    States["D2_BORROW_BPRO_WITHDRAW_COLLATERAL"] = "d2BorrowBPROxWithdrawCollateral";
    States["D2_MARKET_MAKING_FULL"] = "marketMakingFull";
    States["D2_MARKET_MAKING_DLLR"] = "marketMakingDLLR";
    States["D2_MARKET_MAKING_SOV"] = "marketMakingSOV";
    States["D2_MARKET_MAKING_FISH"] = "marketMakingFISH";
    States["D2_MARKET_MAKING_MOC"] = "marketMakingMOC";
    States["D2_MARKET_MAKING_RIF"] = "marketMakingRIF";
    States["D2_MARKET_MAKING_MYNT"] = "marketMakingMYNT";
    States["D2_RUNE_BRIDGE_RSK"] = "d2RuneBridgeRSK";
    States["D2_RUNE_BRIDGE_BOB"] = "d2RuneBridgeBOB";
    // BOB chain states
    States["BOB_FULL"] = "fullD2Bob";
    States["BOB_DEPOSIT_LIQUIDITY"] = "d2BobDepositLiquidity";
    States["BOB_WITHDRAW_LIQUIDITY"] = "d2BobWithdrawLiquidity";
    States["BOB_CLAIM_AMM_FEES"] = "d2BobClaimAMMFees";
    States["BOB_CLAIM_LP_DEPOSIT"] = "d2BobClaimLPDeposit";
})(States || (States = {}));
export function useMaintenance() {
    const maintenanceStates = useMaintenanceModeContext();
    const checkMaintenance = useCallback((...names) => {
        if (process.env.REACT_APP_BYPASS_MAINTENANCE === 'true' || isStaging()) {
            return false;
        }
        return names.every(
        // assume we are in maintenance if states aren't retrieved (e.g. maintenance service unavailable)
        name => { var _a, _b; return (_b = (_a = maintenanceStates[name]) === null || _a === void 0 ? void 0 : _a.isInMaintenance) !== null && _b !== void 0 ? _b : true; });
    }, [maintenanceStates]);
    const checkMaintenances = useCallback(() => {
        return Object.keys(maintenanceStates).reduce((res, curr) => Object.assign(res, {
            [curr]: checkMaintenance(curr),
        }), {});
    }, [checkMaintenance, maintenanceStates]);
    return useMemo(() => ({ checkMaintenance, checkMaintenances, States }), [checkMaintenance, checkMaintenances]);
}
