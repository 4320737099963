import { useRef, useEffect } from 'react';
export function useInterval(callback, delay, options) {
    const savedCallback = useRef(callback);
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        if (delay === null) {
            return;
        }
        if (options === null || options === void 0 ? void 0 : options.immediate) {
            savedCallback.current();
        }
        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
    }, [delay, options === null || options === void 0 ? void 0 : options.immediate]);
}
