var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { ErrorBadge, ErrorLevel, NotificationType, OrderDirection, Pagination, Paragraph, ParagraphSize, Table, } from '@sovryn/ui';
import { RSK_CHAIN_ID } from '../../../../../config/chains';
import { AmountRenderer } from '../../../../2_molecules/AmountRenderer/AmountRenderer';
import { AssetRenderer } from '../../../../2_molecules/AssetRenderer/AssetRenderer';
import { ExportCSV } from '../../../../2_molecules/ExportCSV/ExportCSV';
import { TxIdWithNotification } from '../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BTC_RENDER_PRECISION } from '../../../../../constants/currencies';
import { DEFAULT_HISTORY_FRAME_PAGE_SIZE, EXPORT_RECORD_LIMIT, } from '../../../../../constants/general';
import { getTokenDisplayName } from '../../../../../constants/tokens';
import { useNotificationContext } from '../../../../../contexts/NotificationContext';
import { useAccount } from '../../../../../hooks/useAccount';
import { useBlockNumber } from '../../../../../hooks/useBlockNumber';
import { useMaintenance } from '../../../../../hooks/useMaintenance';
import { translations } from '../../../../../locales/i18n';
import { getChainById } from '../../../../../utils/chain';
import { rskClient } from '../../../../../utils/clients';
import { useGetBorrowHistoryLazyQuery, } from '../../../../../utils/graphql/rsk/generated';
import { dateFormat } from '../../../../../utils/helpers';
import { useGetNewLoans } from './hooks/useGetNewLoans';
const pageSize = DEFAULT_HISTORY_FRAME_PAGE_SIZE;
export const NewLoanHistoryFrame = ({ children }) => {
    const { account } = useAccount();
    const { addNotification } = useNotificationContext();
    const [page, setPage] = useState(0);
    const chain = getChainById(RSK_CHAIN_ID);
    const { value: block } = useBlockNumber();
    const [orderOptions, setOrderOptions] = useState({
        orderBy: 'timestamp',
        orderDirection: OrderDirection.Desc,
    });
    const { data, loading, refetch } = useGetNewLoans(account, pageSize, page, orderOptions);
    useEffect(() => {
        refetch();
    }, [refetch, block]);
    const [getBorrowHistory] = useGetBorrowHistoryLazyQuery({
        client: rskClient,
    });
    const generateRowTitle = useCallback((row) => (React.createElement(Paragraph, { size: ParagraphSize.small, className: "text-left" },
        t(translations.borrowHistory.transactionTypes.createLoan),
        ' - ',
        dateFormat(row.timestamp))), []);
    const columns = useMemo(() => [
        {
            id: 'timestamp',
            title: t(translations.common.tables.columnTitles.timestamp),
            cellRenderer: (tx) => dateFormat(tx.timestamp),
            sortable: true,
        },
        {
            id: 'transactionType',
            title: t(translations.common.tables.columnTitles.transactionType),
            cellRenderer: () => t(translations.borrowHistory.transactionTypes.createLoan),
        },
        {
            id: 'collateralAmount',
            title: t(translations.loanHistory.table.collateralAmount),
            cellRenderer: tx => (React.createElement("div", { className: "inline-flex gap-1 items-center" },
                React.createElement(AmountRenderer, { value: tx.newCollateral, precision: BTC_RENDER_PRECISION, dataAttribute: "borrow-new-collateral" }),
                React.createElement(AssetRenderer, { asset: tx.collateralToken }))),
        },
        {
            id: 'debtAmount',
            title: t(translations.loanHistory.table.debtAmount),
            cellRenderer: tx => (React.createElement("div", { className: "inline-flex gap-1 items-center" },
                React.createElement(AmountRenderer, { value: tx.newPrincipal, precision: BTC_RENDER_PRECISION, dataAttribute: "borrow-new-debt" }),
                React.createElement(AssetRenderer, { asset: tx.loanToken }))),
        },
        {
            id: 'interestRate',
            title: 'Interest Rate',
            cellRenderer: tx => (React.createElement(AmountRenderer, { value: tx.interestRate, precision: 2, suffix: "%" })),
        },
        {
            id: 'loanId',
            title: t(translations.loanHistory.table.loanID),
            cellRenderer: tx => (React.createElement(TxIdWithNotification, { href: "", value: tx.loanId, dataAttribute: "loan-id" })),
        },
        {
            id: 'transactionID',
            title: t(translations.common.tables.columnTitles.transactionID),
            cellRenderer: (tx) => (React.createElement(TxIdWithNotification, { href: `${chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl}/tx/${tx.hash}`, value: tx.hash, dataAttribute: "transaction-id" })),
        },
    ], [chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl]);
    const onPageChange = useCallback((value) => {
        if ((data === null || data === void 0 ? void 0 : data.length) < pageSize && value > page) {
            return;
        }
        setPage(value);
    }, [page, data]);
    const isNextButtonDisabled = useMemo(() => !loading && (data === null || data === void 0 ? void 0 : data.length) < pageSize, [loading, data]);
    const exportData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield getBorrowHistory({
            variables: {
                skip: 0,
                pageSize: EXPORT_RECORD_LIMIT,
                user: account === null || account === void 0 ? void 0 : account.toLowerCase(),
                orderBy: orderOptions.orderBy || undefined,
                orderDirection: orderOptions.orderDirection || undefined,
            },
        });
        let list = (data === null || data === void 0 ? void 0 : data.borrows) || [];
        if (!list || !list.length) {
            addNotification({
                type: NotificationType.warning,
                title: t(translations.common.tables.actions.noDataToExport),
                content: '',
                dismissible: true,
                id: nanoid(),
            });
        }
        return list.map(tx => ({
            timestamp: dateFormat(tx.timestamp),
            transactionType: t(translations.borrowHistory.transactionTypes.createLoan),
            collateralAmount: `${tx.newCollateral} ${getTokenDisplayName(tx.loanId.collateralToken.symbol || '')}`,
            debtAmount: `${tx.newPrincipal} ${getTokenDisplayName(tx.loanId.loanToken.symbol || '')}`,
            interestRate: tx.interestRate,
            loanId: tx.loanId.id,
            TXID: tx.transaction.id,
        }));
    }), [
        account,
        addNotification,
        getBorrowHistory,
        orderOptions.orderBy,
        orderOptions.orderDirection,
    ]);
    useEffect(() => {
        setPage(0);
    }, [orderOptions]);
    const { checkMaintenance, States } = useMaintenance();
    const exportLocked = checkMaintenance(States.ZERO_EXPORT_CSV);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-row items-center gap-4 mb-7 flex justify-center lg:justify-start" },
            children,
            React.createElement("div", { className: "flex-row items-center ml-2 gap-4 hidden lg:inline-flex" },
                React.createElement(ExportCSV, { getData: exportData, filename: "New loans", disabled: !data || data.length === 0 || exportLocked }),
                exportLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.featureDisabled) })))),
        React.createElement("div", { className: "bg-gray-80 py-4 px-4 rounded" },
            React.createElement(Table, { setOrderOptions: setOrderOptions, orderOptions: orderOptions, columns: columns, rows: data, rowTitle: generateRowTitle, isLoading: loading, className: "bg-gray-80 text-gray-10 lg:px-6 lg:py-4", noData: t(translations.common.tables.noData), loadingData: t(translations.common.tables.loading), dataAttribute: "new-loans-table" }),
            React.createElement(Pagination, { page: page, className: "lg:pb-6 mt-3 lg:mt-6 justify-center lg:justify-start", onChange: onPageChange, itemsPerPage: pageSize, isNextButtonDisabled: isNextButtonDisabled, dataAttribute: "new-loans-pagination" }))));
};
