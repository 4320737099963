var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useAccount } from '../../../../hooks/useAccount';
import { DEPOSIT_ADDRESS_PATH } from '../constants';
import { useRuneContext } from '../contexts/rune';
import { useRuneBridgeApiClient } from './useRuneBridgeApiClient';
export const useRequestDepositAddress = () => {
    const { account } = useAccount();
    const { set } = useRuneContext();
    const runeBridgeApiClient = useRuneBridgeApiClient();
    return React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = { evm_address: account };
        const requestOps = {
            method: 'POST',
            data,
        };
        return yield runeBridgeApiClient
            .request(DEPOSIT_ADDRESS_PATH, requestOps)
            .then(response => {
            const { deposit_address: depositAddress } = response;
            set(prevState => {
                return Object.assign(Object.assign({}, prevState), { depositAddress });
            });
            return response;
        });
    }), [account, runeBridgeApiClient, set]);
};
