import { useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { getContract } from '@sovryn/contracts';
import { getProvider } from '@sovryn/ethers-provider';
import { RSK_CHAIN_ID } from '../config/chains';
import { useAccount } from './useAccount';
import { useIsMounted } from './useIsMounted';
export const useLoadContract = (contractName, group, chain = RSK_CHAIN_ID, customSigner) => {
    const isMounted = useIsMounted();
    const { signer: userSigner } = useAccount();
    const [address, setAddress] = useState(undefined);
    const [abi, setAbi] = useState(undefined);
    const provider = useMemo(() => getProvider(chain), [chain]);
    useEffect(() => {
        getContract(contractName, group, chain)
            .then(result => {
            if (isMounted()) {
                setAddress(result.address);
                setAbi(result.abi);
            }
        })
            .catch(e => {
            console.warn(`Failed to load contract ${contractName} from group ${group} on chain ${chain}`, e);
        });
    }, [chain, contractName, group, isMounted]);
    return useMemo(() => {
        if (address && abi) {
            const signer = customSigner || userSigner || provider;
            return new ethers.Contract(address, abi, signer);
        }
    }, [address, abi, customSigner, userSigner, provider]);
};
