var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import { t } from 'i18next';
import { Button, ButtonStyle, ErrorBadge, ErrorLevel } from '@sovryn/ui';
import { useAccount } from '../../../../../../hooks/useAccount';
import { translations } from '../../../../../../locales/i18n';
import { ReceiveflowStep } from '../../../contexts/receiveflow';
import { useReceiveFlowContext } from '../../../contexts/receiveflow';
import { useRequestDepositAddress } from '../../../hooks/useRequestDepositAddress';
import { useRuneBridgeLocked } from '../../../hooks/useRuneBridgeLocked';
import { Instructions } from '../../Instructions';
export const MainScreen = () => {
    const { account } = useAccount();
    const requestDepositAddress = useRequestDepositAddress();
    const { errorMessage, set } = useReceiveFlowContext();
    const runeBridgeLocked = useRuneBridgeLocked();
    const onContinueClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield requestDepositAddress()
            .then(() => {
            set(prevState => (Object.assign(Object.assign({}, prevState), { step: ReceiveflowStep.ADDRESS, errorMessage: null })));
        })
            .catch(e => {
            set(prevState => (Object.assign(Object.assign({}, prevState), { errorMessage: String(e) })));
        });
    }), [requestDepositAddress, set]);
    return (React.createElement("div", null,
        React.createElement(Instructions, { isReceive: true }),
        runeBridgeLocked ? (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.runeBridge) })) : (React.createElement(Button, { disabled: !account, onClick: onContinueClick, text: t(translations.common.buttons.continue), className: "w-full", style: ButtonStyle.secondary, dataAttribute: "funding-send-instructions-confirm" })),
        errorMessage && (React.createElement(ErrorBadge, { level: ErrorLevel.Critical, message: errorMessage }))));
};
