var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Chains } from '../../../../config/chains';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';
import { defaultValue, } from '../contexts/withdraw-context';
export function useWithdrawBridgeConfig(network = Chains.RSK) {
    const [state, setState] = useState(defaultValue);
    const fastBtcBridgeContract = useGetProtocolContract('fastBtcBridge');
    const getFastBtcBridgeParams = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        let minTransferSatoshi = 0;
        let maxTransferSatoshi = 0;
        let feeStructures = {
            baseFeeSatoshi: 0,
            dynamicFee: 0,
        };
        if (fastBtcBridgeContract) {
            minTransferSatoshi = yield fastBtcBridgeContract.minTransferSatoshi();
            maxTransferSatoshi = yield fastBtcBridgeContract.maxTransferSatoshi();
            const currentFeeStructureIndex = yield fastBtcBridgeContract.currentFeeStructureIndex();
            feeStructures = yield fastBtcBridgeContract.feeStructures(currentFeeStructureIndex);
        }
        return {
            minTransferSatoshi: minTransferSatoshi,
            maxTransferSatoshi: maxTransferSatoshi,
            feeStructures: feeStructures,
        };
    }), [fastBtcBridgeContract]);
    useEffect(() => {
        getFastBtcBridgeParams()
            .then(data => {
            setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: {
                    min: data.minTransferSatoshi,
                    max: data.maxTransferSatoshi,
                    baseFee: data.feeStructures.baseFeeSatoshi,
                    dynamicFee: data.feeStructures.dynamicFee,
                    loading: false,
                } })));
        })
            .catch(error => {
            console.error(error);
            setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: Object.assign(Object.assign({}, prevState.limits), { loading: false }) })));
        });
    }, [getFastBtcBridgeParams]);
    return useMemo(() => (Object.assign(Object.assign({}, state), { set: setState })), [state, setState]);
}
