export var Environments;
(function (Environments) {
    Environments["Testnet"] = "testnet";
    Environments["Mainnet"] = "mainnet";
})(Environments || (Environments = {}));
export var Services;
(function (Services) {
    Services["Maintenance"] = "maintenance";
    Services["Notification"] = "notification";
    Services["Amm"] = "amm";
})(Services || (Services = {}));
