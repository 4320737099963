var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useContext } from 'react';
export var ReceiveflowStep;
(function (ReceiveflowStep) {
    ReceiveflowStep[ReceiveflowStep["MAIN"] = 0] = "MAIN";
    ReceiveflowStep[ReceiveflowStep["ADDRESS"] = 1] = "ADDRESS";
    ReceiveflowStep[ReceiveflowStep["PROCESSING"] = 2] = "PROCESSING";
    ReceiveflowStep[ReceiveflowStep["COMPLETED"] = 3] = "COMPLETED";
})(ReceiveflowStep || (ReceiveflowStep = {}));
export const defaultValue = {
    step: ReceiveflowStep.MAIN,
    ready: false,
    address: '',
    addressLoading: false,
    addressError: null,
    txCheckingAttempts: 0,
    depositTx: {
        lastBlockHash: '',
        statuses: [],
        currentTX: {
            btcDepositTxid: '',
            btcDepositVout: '',
            runeName: '',
            runeSymbol: '',
            amountDecimal: '',
            feeDecimal: '',
            receiveAmountDecimal: '',
            status: '',
            evmTransferTxHash: '',
        },
    },
    limits: {
        min: 0,
        max: 0,
        baseFee: 0,
        dynamicFee: 0,
        loading: true,
    },
    signatures: [],
    errorMessage: null,
    set: () => {
        throw new Error('set() has not been defined.');
    },
    requestLastScannedBlock: () => __awaiter(void 0, void 0, void 0, function* () {
        throw new Error('requestLastScannedBlock() has not been defined.');
    }),
    getRuneDepositStatus: () => __awaiter(void 0, void 0, void 0, function* () {
        throw new Error('getRuneDepositStatus() has not been defined.');
    }),
};
export const ReceiveFlowContext = createContext(defaultValue);
export const useReceiveFlowContext = () => {
    return useContext(ReceiveFlowContext);
};
