// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.94" clip-path="url(#clip0_11380_193)">
<path d="M19.8 9.99998C19.8 12.7091 18.7 15.1545 16.9272 16.9273C16.6818 17.1727 16.4272 17.4 16.1545 17.6182C15.8636 17.8636 15.5545 18.0818 15.2272 18.2909C15.1363 18.3454 15.0454 18.4 14.9545 18.4545C14.9454 18.4545 14.9272 18.4727 14.9181 18.4727C14.6181 18.6545 14.3 18.8091 13.9818 18.9545C13.6636 19.1 13.3272 19.2273 12.9909 19.3273C12.9909 19.3273 12.9454 19.3545 12.9272 19.3545C12.8 19.4 12.6727 19.4363 12.5454 19.4636C12.0363 19.6091 11.509 19.7 10.9636 19.7454C10.9545 19.7545 10.9363 19.7545 10.9181 19.7545C10.8909 19.7636 10.8545 19.7636 10.8272 19.7636C10.709 19.7636 10.5909 19.7818 10.4727 19.7909C10.3181 19.8 10.1636 19.8 10.009 19.8C9.38177 19.8 8.77268 19.7454 8.19086 19.6273C8.16359 19.6273 8.13631 19.6182 8.11813 19.6182C7.98177 19.5909 7.8545 19.5636 7.72722 19.5273C7.17268 19.4 6.63631 19.2182 6.11813 19C4.98177 18.5 3.94541 17.7909 3.08177 16.9273C1.29995 15.1545 0.199951 12.7091 0.199951 9.99998C0.199951 7.29089 1.29995 4.83634 3.07268 3.07271C4.84541 1.29089 7.29086 0.199982 9.99995 0.199982C15.409 0.199982 19.8 4.5818 19.8 9.99998Z" fill="#262B34"/>
<path d="M19.8 9.99998C19.8 12.7091 18.7 15.1545 16.9272 16.9273C16.6818 17.1727 16.4272 17.4 16.1545 17.6182C15.8636 17.8636 15.5545 18.0818 15.2272 18.2909C15.1363 18.3454 15.0454 18.4 14.9545 18.4545C14.9454 18.4545 14.9272 18.4727 14.9181 18.4727C14.6181 18.6545 14.3 18.8091 13.9818 18.9545C13.6636 19.1 13.3272 19.2273 12.9909 19.3273C12.9909 19.3273 12.9454 19.3545 12.9272 19.3545C12.8 19.4 12.6727 19.4363 12.5454 19.4636C12.0363 19.6091 11.509 19.7 10.9636 19.7454C10.9545 19.7545 10.9363 19.7545 10.9181 19.7545C10.8909 19.7636 10.8545 19.7636 10.8272 19.7636C10.709 19.7636 10.5909 19.7818 10.4727 19.7909C10.3181 19.8 10.1636 19.8 10.009 19.8C9.38177 19.8 8.77268 19.7454 8.19086 19.6273C8.16359 19.6273 8.13631 19.6182 8.11813 19.6182C7.98177 19.5909 7.8545 19.5636 7.72722 19.5273C7.17268 19.4 6.63631 19.2182 6.11813 19C4.98177 18.5 3.94541 17.7909 3.08177 16.9273C1.29995 15.1545 0.199951 12.7091 0.199951 9.99998C0.199951 7.29089 1.29995 4.83634 3.07268 3.07271C4.84541 1.29089 7.29086 0.199982 9.99995 0.199982C15.409 0.199982 19.8 4.5818 19.8 9.99998Z" stroke="#FF591F" stroke-width="0.255269"/>
<path d="M8.18182 19.6272C8.76364 19.7454 9.37273 19.8 10 19.8C10.1545 19.8 10.3091 19.8 10.4636 19.7909L10.8182 19.7636C10.8182 19.7636 10.8818 19.7636 10.9091 19.7545C10.9273 19.7545 10.9455 19.7545 10.9545 19.7454C11.5 19.7 12.0273 19.6091 12.5364 19.4636C12.6636 19.4363 12.7909 19.4 12.9182 19.3545C12.9364 19.3545 12.9545 19.3454 12.9818 19.3363H12.9909C13.3818 19.2454 13.7182 19.1091 13.9818 18.9545C14.3 18.8091 14.6182 18.6545 14.9182 18.4727C14.9273 18.4727 14.9455 18.4636 14.9545 18.4545L15.2273 18.2909C15.5545 18.0818 15.8636 17.8636 16.1545 17.6182L15.5091 16.3454C15.2818 15.9 15.0727 15.4636 14.6273 15.2545L13.3727 14.6545H13.3636L13.3273 14.6363H13.3182L13.2364 14.5909H12.7545L12.7455 14.6C12.7455 14.6091 12.7364 14.6 12.7364 14.6L10.2727 14.6636H10.2636L9.45455 14.6818H9.43637L8.62728 14.7H8.6L8.59091 14.7091L7.32728 14.8727L6.71819 14.7454L6.50909 14.7V16.3L5.86364 16.3818L4.93637 16.5091L4.86364 16.2L4.83637 16.1V16.0454L4.34546 16.0909L4.4 16.1818L4.42728 16.2363L5.53637 18.0545L6.10909 19C6.62728 19.2182 7.16364 19.4 7.71819 19.5272C7.84546 19.5636 7.97273 19.5909 8.10909 19.6182H8.18182V19.6272Z" fill="#FF591F"/>
<path d="M4.3363 16.0909L5.5363 18.0546C5.4363 17.0818 6.21812 14.9273 7.32721 14.8727L6.36358 14.6091L4.3363 16.0909Z" fill="#EA3921"/>
<path d="M8.18177 19.6273C8.76359 19.7455 9.37268 19.8 9.99995 19.8C10.1545 19.8 10.309 19.8 10.4636 19.7909L10.8181 19.7637C10.8181 19.7637 10.8818 19.7637 10.909 19.7546C10.9272 19.7546 10.9454 19.7546 10.9545 19.7455C11.5 19.7 12.0272 19.6091 12.5363 19.4637C12.6636 19.4364 12.7909 19.4 12.9181 19.3546C12.9363 19.3546 12.9545 19.3455 12.9818 19.3364C12.9636 19.3364 12.9454 19.3455 12.9181 19.3455C12.8 19.3727 12.6727 19.4 12.5363 19.4091C12.3636 19.4364 12.1727 19.4455 11.9727 19.4455C11.6 19.4455 11.2454 19.4182 10.9181 19.3546C10.909 19.3637 10.9 19.3637 10.9 19.3546C10.8636 19.3546 10.8363 19.3455 10.809 19.3364C10.6909 19.3182 10.5727 19.3 10.4636 19.2637C9.13632 18.9455 8.37268 18.2 8.04541 17.3364C8.04541 17.3364 8.02723 17.3091 8.02723 17.3C7.9545 17.6273 7.92723 18.8273 8.10904 19.6182H8.18177V19.6273Z" fill="#EA3921"/>
<path d="M14.9545 18.1V18.4545C14.9545 18.4545 14.9273 18.4727 14.9182 18.4727C14.6182 18.6545 14.3 18.8091 13.9818 18.9545C14.4909 18.6636 14.7818 18.3272 14.9 18.1818C14.9364 18.1272 14.9545 18.1 14.9545 18.1Z" fill="#EA3921"/>
<path d="M14.4454 15.3273L12.9818 19.0364V19.3364C12.9818 19.3364 12.9363 19.3546 12.9181 19.3546C12.7909 19.4 12.6636 19.4364 12.5363 19.4637C12.0272 19.6091 11.5 19.7 10.9545 19.7455C10.9454 19.6546 10.9363 19.5727 10.9363 19.5364C10.9363 19.4727 10.9272 19.4091 10.9181 19.3546C10.9091 19.2455 10.8909 19.1546 10.8909 19.1546L7.69087 15.1727L7.5636 15.0182L7.89087 14.9637L8.49996 14.8L11.9272 18.5091L11.9818 18.5637L13.4454 14.8455L14.4091 15.3091L14.4454 15.3273Z" fill="#E0FCFD"/>
<path d="M12.5182 18.9728L12.5364 19.4091V19.4637C12.0273 19.6091 11.5 19.7 10.9545 19.7455C10.9454 19.6546 10.9364 19.5728 10.9364 19.5364C10.9364 19.4728 10.9273 19.4091 10.9182 19.3546C10.9091 19.2455 10.8909 19.1546 10.8909 19.1546L7.93635 15.5L7.65453 15.2L7.63635 15.1819H7.6909L8.39999 15.1L11.8636 18.9273L13.4454 14.8455L14.0545 15.3546L12.5182 18.9728Z" fill="#C4C3C2"/>
<path d="M4.64549 5.81821L3.59094 3.25458H7.28185L4.64549 5.81821Z" fill="#FF591F"/>
<path d="M15.7 5.66367L16.6182 3.42731H13.4182L15.7 5.66367Z" fill="#FF591F"/>
<path d="M17.1819 9.22724C17.1728 9.38179 17.1728 9.52724 17.1546 9.67269V9.73633C17.0728 10.2181 16.9455 10.6727 16.7728 11.1091C16.0455 12.9091 14.4819 14.3363 12.5273 14.9363C11.9637 15.1181 11.3546 15.2272 10.7273 15.2545C10.2273 14.6272 9.61823 13.7909 9.06368 12.8454C8.97277 12.6909 8.88186 12.5363 8.80004 12.3727C8.68186 12.1727 8.58186 11.9818 8.47277 11.7727C8.18186 11.2091 7.91823 10.6363 7.69095 10.0272C7.66368 9.93633 7.62732 9.84542 7.60004 9.74542C7.36368 9.09088 7.18186 8.41815 7.07277 7.74542C7.02732 7.52724 7.00914 7.30906 6.99095 7.09088C6.96368 6.85451 6.95459 6.62724 6.95459 6.39997C6.95459 5.01815 7.3455 3.68179 8.33641 2.53633C8.97277 2.34542 9.65459 2.24542 10.3546 2.24542H10.3728C11.8091 2.24542 13.1455 2.6636 14.2455 3.3727H14.2546C14.2637 3.3727 14.2728 3.3727 14.2819 3.39997H14.291C14.9455 3.82724 15.5273 4.37269 15.9819 4.99088C16.5091 5.69088 16.9 6.49088 17.1 7.3636C17.1 7.38179 17.1091 7.39088 17.1091 7.40906C17.1455 7.62724 17.1728 7.85451 17.191 8.07269C17.2182 8.45451 17.2273 8.83633 17.2091 9.21815L17.1819 9.22724Z" fill="#FF591F"/>
<path d="M16.7727 8.57272C17.0363 13.0818 13.7909 15.0545 11.0182 15.1818C10.6636 15.2091 10.2454 15.1364 9.95453 15.0909C6.65453 14.5091 3.6818 11.6 3.45453 8.72726C3.22726 5.85453 5.49998 3.36363 8.30907 2.55453C7.31816 3.69999 6.92725 5.01817 6.92725 6.3909C6.92725 6.61817 6.93635 6.84544 6.95453 7.07272C6.97271 7.2909 6.99998 7.50908 7.03635 7.72726C7.14544 8.39999 7.32725 9.06363 7.56362 9.70908C7.59089 9.80908 7.62725 9.89999 7.65453 9.98181C7.8818 10.5818 8.14544 11.1545 8.44544 11.7091C8.55453 11.9182 8.65453 12.1091 8.77271 12.3091C8.85453 12.4636 8.94544 12.6273 9.03635 12.7727H9.04544C9.33635 12.9364 15.4182 8.76363 16.7545 8.57272H16.7727Z" fill="#EA3921"/>
<path d="M17.2454 9.17273C17.2363 9.32727 17.2181 9.47273 17.209 9.61818V9.68182C17.1363 10.1636 17.009 10.6091 16.8363 11.0455C16.109 12.8273 15 14.1636 13.0454 14.7636C15.0181 13.9636 15.809 12.7909 16.3818 11.0091C16.8363 9.60909 16.6 8.34545 16.6727 7.87273V8.19091C16.6818 8.04545 16.6818 7.94545 16.6727 7.87273C16.6545 7.58182 16.5 7.8 16.409 7.20909C16.3909 7.20909 16.3909 7.17273 16.3909 7.16364C16.2545 6.56364 16.0636 5.99091 15.8272 5.47273C15.4545 4.67273 14.9636 3.97273 14.3272 3.41818H14.3363C14.9909 3.84546 15.5727 4.38182 16.0272 4.99091C16.5545 5.68182 16.9454 6.47273 17.1454 7.34545V7.39091C17.1818 7.60909 17.2181 7.83636 17.2363 8.04545C17.2636 8.42727 17.2727 8.8 17.2545 9.18182L17.2454 9.17273Z" fill="#FF7B61"/>
<path d="M14.9091 12.4636C14.9091 12.4636 14.8909 12.4727 14.8909 12.4818C13.5364 14.3182 12.3273 15.1 11.0727 15.1C9.89999 15.1 7.97272 14.0727 6.63635 12.7091C9.66362 11.2727 11.7909 11.2 14.9182 12.4636H14.9091Z" fill="#E0FCFD"/>
<path d="M14.9091 12.4636C11.7818 11.2 9.65451 11.2727 6.62724 12.7091C6.54542 12.6182 6.45451 12.5273 6.37269 12.4364C4.81815 10.6818 3.84542 8.02726 5.29088 6.8909C6.74542 5.76363 9.23633 7.27272 10.7181 9.32726C11.5636 8.05454 14.0363 5.54545 15.6909 6.51817C17.5091 7.60908 15.9272 11.0545 14.9091 12.4545V12.4636Z" fill="#00CDFE"/>
<path d="M9.36365 13.4818C9.47274 13.6727 10.3727 13.7909 11 13.7636C11.4091 13.7455 12.3818 13.6818 12.5455 13.4182C12.1455 13.4455 9.75456 13.4909 9.36365 13.4909V13.4818Z" fill="#010101"/>
<path d="M4.99089 10.2636C5.00908 8.21818 6.62726 6.7909 8.8818 7.46363C7.91817 6.88181 6.39089 5.97272 5.29089 6.8909C4.19089 7.80908 4.99089 10.2636 4.99089 10.2636Z" fill="#75DCFC"/>
<path d="M12.5908 7.3182C13.1454 7.02729 16.209 7.3182 16.2363 9.8182C17.009 7.78183 16.0726 6.6091 15.509 6.43638C14.6545 6.17274 13.2817 6.70911 12.5908 7.32729V7.3182Z" fill="#75DCFC"/>
<path d="M8.24547 7.69095C8.49092 7.69095 8.70001 7.88186 8.70001 8.11822C8.70001 8.31822 8.52728 8.48186 8.31819 8.48186C8.07274 8.48186 7.86365 8.27277 7.86365 8.0364C7.86365 7.8364 8.02728 7.69095 8.25456 7.69095H8.24547Z" fill="#FBFCFC"/>
<path d="M13.1727 8.26364C12.9272 8.26364 12.7818 8.05455 12.8636 7.82728C12.9363 7.60001 13.1909 7.44546 13.4272 7.49092C13.4727 7.49092 13.509 7.51819 13.5454 7.53637C13.5818 7.55455 13.609 7.59092 13.6363 7.62728C13.6636 7.66364 13.6727 7.70001 13.6818 7.74546C13.6818 7.79092 13.6818 7.82728 13.6818 7.87273C13.6363 8.09092 13.4181 8.26364 13.1818 8.26364H13.1727Z" fill="#FBFCFC"/>
<path d="M14.9364 12.5727C13.491 12.0545 12.2364 11.6454 10.7364 11.6909C9.11825 11.6818 7.90916 12.4 6.53644 12.7909C4.95462 11.4091 3.11825 8.47272 5.01825 6.62726C7.20916 5.14545 9.72734 7.46363 10.8092 9.27272H10.6183C14.6455 2.80908 19.6183 6.93635 14.9273 12.5818L14.9364 12.5727ZM14.8728 12.3545C15.4001 11.4818 15.9092 10.5727 16.1819 9.58181C16.7092 8.13635 16.3364 6.05454 14.3819 6.48181C12.8819 6.91817 11.7183 8.17272 10.8183 9.39999C10.7819 9.45454 10.7092 9.46363 10.6546 9.42726C9.69098 8.17272 8.40916 6.92726 6.79098 6.65454C4.82734 6.36363 4.30916 8.43635 4.9728 9.92726C5.34553 10.9364 5.94553 11.8545 6.70916 12.6364L6.5728 12.6091C9.10007 11.2545 12.291 11.0909 14.8728 12.3545Z" fill="#010101"/>
<path d="M8.01823 13.8727C7.70005 13.5 7.49096 13.0545 7.77277 12.7182C8.20005 12.2 9.76368 11.7818 10.9546 11.7909C13.6728 11.8091 14.391 12.7182 14.1728 13.3727C14.891 12.4818 14.8728 12.5 14.891 12.4818C12.7 11.6818 11.5364 11.4818 10.6182 11.5818C9.70005 11.6818 6.88186 12.3363 6.62732 12.7091C6.99096 13.2 7.78186 13.7182 8.01823 13.8727Z" fill="#C4C3C2"/>
<path d="M15.0182 12.4364C14.9909 12.6 14.9818 12.5364 14.9545 12.5637C14.3364 13.4728 12.6 15.3728 10.9 15.1546C9.21818 14.9455 7.75454 13.9273 6.55454 12.791C6.50909 12.7455 6.5 12.6728 6.55454 12.6273C9.14545 11.2273 12.3909 11.0455 15.0273 12.4364H15.0182ZM14.7909 12.4819C13.4636 12.1 12.1364 11.6091 10.7364 11.6819C9.31818 11.7182 7.95454 12.2 6.67273 12.8091L6.70909 12.6273C9.55454 15.5182 12.3818 16.2 14.8 12.4091L14.7818 12.4728C14.7818 12.4182 14.8455 12.3546 14.8455 12.3637C14.8545 12.3819 14.8273 12.3182 14.8091 12.4728L14.7909 12.4819Z" fill="#010101"/>
<path d="M8.44548 19.6272C8.21821 18.9091 8.15457 18.1272 8.14548 17.3363C8.13639 17.2272 8.13639 17.1363 8.13639 17.0363L8.03639 17.0182C7.93639 17.8363 7.86366 18.6909 7.98184 19.5272C8.10912 19.5636 8.23639 19.5909 8.37275 19.6182H8.44548V19.6272Z" fill="#010101"/>
<path d="M15.2273 18.2909C15.1363 18.3455 15.0454 18.4 14.9545 18.4545C14.9454 18.4545 14.9273 18.4727 14.9182 18.4727C14.9182 18.3727 14.9182 18.2818 14.9 18.1818C14.8454 17.5909 14.7545 17.0091 14.6182 16.4455L14.6818 16.4182C14.9182 17.0273 15.0909 17.6545 15.2273 18.2909Z" fill="#010101"/>
<path d="M13.3727 14.6545L11.6545 18.1727L11.9 18.4091L13.3727 14.6545Z" fill="#EA3921"/>
<path d="M10.4636 19.1727L7.27271 14.8818L10.8091 19.1818V19.7636C10.7 19.7636 10.5818 19.7818 10.4636 19.7909V19.1727Z" fill="#EA3921"/>
<path d="M14.4273 15.2636L14.4091 15.3091L12.9182 19.0364V19.3546C12.7909 19.4 12.6636 19.4364 12.5363 19.4636C12.0273 19.6091 11.5 19.7 10.9545 19.7455C10.9454 19.7546 10.9273 19.7546 10.9091 19.7546V19.3546L10.8909 19.1546L7.65453 15.2L7.63634 15.1818L7.44543 14.9455L8.49998 14.8L11.9091 18.5636L11.9273 18.5091L13.4273 14.7727L14.4273 15.2636Z" stroke="#010101" stroke-width="0.11023" stroke-miterlimit="10"/>
<path d="M6.88179 13.9909L7.2727 16.2L5.44543 16.7454L5.23633 16.2272L6.64543 15.8909C6.64543 15.8909 4.31815 16.4636 3.26361 16.6818C2.80906 16.1181 2.45452 15.1454 2.53634 14.0818L6.0727 13.0363L6.33633 13.809L6.8727 14L6.88179 13.9909Z" fill="#FBFCFC"/>
<path d="M2.66363 15.1363L3.50909 14.8727C3.50909 15.2636 3.75454 16.2545 3.90909 16.4C3.23636 16.6727 3.26363 16.6636 3.26363 16.6636C2.9909 16.2545 2.67272 15.4636 2.65454 15.1273L2.66363 15.1363Z" fill="#C4C3C2"/>
<path d="M3.76367 14.8091L4.85458 14.5455C4.85458 14.8818 5.17276 15.8909 5.30004 16.0909C4.62731 16.3637 4.25458 16.4273 4.25458 16.4273C3.98185 16.0182 3.77276 15.0909 3.76367 14.8091Z" fill="#C4C3C2"/>
<path d="M5.1181 14.5182L6.2181 14.3818C6.2181 14.7727 6.33628 15.9091 6.33628 15.9091C5.66355 16.1818 5.60901 16.0546 5.60901 16.0546C5.33628 15.6455 5.12719 14.8546 5.10901 14.5182H5.1181Z" fill="#C4C3C2"/>
<path d="M4.11818 16.5C3.86364 15.9182 3.72727 15.3091 3.67273 14.7H3.74546C3.88182 15.2727 4.1 15.8454 4.40909 16.3454L4.11818 16.5Z" fill="#010101"/>
<path d="M5.50001 16.1454C5.24547 15.5636 5.1091 14.9636 5.03638 14.3545L5.13638 14.3363C5.27274 14.9182 5.49092 15.4909 5.78183 15.9818L5.49092 16.1363L5.50001 16.1454Z" fill="#010101"/>
<path d="M6.48181 13.6727C6.57272 13.6909 7.00908 13.7818 7.09999 13.8L7.11817 13.9546L7.39999 15.9818C7.41817 16.4636 6.7909 16.4818 6.45453 16.5909L5.47272 16.8455L5.38181 16.8727C5.37272 16.8273 5.16363 16.3182 5.14544 16.2636L5.09999 16.1546L5.21817 16.1273L6.62726 15.7909L6.54544 15.9091L6.38181 14.2273L6.49999 14.2091L6.76363 15.8818L6.78181 15.9727L6.6909 15.9909L3.30908 16.7818L3.24544 16.8L3.19999 16.7455C2.63635 16.0273 2.38181 15.1182 2.40908 14.2182C2.42726 14.1182 2.48181 14.0182 2.55454 13.9546C2.65454 13.8546 5.7909 12.9182 6.02726 12.8364C6.02726 12.8364 6.21817 12.7727 6.22726 12.7727C6.25453 12.8818 6.45453 13.5455 6.4909 13.6727H6.48181ZM6.27272 13.8727L5.98181 13.0546C5.98181 13.0546 6.12726 13.1273 6.09999 13.1182C5.93635 13.1727 2.71817 14.1 2.69999 14.1364C2.4909 14.9091 2.87272 15.9455 3.35454 16.6C3.35454 16.6 3.24544 16.5636 3.25454 16.5636L6.62726 15.7727L6.54544 15.8909L6.40908 14.2C6.40908 14.2 6.46363 14.1909 6.46363 14.2L6.75453 15.8636C6.77272 15.9727 6.7909 15.9546 6.67272 15.9818L5.26363 16.3182L5.33635 16.1818L5.54544 16.7C5.54544 16.7 5.40908 16.6364 5.41817 16.6364L6.3909 16.3455C6.70908 16.2273 7.14544 16.2182 7.12726 15.9818L6.78181 14L6.84544 14.0818L6.27272 13.8636V13.8727Z" fill="#010101"/>
</g>
<defs>
<clipPath id="clip0_11380_193">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
