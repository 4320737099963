var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from 'react';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { NotificationType } from '@sovryn/ui';
import { EXPORT_RECORD_LIMIT } from '../../../../../../constants/general';
import { getTokenDisplayName } from '../../../../../../constants/tokens';
import { useNotificationContext } from '../../../../../../contexts/NotificationContext';
import { translations } from '../../../../../../locales/i18n';
import { rskClient } from '../../../../../../utils/clients';
import { useGetRolloversLazyQuery, useGetRolloversQuery, } from '../../../../../../utils/graphql/rsk/generated';
import { dateFormat } from '../../../../../../utils/helpers';
import { useGetLoanIds } from '../../../hooks/useGetLoanIds';
export const useGetRolloverLoans = (pageSize, page, orderOptions) => {
    const { loanIds, loading: loadingLoanIds, refetch: refetchLoanIds, } = useGetLoanIds();
    const { addNotification } = useNotificationContext();
    const [getRollovers] = useGetRolloversLazyQuery({
        client: rskClient,
    });
    const config = useMemo(() => ({
        loanIds,
        skip: page * pageSize,
        pageSize,
        orderBy: orderOptions.orderBy || undefined,
        orderDirection: orderOptions.orderDirection || undefined,
    }), [
        loanIds,
        page,
        pageSize,
        orderOptions.orderBy,
        orderOptions.orderDirection,
    ]);
    const { loading, data, refetch: refetchRollovers, } = useGetRolloversQuery({
        variables: config,
        client: rskClient,
    });
    const list = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.rollovers.map(tx => ({
            id: tx.transaction.id,
            loanId: tx.loanId.id,
            principal: tx.principal,
            collateral: tx.collateral,
            timestamp: tx.timestamp,
            hash: tx.transaction.id,
            collateralToken: tx.loanId.collateralToken.symbol,
            loanToken: tx.loanId.loanToken.symbol,
        }));
    }, [data]);
    const refetch = useCallback(() => {
        refetchRollovers();
        refetchLoanIds();
    }, [refetchLoanIds, refetchRollovers]);
    const exportData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield getRollovers({
            variables: Object.assign(Object.assign({}, config), { skip: 0, pageSize: EXPORT_RECORD_LIMIT }),
        });
        let list = (data === null || data === void 0 ? void 0 : data.rollovers) || [];
        if (!list || !list.length) {
            addNotification({
                type: NotificationType.warning,
                title: t(translations.common.tables.actions.noDataToExport),
                content: '',
                dismissible: true,
                id: nanoid(),
            });
        }
        return list.map(tx => ({
            timestamp: dateFormat(tx.timestamp),
            transactionType: t(translations.borrowHistory.transactionTypes.rollovers),
            rolloverFee: `${tx.principal} ${getTokenDisplayName(tx.loanId.collateralToken.symbol || '')}`,
            newCollateralBalance: `${tx.collateral} ${getTokenDisplayName(tx.loanId.collateralToken.symbol || '')}`,
            loanId: tx.loanId.id,
            TXID: tx.transaction.id,
        }));
    }), [addNotification, config, getRollovers]);
    return {
        loading: loading || loadingLoanIds,
        data: list,
        refetch,
        exportData,
    };
};
