import React, { useLayoutEffect } from 'react';
export const LoaderProvider = ({ children }) => {
    useLayoutEffect(() => {
        const elem = document.getElementById('bootstrap');
        if (elem) {
            elem.setAttribute('style', 'opacity: 0');
            const timeout = setTimeout(() => {
                elem.remove();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);
    return React.createElement(React.Fragment, null, children);
};
