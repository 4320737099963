// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2929)">
<g clip-path="url(#clip1_9856_2929)">
<path d="M10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5224 20 10 20C4.4776 20 0 15.5238 0 10C0 4.4762 4.4768 0 10 0Z" fill="#53AE94"/>
<path d="M11.2342 8.66759V7.17999H14.636V4.91339H5.37283V7.17999H8.77503V8.66639C6.01003 8.79339 3.93103 9.34099 3.93103 9.99699C3.93103 10.653 6.01103 11.2006 8.77503 11.3284V16.0934H11.235V11.328C13.995 11.2006 16.0698 10.6534 16.0698 9.99799C16.0698 9.34259 13.995 8.79539 11.235 8.66799M11.235 10.9244V10.9232C11.1656 10.9276 10.809 10.949 10.015 10.949C9.38023 10.949 8.93363 10.931 8.77623 10.9228V10.9248C6.33343 10.8166 4.51003 10.3912 4.51003 9.88219C4.51003 9.37319 6.33363 8.94839 8.77623 8.83999V10.501C8.93623 10.512 9.39383 10.539 10.0254 10.539C10.784 10.539 11.1654 10.5074 11.2354 10.501V8.83999C13.6734 8.94859 15.4926 9.37439 15.4926 9.88159C15.4926 10.3888 13.6726 10.8148 11.2354 10.9234" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_9856_2929">
<rect width="20" height="20" fill="white"/>
</clipPath>
<clipPath id="clip1_9856_2929">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
