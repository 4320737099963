// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11462_156)">
<path d="M10.0001 19.4737C15.2322 19.4737 19.4737 15.35 19.4737 10.2632C19.4737 5.17632 15.2322 1.05263 10.0001 1.05263C4.76788 1.05263 0.526367 5.17632 0.526367 10.2632C0.526367 15.35 4.76788 19.4737 10.0001 19.4737Z" fill="white"/>
<path d="M6.43732 10.2221C6.1798 10.0102 5.8618 9.88529 5.5289 9.86526C5.82216 9.83504 6.09662 9.70671 6.30785 9.50106C6.4038 9.39541 6.47789 9.27181 6.52585 9.1374C6.57382 9.00299 6.59471 8.86042 6.58732 8.71789C6.59552 8.51935 6.55194 8.32209 6.46087 8.14548C6.36979 7.96887 6.23436 7.81898 6.06785 7.71052C5.77205 7.52341 5.43517 7.41105 5.08627 7.38315V6.76999H4.33837V7.35948H3.92312V6.76999H3.17364V7.35948H2.57996V12.5074H3.17417V13.0889H3.92364V12.5074H4.3389V13.0889H5.08785V12.4926C5.49405 12.4734 5.88705 12.3424 6.22363 12.1142C6.39422 11.994 6.53226 11.8333 6.62533 11.6465C6.71839 11.4597 6.76356 11.2528 6.75679 11.0442C6.76617 10.8922 6.74247 10.74 6.68732 10.5981C6.63216 10.4561 6.54687 10.3279 6.43732 10.2221ZM3.93311 8.38H4.63784C4.79596 8.37075 4.95186 8.42065 5.07522 8.52C5.12511 8.56899 5.16474 8.62743 5.19179 8.69191C5.21884 8.75638 5.23277 8.8256 5.23277 8.89552C5.23277 8.96544 5.21884 9.03466 5.19179 9.09914C5.16474 9.16362 5.12511 9.22206 5.07522 9.27105C4.95179 9.37026 4.79594 9.42014 4.63784 9.41104H3.93206L3.93311 8.38ZM5.20153 11.3358C5.07008 11.436 4.90704 11.4857 4.74206 11.4758H3.93258V10.4174H4.74311C4.9081 10.4075 5.07116 10.4571 5.20259 10.5574C5.25494 10.6079 5.29658 10.6684 5.32502 10.7354C5.35346 10.8023 5.36811 10.8744 5.36811 10.9471C5.36811 11.0199 5.35346 11.0919 5.32502 11.1588C5.29658 11.2258 5.25494 11.2863 5.20259 11.3368L5.20153 11.3358Z" fill="#FF9415"/>
<path d="M10.1747 7.57684C9.85576 7.42511 9.50577 7.34996 9.15263 7.35737H6.98999V12.5053H8.34316V10.9168H9.1521C9.50536 10.9244 9.85547 10.8488 10.1742 10.6963C10.4574 10.5594 10.695 10.3436 10.8584 10.0747C11.0176 9.78767 11.1011 9.46482 11.1011 9.13658C11.1011 8.80834 11.0176 8.48549 10.8584 8.19842C10.6952 7.92968 10.4578 7.71383 10.1747 7.57684V7.57684ZM9.53842 9.63369C9.39859 9.7589 9.21477 9.82344 9.02736 9.81315H8.34316V8.46264H9.02736C9.12019 8.45761 9.21311 8.47091 9.3008 8.5018C9.38848 8.53268 9.46922 8.58054 9.53842 8.64263C9.65938 8.77997 9.72612 8.95672 9.72612 9.13974C9.72612 9.32276 9.65938 9.4995 9.53842 9.63684V9.63369Z" fill="#FF9415"/>
<path d="M13.8357 8.71211H13.652C13.428 8.70304 13.2054 8.75122 13.0052 8.8521C12.8302 8.95285 12.6849 9.09813 12.5842 9.27317L12.4789 8.71421H11.3131V12.5089H12.5852V10.6853C12.5852 10.1214 12.8426 9.83948 13.3573 9.83948H13.8352L13.8357 8.71211Z" fill="#FF9415"/>
<path d="M16.9806 8.9063C16.6381 8.74839 16.2655 8.66663 15.8885 8.66663C15.5114 8.66663 15.1388 8.74839 14.7963 8.9063C14.4942 9.05696 14.2435 9.29377 14.0758 9.58683C13.909 9.90169 13.8218 10.2526 13.8218 10.6089C13.8218 10.9653 13.909 11.3162 14.0758 11.631C14.2436 11.9242 14.4945 12.161 14.7969 12.3116C15.1393 12.4695 15.5119 12.5513 15.889 12.5513C16.2661 12.5513 16.6387 12.4695 16.9811 12.3116C17.2832 12.1611 17.5339 11.9244 17.7016 11.6316C17.8686 11.3167 17.9559 10.9656 17.9559 10.6092C17.9559 10.2528 17.8686 9.90174 17.7016 9.58683C17.5338 9.29375 17.2829 9.05696 16.9806 8.9063V8.9063ZM16.4543 11.2816C16.3831 11.3614 16.2959 11.4254 16.1983 11.4691C16.1007 11.5129 15.9949 11.5355 15.8879 11.5355C15.781 11.5355 15.6752 11.5129 15.5776 11.4691C15.48 11.4254 15.3928 11.3614 15.3216 11.2816C15.1874 11.0828 15.1157 10.8485 15.1157 10.6087C15.1157 10.3689 15.1874 10.1345 15.3216 9.93579C15.3928 9.85592 15.48 9.79198 15.5776 9.74822C15.6752 9.70445 15.781 9.68183 15.8879 9.68183C15.9949 9.68183 16.1007 9.70445 16.1983 9.74822C16.2959 9.79198 16.3831 9.85592 16.4543 9.93579C16.5885 10.1345 16.6602 10.3689 16.6602 10.6087C16.6602 10.8485 16.5885 11.0828 16.4543 11.2816Z" fill="#FF9415"/>
<path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433282 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM16.1958 16.1958C14.6591 17.7279 12.607 18.6329 10.4395 18.7346C8.27189 18.8363 6.1441 18.1274 4.47071 16.7459C2.79732 15.3644 1.6982 13.4094 1.38754 11.2618C1.07687 9.11422 1.57691 6.92788 2.79021 5.12883C4.00352 3.32978 5.84317 2.04689 7.9507 1.53016C10.0582 1.01343 12.2827 1.29986 14.1905 2.33365C16.0984 3.36744 17.553 5.07452 18.271 7.12226C18.989 9.17 18.9188 11.4117 18.0742 13.4105C17.6342 14.4518 16.9963 15.3977 16.1958 16.1958Z" fill="#FF9415"/>
</g>
<defs>
<clipPath id="clip0_11462_156">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
