// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2896)">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#E9EAE9"/>
<path d="M12.6463 15.1679L10.0805 11.4769L7.57053 15.1679H4.75159L8.68422 9.77211L4.93948 4.55054H7.72895L10.1726 8.00001L12.5769 4.55054H15.2347L11.5169 9.6779L15.4763 15.1679H12.6463Z" fill="black"/>
<path d="M11.7185 6.32212C12.2475 6.65538 12.6783 7.1233 12.9669 7.6779L13.7722 6.56369C13.4189 6.04716 12.9505 5.61978 12.4038 5.31527C12.3096 5.26264 12.2159 5.22106 12.1354 5.18106L11.4364 6.1879C11.533 6.22713 11.6272 6.27194 11.7185 6.32212Z" fill="#7D7E7D"/>
<path d="M8.59058 13.6505C8.26617 13.5014 7.96725 13.3021 7.70479 13.06C7.41156 12.7772 7.16309 12.4513 6.96795 12.0937L6.10742 13.2747C6.32451 13.5631 6.5715 13.8278 6.84426 14.0642C7.15343 14.3209 7.4967 14.5334 7.86426 14.6958L8.59058 13.6505Z" fill="#7D7E7D"/>
<path d="M12.1342 13.5837C11.9919 13.6659 11.8438 13.7376 11.6911 13.7984L12.349 14.7516C12.8513 14.5394 13.2875 14.1965 13.6121 13.7584C13.765 13.5509 13.8829 13.3198 13.9611 13.0742L11.5179 9.67841L11.5311 9.66473C11.5179 9.66473 11.5042 9.66473 11.5042 9.65157L10.699 9.47684V7.23473L10.1753 7.98631L9.62476 7.19684V9.22368L9.32634 9.1542C8.96321 9.08035 8.61424 8.94886 8.29265 8.76473C8.07476 8.64425 7.89733 8.46206 7.78266 8.24105C7.68493 8.03552 7.63439 7.81073 7.63476 7.58315C7.63035 7.28161 7.71954 6.98612 7.89002 6.73736C8.07968 6.46465 8.34601 6.25436 8.65529 6.13315C8.70792 6.10631 8.76055 6.09263 8.81634 6.06578L8.16107 5.15421C8.13423 5.16789 8.12055 5.16789 8.09371 5.18105C7.57051 5.4151 7.12393 5.79199 6.80529 6.26841C6.69755 6.42816 6.60755 6.59917 6.53687 6.77841L8.65792 9.75841L8.34897 10.1747C8.52371 10.2274 8.7116 10.28 8.92792 10.3358L9.59897 10.4837V12.1479L10.069 11.4637L10.6863 12.3495V10.7516L11.2769 10.8858C11.731 10.9519 12.1532 11.1584 12.4842 11.4763C12.612 11.6327 12.705 11.8145 12.757 12.0096C12.809 12.2047 12.8188 12.4086 12.7857 12.6078C12.7527 12.807 12.6776 12.9969 12.5655 13.1648C12.4533 13.3327 12.3067 13.4748 12.1353 13.5816L12.1342 13.5837Z" fill="#7D7E7D"/>
</g>
<defs>
<clipPath id="clip0_9856_2896">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
