var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect } from 'react';
import { GoBackButton } from '../../../../1_atoms/GoBackButton/GoBackButton';
import { useAccount } from '../../../../../hooks/useAccount';
import { ReceiveflowStep } from '../../contexts/receiveflow';
import { useReceiveFlowContext } from '../../contexts/receiveflow';
import { AddressForm } from './components/AddressForm';
import { MainScreen } from './components/MainScreen';
import { StatusScreen } from './components/StatusScreen';
export const ReceiveFlow = ({ onClose }) => {
    const { account: evmAddress } = useAccount();
    const { step, set, requestLastScannedBlock, getRuneDepositStatus, depositTx, } = useReceiveFlowContext();
    const onBackClick = useCallback(() => {
        set(prevState => (Object.assign(Object.assign({}, prevState), { step: ReceiveflowStep.MAIN })));
    }, [set]);
    useEffect(() => {
        if ((depositTx === null || depositTx === void 0 ? void 0 : depositTx.lastBlockHash) ||
            !evmAddress ||
            ![
                ReceiveflowStep.ADDRESS,
                ReceiveflowStep.PROCESSING,
                ReceiveflowStep.COMPLETED,
            ].includes(step)) {
            return;
        }
        requestLastScannedBlock().then(response => {
            if (!response.last_scanned_block) {
                return;
            }
            if (!(depositTx === null || depositTx === void 0 ? void 0 : depositTx.lastBlockHash)) {
                set(prevState => (Object.assign(Object.assign({}, prevState), { depositTx: Object.assign(Object.assign({}, prevState.depositTx), { lastBlockHash: response.last_scanned_block }) })));
            }
        });
    }, [
        depositTx === null || depositTx === void 0 ? void 0 : depositTx.lastBlockHash,
        evmAddress,
        requestLastScannedBlock,
        set,
        step,
    ]);
    useEffect(() => {
        if (!depositTx.lastBlockHash || !evmAddress) {
            return;
        }
        const checkPendingTx = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!depositTx.lastBlockHash) {
                return;
            }
            const response = yield getRuneDepositStatus(evmAddress, depositTx.lastBlockHash);
            const currentDepositStatus = (_a = response.deposits) === null || _a === void 0 ? void 0 : _a[0];
            if (!currentDepositStatus) {
                // no deposits
                return;
            }
            const currentStep = (currentDepositStatus === null || currentDepositStatus === void 0 ? void 0 : currentDepositStatus.status) === 'confirmed'
                ? ReceiveflowStep.COMPLETED
                : ReceiveflowStep.PROCESSING;
            set(prevState => (Object.assign(Object.assign({}, prevState), { step: currentStep, isTxInProgress: (currentDepositStatus === null || currentDepositStatus === void 0 ? void 0 : currentDepositStatus.status) !== 'confirmed', depositTx: Object.assign(Object.assign({}, prevState.depositTx), { statuses: response.deposits, currentTX: {
                        btcDepositTxid: currentDepositStatus.btc_deposit_txid,
                        btcDepositVout: currentDepositStatus.btc_deposit_vout,
                        runeName: currentDepositStatus.rune_name,
                        runeSymbol: currentDepositStatus.rune_symbol,
                        amountDecimal: currentDepositStatus.amount_decimal,
                        feeDecimal: currentDepositStatus.fee_decimal,
                        receiveAmountDecimal: currentDepositStatus.receive_amount_decimal,
                        status: currentDepositStatus.status,
                        evmTransferTxHash: currentDepositStatus.evm_transfer_tx_hash,
                    } }) })));
        });
        const interval = setInterval(checkPendingTx, 10000);
        return () => clearInterval(interval);
    }, [
        depositTx,
        depositTx.lastBlockHash,
        depositTx.currentTX,
        evmAddress,
        getRuneDepositStatus,
        requestLastScannedBlock,
        set,
        step,
    ]);
    return (React.createElement("div", null,
        step === ReceiveflowStep.ADDRESS && (React.createElement(GoBackButton, { onClick: onBackClick })),
        React.createElement("div", { className: "mt-0 md:mt-12" },
            step === ReceiveflowStep.MAIN && React.createElement(MainScreen, null),
            [ReceiveflowStep.PROCESSING, ReceiveflowStep.COMPLETED].includes(step) && (depositTx === null || depositTx === void 0 ? void 0 : depositTx.currentTX.status) ? (React.createElement(StatusScreen, { onClose: onClose })) : (step === ReceiveflowStep.ADDRESS ||
                (depositTx === null || depositTx === void 0 ? void 0 : depositTx.currentTX.status) === 'confirmed') &&
                depositTx.lastBlockHash ? (React.createElement("div", null,
                React.createElement(AddressForm, null))) : (''))));
};
