var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class ApiError extends Error {
}
class RuneBridgeClient {
    constructor(baseUrl) {
        this._baseUrl = this.sanitizeUrl(baseUrl);
    }
    sanitizeUrl(url) {
        return url.replace(/\/*$/, '');
    }
    request(path, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.requestRaw(path, opts)
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (!response.ok) {
                    let message = '';
                    try {
                        const jsonData = yield response.json();
                        message = jsonData.error;
                    }
                    catch (e) { }
                    if (!message) {
                        message = yield response.text();
                    }
                    throw new ApiError(message);
                }
                return yield response.json();
            }))
                .catch(error => {
                if (error instanceof TypeError &&
                    (error.message.includes('Failed to fetch') ||
                        error.message.includes('NetworkError when attempting to fetch resource'))) {
                    throw new ApiError('Unable to connect to the server. Please try again later.');
                }
                throw error;
            });
        });
    }
    requestRaw(path, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            let { data, method = 'GET', multipart } = opts;
            const url = this.getApiUrl(path);
            const headers = new Headers();
            if (!multipart) {
                headers.set('Content-Type', 'application/json');
            }
            const fetchOpts = {
                method,
                credentials: 'include',
                mode: 'cors',
                headers,
            };
            if (data) {
                if (multipart) {
                    fetchOpts.body = new FormData();
                    for (const [key, value] of Object.entries(data)) {
                        fetchOpts.body.append(key, value);
                    }
                }
                else {
                    fetchOpts.body = JSON.stringify(data);
                }
            }
            return yield fetch(url, fetchOpts);
        });
    }
    getApiUrl(path) {
        if (path.charAt(0) !== '/') {
            path = '/' + path;
        }
        return `${this._baseUrl}${path}`;
    }
}
export default RuneBridgeClient;
