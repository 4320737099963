import { useMemo } from 'react';
import { ethers } from 'ethers';
import { useWalletConnect } from './useWalletConnect';
export const useAccount = () => {
    var _a, _b, _c, _d;
    const { wallets } = useWalletConnect();
    const web3provider = useMemo(() => {
        var _a, _b;
        return ((_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.provider)
            ? new ethers.providers.Web3Provider((_b = wallets[0]) === null || _b === void 0 ? void 0 : _b.provider)
            : undefined;
    }, [wallets]);
    const signer = useMemo(() => web3provider === null || web3provider === void 0 ? void 0 : web3provider.getSigner(), [web3provider]);
    return {
        account: ((_b = (_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address.toLowerCase()) || '',
        type: (_c = wallets[0]) === null || _c === void 0 ? void 0 : _c.label,
        eip1193Provider: (_d = wallets[0]) === null || _d === void 0 ? void 0 : _d.provider,
        provider: web3provider,
        signer,
    };
};
