import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { t } from 'i18next';
import { ErrorBadge, ErrorLevel, OrderDirection, Pagination, Paragraph, ParagraphSize, Table, } from '@sovryn/ui';
import { RSK_CHAIN_ID } from '../../../../../config/chains';
import { AmountRenderer } from '../../../../2_molecules/AmountRenderer/AmountRenderer';
import { ExportCSV } from '../../../../2_molecules/ExportCSV/ExportCSV';
import { TxIdWithNotification } from '../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BITCOIN, BTC_RENDER_PRECISION, } from '../../../../../constants/currencies';
import { DEFAULT_HISTORY_FRAME_PAGE_SIZE } from '../../../../../constants/general';
import { useAccount } from '../../../../../hooks/useAccount';
import { useBlockNumber } from '../../../../../hooks/useBlockNumber';
import { useMaintenance } from '../../../../../hooks/useMaintenance';
import { translations } from '../../../../../locales/i18n';
import { getChainById } from '../../../../../utils/chain';
import { dateFormat } from '../../../../../utils/helpers';
import { useGetCollateralSurplusWithdrawals } from './hooks/useGetCollateralSurplusWithdrawals';
const pageSize = DEFAULT_HISTORY_FRAME_PAGE_SIZE;
export const CollateralSurplusHistoryFrame = ({ children, }) => {
    const { account } = useAccount();
    const [page, setPage] = useState(0);
    const chain = getChainById(RSK_CHAIN_ID);
    const { value: block } = useBlockNumber();
    const [orderOptions, setOrderOptions] = useState({
        orderBy: 'sequenceNumber',
        orderDirection: OrderDirection.Desc,
    });
    const { data, loading, refetch, exportData } = useGetCollateralSurplusWithdrawals(account, pageSize, page, orderOptions);
    useEffect(() => {
        refetch();
    }, [refetch, block]);
    const renderCollateralChange = useCallback((collSurplusChange) => (React.createElement(AmountRenderer, { value: collSurplusChange, suffix: BITCOIN, precision: BTC_RENDER_PRECISION, dataAttribute: "surplus-withdrawals-collateral" })), []);
    const generateRowTitle = useCallback((row) => (React.createElement(Paragraph, { size: ParagraphSize.small, className: "text-left" },
        t(translations.collateralSurplusHistory.table.withdrawSurplus),
        ' - ',
        dateFormat(row.timestamp))), []);
    const columns = useMemo(() => [
        {
            id: 'sequenceNumber',
            title: t(translations.common.tables.columnTitles.timestamp),
            cellRenderer: (tx) => dateFormat(tx.timestamp),
            sortable: true,
        },
        {
            id: 'withdrawSurplus',
            title: t(translations.common.tables.columnTitles.transactionType),
            cellRenderer: () => t(translations.collateralSurplusHistory.table.withdrawSurplus),
        },
        {
            id: 'collSurplusChange',
            title: t(translations.collateralSurplusHistory.table.amount),
            cellRenderer: tx => renderCollateralChange(tx.collSurplusChange),
        },
        {
            id: 'transactionID',
            title: t(translations.common.tables.columnTitles.transactionID),
            cellRenderer: (tx) => (React.createElement(TxIdWithNotification, { href: `${chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl}/tx/${tx.hash}`, value: tx.hash, dataAttribute: "history-address-id" })),
        },
    ], [chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl, renderCollateralChange]);
    const onPageChange = useCallback((value) => {
        if ((data === null || data === void 0 ? void 0 : data.length) < pageSize && value > page) {
            return;
        }
        setPage(value);
    }, [page, data]);
    const isNextButtonDisabled = useMemo(() => !loading && (data === null || data === void 0 ? void 0 : data.length) < pageSize, [loading, data]);
    useEffect(() => {
        setPage(0);
    }, [orderOptions]);
    const { checkMaintenance, States } = useMaintenance();
    const exportLocked = checkMaintenance(States.ZERO_EXPORT_CSV);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-row items-center gap-4 mb-7 flex justify-center lg:justify-start" },
            children,
            React.createElement("div", { className: "flex-row items-center ml-2 gap-4 hidden lg:inline-flex" },
                React.createElement(ExportCSV, { getData: exportData, filename: "collateral surplus withdrawals", disabled: !data || data.length === 0 || exportLocked }),
                exportLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.featureDisabled) })))),
        React.createElement("div", { className: "bg-gray-80 py-4 px-4 rounded" },
            React.createElement(Table, { setOrderOptions: setOrderOptions, orderOptions: orderOptions, columns: columns, rows: data, rowTitle: generateRowTitle, isLoading: loading, className: "bg-gray-80 text-gray-10 lg:px-6 lg:py-4", noData: t(translations.common.tables.noData), loadingData: t(translations.common.tables.loading), dataAttribute: "surplus-withdrawals-table" }),
            React.createElement(Pagination, { page: page, className: "lg:pb-6 mt-3 lg:mt-6 justify-center lg:justify-start", onChange: onPageChange, itemsPerPage: pageSize, isNextButtonDisabled: isNextButtonDisabled, dataAttribute: "surplus-withdrawals-pagination" }))));
};
