import { useMemo } from 'react';
import { rskClient } from '../../../../../../utils/clients';
import { useGetBorrowHistoryQuery, } from '../../../../../../utils/graphql/rsk/generated';
export const useGetNewLoans = (account, pageSize, page, orderOptions) => {
    const config = useMemo(() => ({
        skip: page * pageSize,
        pageSize,
        orderBy: orderOptions.orderBy || undefined,
        orderDirection: orderOptions.orderDirection || undefined,
        user: account === null || account === void 0 ? void 0 : account.toLowerCase(),
    }), [page, orderOptions, pageSize, account]);
    const { loading, data, refetch } = useGetBorrowHistoryQuery({
        variables: config,
        client: rskClient,
    });
    const borrows = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.borrows.map(tx => ({
            id: tx.transaction.id,
            loanId: tx.loanId.id,
            collateralToken: tx.loanId.collateralToken.symbol,
            loanToken: tx.loanId.loanToken.symbol,
            newPrincipal: tx.newPrincipal,
            newCollateral: tx.newCollateral,
            interestRate: tx.interestRate,
            interestDuration: tx.interestDuration,
            collateralToLoanRate: tx.collateralToLoanRate,
            timestamp: tx.timestamp,
            hash: tx.transaction.id,
        }));
    }, [data]);
    return { loading, data: borrows, refetch };
};
