// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11380_365)">
<path d="M10 20C15.5417 20 20 15.5417 20 10C20 4.4583 15.5417 0 10 0C4.4583 0 0 4.4583 0 10C0 15.5417 4.4583 20 10 20Z" fill="#2775CA"/>
<path d="M12.75 11.5833C12.75 10.125 11.875 9.62501 10.125 9.41671C8.87505 9.25001 8.62505 8.91671 8.62505 8.33331C8.62505 7.74991 9.04175 7.37501 9.87505 7.37501C10.625 7.37501 11.0417 7.62501 11.25 8.25001C11.2917 8.37501 11.4167 8.45831 11.5417 8.45831H12.2083C12.375 8.45831 12.5 8.33331 12.5 8.16671V8.12501C12.3333 7.20831 11.5833 6.50001 10.625 6.41671V5.41671C10.625 5.25001 10.5 5.12501 10.2917 5.08331H9.66675C9.50005 5.08331 9.37505 5.20831 9.33335 5.41671V6.37501C8.08335 6.54171 7.29175 7.37501 7.29175 8.41671C7.29175 9.79171 8.12505 10.3333 9.87505 10.5417C11.0417 10.75 11.4167 11 11.4167 11.6667C11.4167 12.3334 10.8333 12.7917 10.0417 12.7917C8.95835 12.7917 8.58335 12.3333 8.45835 11.7083C8.41675 11.5417 8.29175 11.4583 8.16675 11.4583H7.45835C7.29175 11.4583 7.16675 11.5833 7.16675 11.75V11.7917C7.33335 12.8333 8.00005 13.5833 9.37505 13.7917V14.7917C9.37505 14.9583 9.50005 15.0833 9.70835 15.125H10.3333C10.5 15.125 10.625 15 10.6667 14.7917V13.7917C11.9167 13.5833 12.75 12.7083 12.75 11.5833Z" fill="white"/>
<path d="M7.87496 15.9583C4.62496 14.7917 2.95826 11.1667 4.16666 7.95829C4.79166 6.20829 6.16666 4.87499 7.87496 4.24999C8.04166 4.16669 8.12496 4.04169 8.12496 3.83329V3.24999C8.12496 3.08329 8.04166 2.95829 7.87496 2.91669C7.83326 2.91669 7.74996 2.91669 7.70826 2.95829C3.74996 4.20829 1.58326 8.41669 2.83326 12.375C3.58326 14.7083 5.37496 16.5 7.70826 17.25C7.87496 17.3333 8.04166 17.25 8.08326 17.0833C8.12496 17.0417 8.12496 17 8.12496 16.9167V16.3333C8.12496 16.2083 7.99996 16.0417 7.87496 15.9583ZM12.2917 2.95829C12.125 2.87499 11.9583 2.95829 11.9167 3.12499C11.875 3.16669 11.875 3.20829 11.875 3.29169V3.87499C11.875 4.04169 12 4.20829 12.125 4.29169C15.375 5.45829 17.0417 9.08329 15.8333 12.2917C15.2083 14.0417 13.8333 15.375 12.125 16C11.9583 16.0833 11.875 16.2083 11.875 16.4167V17C11.875 17.1667 11.9583 17.2917 12.125 17.3333C12.1667 17.3333 12.25 17.3333 12.2917 17.2917C16.25 16.0417 18.4167 11.8333 17.1667 7.87499C16.4167 5.49999 14.5833 3.70829 12.2917 2.95829Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_11380_365">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
