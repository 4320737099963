import React, { useCallback } from 'react';
import { GoBackButton } from '../../../../1_atoms/GoBackButton/GoBackButton';
import { SendFlowStep } from '../../contexts/sendflow';
import { useSendFlowContext } from '../../contexts/sendflow';
import { AddressForm } from './components/AddressForm';
import { AmountForm } from './components/AmountForm';
import { ConfirmationScreens } from './components/ConfirmationScreens';
import { MainScreen } from './components/MainScreen';
const allowedStepsToGoBackFrom = [
    SendFlowStep.AMOUNT,
    SendFlowStep.ADDRESS,
    SendFlowStep.REVIEW,
];
const getBackStep = (step) => {
    switch (step) {
        case SendFlowStep.AMOUNT:
            return SendFlowStep.MAIN;
        case SendFlowStep.ADDRESS:
            return SendFlowStep.AMOUNT;
        case SendFlowStep.REVIEW:
            return SendFlowStep.ADDRESS;
        default:
            return SendFlowStep.AMOUNT;
    }
};
export const SendFlow = ({ onClose }) => {
    const { set, step } = useSendFlowContext();
    const onBackClick = useCallback(() => {
        set(prevState => (Object.assign(Object.assign({}, prevState), { step: getBackStep(step) })));
    }, [set, step]);
    return (React.createElement(React.Fragment, null,
        allowedStepsToGoBackFrom.includes(step) && (React.createElement(GoBackButton, { onClick: onBackClick })),
        React.createElement("div", { className: "mt-0 md:mt-12" },
            step === SendFlowStep.MAIN && React.createElement(MainScreen, null),
            step === SendFlowStep.AMOUNT && React.createElement(AmountForm, null),
            step === SendFlowStep.ADDRESS && React.createElement(AddressForm, null),
            [
                SendFlowStep.REVIEW,
                SendFlowStep.CONFIRM,
                SendFlowStep.PROCESSING,
                SendFlowStep.COMPLETED,
            ].includes(step) && React.createElement(ConfirmationScreens, { onClose: onClose }))));
};
