import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var BitcoinTransferBatchSending_OrderBy;
(function (BitcoinTransferBatchSending_OrderBy) {
    BitcoinTransferBatchSending_OrderBy["BitcoinTxHash"] = "bitcoinTxHash";
    BitcoinTransferBatchSending_OrderBy["EmittedBy"] = "emittedBy";
    BitcoinTransferBatchSending_OrderBy["Id"] = "id";
    BitcoinTransferBatchSending_OrderBy["Timestamp"] = "timestamp";
    BitcoinTransferBatchSending_OrderBy["Transaction"] = "transaction";
    BitcoinTransferBatchSending_OrderBy["TransferBatchSize"] = "transferBatchSize";
})(BitcoinTransferBatchSending_OrderBy || (BitcoinTransferBatchSending_OrderBy = {}));
export var BitcoinTransferDirection;
(function (BitcoinTransferDirection) {
    BitcoinTransferDirection["Incoming"] = "INCOMING";
    BitcoinTransferDirection["Outgoing"] = "OUTGOING";
})(BitcoinTransferDirection || (BitcoinTransferDirection = {}));
export var BitcoinTransferStatus;
(function (BitcoinTransferStatus) {
    /**
     * the transfer was confirmedly mined in Bitcoin blockchain
     *
     */
    BitcoinTransferStatus["Mined"] = "MINED";
    /**
     * the transfer was initiated
     *
     */
    BitcoinTransferStatus["New"] = "NEW";
    /**
     * the transfer slot has not been initialized
     *
     */
    BitcoinTransferStatus["NotApplicable"] = "NOT_APPLICABLE";
    /**
     * the transfer was reclaimed by the user
     *
     */
    BitcoinTransferStatus["Reclaimed"] = "RECLAIMED";
    /**
     * the transfer was refunded
     *
     */
    BitcoinTransferStatus["Refunded"] = "REFUNDED";
    /**
     * the federators have approved this transfer as part of a transfer batch
     *
     */
    BitcoinTransferStatus["Sending"] = "SENDING";
})(BitcoinTransferStatus || (BitcoinTransferStatus = {}));
export var BitcoinTransfer_OrderBy;
(function (BitcoinTransfer_OrderBy) {
    BitcoinTransfer_OrderBy["AmountBtc"] = "amountBTC";
    BitcoinTransfer_OrderBy["BitcoinTxHash"] = "bitcoinTxHash";
    BitcoinTransfer_OrderBy["BtcAddress"] = "btcAddress";
    BitcoinTransfer_OrderBy["CreatedAtBlockNumber"] = "createdAtBlockNumber";
    BitcoinTransfer_OrderBy["CreatedAtTimestamp"] = "createdAtTimestamp";
    BitcoinTransfer_OrderBy["CreatedAtTx"] = "createdAtTx";
    BitcoinTransfer_OrderBy["Direction"] = "direction";
    BitcoinTransfer_OrderBy["FeeBtc"] = "feeBTC";
    BitcoinTransfer_OrderBy["Id"] = "id";
    BitcoinTransfer_OrderBy["Nonce"] = "nonce";
    BitcoinTransfer_OrderBy["Status"] = "status";
    BitcoinTransfer_OrderBy["TotalAmountBtc"] = "totalAmountBTC";
    BitcoinTransfer_OrderBy["UpdatedAtBlockNumber"] = "updatedAtBlockNumber";
    BitcoinTransfer_OrderBy["UpdatedAtTimestamp"] = "updatedAtTimestamp";
    BitcoinTransfer_OrderBy["UpdatedAtTx"] = "updatedAtTx";
    BitcoinTransfer_OrderBy["User"] = "user";
})(BitcoinTransfer_OrderBy || (BitcoinTransfer_OrderBy = {}));
export var Borrow_OrderBy;
(function (Borrow_OrderBy) {
    Borrow_OrderBy["CollateralToLoanRate"] = "collateralToLoanRate";
    Borrow_OrderBy["CollateralToken"] = "collateralToken";
    Borrow_OrderBy["CurrentMargin"] = "currentMargin";
    Borrow_OrderBy["EmittedBy"] = "emittedBy";
    Borrow_OrderBy["Id"] = "id";
    Borrow_OrderBy["InterestDuration"] = "interestDuration";
    Borrow_OrderBy["InterestRate"] = "interestRate";
    Borrow_OrderBy["Lender"] = "lender";
    Borrow_OrderBy["LoanId"] = "loanId";
    Borrow_OrderBy["LoanToken"] = "loanToken";
    Borrow_OrderBy["NewCollateral"] = "newCollateral";
    Borrow_OrderBy["NewPrincipal"] = "newPrincipal";
    Borrow_OrderBy["Timestamp"] = "timestamp";
    Borrow_OrderBy["Transaction"] = "transaction";
    Borrow_OrderBy["User"] = "user";
})(Borrow_OrderBy || (Borrow_OrderBy = {}));
export var BridgeChain;
(function (BridgeChain) {
    BridgeChain["Bsc"] = "BSC";
    BridgeChain["Eth"] = "ETH";
    BridgeChain["Rsk"] = "RSK";
})(BridgeChain || (BridgeChain = {}));
/**
 * BridgeType - only 2 bridges at the moment - RSK <-> BSC and RSK <-> ETH
 *
 */
export var BridgeType;
(function (BridgeType) {
    BridgeType["RskBsc"] = "RSK_BSC";
    BridgeType["RskEth"] = "RSK_ETH";
})(BridgeType || (BridgeType = {}));
export var Bridge_OrderBy;
(function (Bridge_OrderBy) {
    Bridge_OrderBy["CreatedAtTx"] = "createdAtTx";
    Bridge_OrderBy["Federation"] = "federation";
    Bridge_OrderBy["Id"] = "id";
    Bridge_OrderBy["IsPaused"] = "isPaused";
    Bridge_OrderBy["IsSuffix"] = "isSuffix";
    Bridge_OrderBy["IsUpgrading"] = "isUpgrading";
    Bridge_OrderBy["Pausers"] = "pausers";
    Bridge_OrderBy["Prefix"] = "prefix";
    Bridge_OrderBy["Type"] = "type";
    Bridge_OrderBy["UpdatedAtTx"] = "updatedAtTx";
})(Bridge_OrderBy || (Bridge_OrderBy = {}));
export var CandleStickDay_OrderBy;
(function (CandleStickDay_OrderBy) {
    CandleStickDay_OrderBy["BaseToken"] = "baseToken";
    CandleStickDay_OrderBy["Close"] = "close";
    CandleStickDay_OrderBy["High"] = "high";
    CandleStickDay_OrderBy["Id"] = "id";
    CandleStickDay_OrderBy["Low"] = "low";
    CandleStickDay_OrderBy["Open"] = "open";
    CandleStickDay_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    CandleStickDay_OrderBy["QuoteToken"] = "quoteToken";
    CandleStickDay_OrderBy["TotalVolume"] = "totalVolume";
    CandleStickDay_OrderBy["TxCount"] = "txCount";
})(CandleStickDay_OrderBy || (CandleStickDay_OrderBy = {}));
export var CandleStickFifteenMinute_OrderBy;
(function (CandleStickFifteenMinute_OrderBy) {
    CandleStickFifteenMinute_OrderBy["BaseToken"] = "baseToken";
    CandleStickFifteenMinute_OrderBy["Close"] = "close";
    CandleStickFifteenMinute_OrderBy["High"] = "high";
    CandleStickFifteenMinute_OrderBy["Id"] = "id";
    CandleStickFifteenMinute_OrderBy["Low"] = "low";
    CandleStickFifteenMinute_OrderBy["Open"] = "open";
    CandleStickFifteenMinute_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    CandleStickFifteenMinute_OrderBy["QuoteToken"] = "quoteToken";
    CandleStickFifteenMinute_OrderBy["TotalVolume"] = "totalVolume";
    CandleStickFifteenMinute_OrderBy["TxCount"] = "txCount";
})(CandleStickFifteenMinute_OrderBy || (CandleStickFifteenMinute_OrderBy = {}));
export var CandleStickFourHour_OrderBy;
(function (CandleStickFourHour_OrderBy) {
    CandleStickFourHour_OrderBy["BaseToken"] = "baseToken";
    CandleStickFourHour_OrderBy["Close"] = "close";
    CandleStickFourHour_OrderBy["High"] = "high";
    CandleStickFourHour_OrderBy["Id"] = "id";
    CandleStickFourHour_OrderBy["Low"] = "low";
    CandleStickFourHour_OrderBy["Open"] = "open";
    CandleStickFourHour_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    CandleStickFourHour_OrderBy["QuoteToken"] = "quoteToken";
    CandleStickFourHour_OrderBy["TotalVolume"] = "totalVolume";
    CandleStickFourHour_OrderBy["TxCount"] = "txCount";
})(CandleStickFourHour_OrderBy || (CandleStickFourHour_OrderBy = {}));
export var CandleStickHour_OrderBy;
(function (CandleStickHour_OrderBy) {
    CandleStickHour_OrderBy["BaseToken"] = "baseToken";
    CandleStickHour_OrderBy["Close"] = "close";
    CandleStickHour_OrderBy["High"] = "high";
    CandleStickHour_OrderBy["Id"] = "id";
    CandleStickHour_OrderBy["Low"] = "low";
    CandleStickHour_OrderBy["Open"] = "open";
    CandleStickHour_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    CandleStickHour_OrderBy["QuoteToken"] = "quoteToken";
    CandleStickHour_OrderBy["TotalVolume"] = "totalVolume";
    CandleStickHour_OrderBy["TxCount"] = "txCount";
})(CandleStickHour_OrderBy || (CandleStickHour_OrderBy = {}));
export var CandleStickMinute_OrderBy;
(function (CandleStickMinute_OrderBy) {
    CandleStickMinute_OrderBy["BaseToken"] = "baseToken";
    CandleStickMinute_OrderBy["Close"] = "close";
    CandleStickMinute_OrderBy["High"] = "high";
    CandleStickMinute_OrderBy["Id"] = "id";
    CandleStickMinute_OrderBy["Low"] = "low";
    CandleStickMinute_OrderBy["Open"] = "open";
    CandleStickMinute_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    CandleStickMinute_OrderBy["QuoteToken"] = "quoteToken";
    CandleStickMinute_OrderBy["TotalVolume"] = "totalVolume";
    CandleStickMinute_OrderBy["TxCount"] = "txCount";
})(CandleStickMinute_OrderBy || (CandleStickMinute_OrderBy = {}));
export var CandleSticksInterval;
(function (CandleSticksInterval) {
    CandleSticksInterval["DayInterval"] = "DayInterval";
    CandleSticksInterval["FifteenMinutesInterval"] = "FifteenMinutesInterval";
    CandleSticksInterval["FourHourInterval"] = "FourHourInterval";
    CandleSticksInterval["HourInterval"] = "HourInterval";
    CandleSticksInterval["MinuteInterval"] = "MinuteInterval";
})(CandleSticksInterval || (CandleSticksInterval = {}));
export var CloseWithDeposit_OrderBy;
(function (CloseWithDeposit_OrderBy) {
    CloseWithDeposit_OrderBy["Closer"] = "closer";
    CloseWithDeposit_OrderBy["CollateralToLoanRate"] = "collateralToLoanRate";
    CloseWithDeposit_OrderBy["CollateralToken"] = "collateralToken";
    CloseWithDeposit_OrderBy["CollateralWithdrawAmount"] = "collateralWithdrawAmount";
    CloseWithDeposit_OrderBy["CurrentMargin"] = "currentMargin";
    CloseWithDeposit_OrderBy["EmittedBy"] = "emittedBy";
    CloseWithDeposit_OrderBy["Id"] = "id";
    CloseWithDeposit_OrderBy["Lender"] = "lender";
    CloseWithDeposit_OrderBy["LoanId"] = "loanId";
    CloseWithDeposit_OrderBy["LoanToken"] = "loanToken";
    CloseWithDeposit_OrderBy["RepayAmount"] = "repayAmount";
    CloseWithDeposit_OrderBy["Timestamp"] = "timestamp";
    CloseWithDeposit_OrderBy["Transaction"] = "transaction";
    CloseWithDeposit_OrderBy["User"] = "user";
})(CloseWithDeposit_OrderBy || (CloseWithDeposit_OrderBy = {}));
export var CloseWithSwap_OrderBy;
(function (CloseWithSwap_OrderBy) {
    CloseWithSwap_OrderBy["Closer"] = "closer";
    CloseWithSwap_OrderBy["CollateralToken"] = "collateralToken";
    CloseWithSwap_OrderBy["CurrentLeverage"] = "currentLeverage";
    CloseWithSwap_OrderBy["EmittedBy"] = "emittedBy";
    CloseWithSwap_OrderBy["ExitPrice"] = "exitPrice";
    CloseWithSwap_OrderBy["Id"] = "id";
    CloseWithSwap_OrderBy["Lender"] = "lender";
    CloseWithSwap_OrderBy["LoanCloseAmount"] = "loanCloseAmount";
    CloseWithSwap_OrderBy["LoanId"] = "loanId";
    CloseWithSwap_OrderBy["LoanToken"] = "loanToken";
    CloseWithSwap_OrderBy["PositionCloseSize"] = "positionCloseSize";
    CloseWithSwap_OrderBy["Timestamp"] = "timestamp";
    CloseWithSwap_OrderBy["Transaction"] = "transaction";
    CloseWithSwap_OrderBy["User"] = "user";
})(CloseWithSwap_OrderBy || (CloseWithSwap_OrderBy = {}));
export var Conversion_OrderBy;
(function (Conversion_OrderBy) {
    Conversion_OrderBy["Amount"] = "_amount";
    Conversion_OrderBy["ConversionFee"] = "_conversionFee";
    Conversion_OrderBy["FromToken"] = "_fromToken";
    Conversion_OrderBy["ProtocolFee"] = "_protocolFee";
    Conversion_OrderBy["Return"] = "_return";
    Conversion_OrderBy["ToToken"] = "_toToken";
    Conversion_OrderBy["Trader"] = "_trader";
    Conversion_OrderBy["BlockNumber"] = "blockNumber";
    Conversion_OrderBy["EmittedBy"] = "emittedBy";
    Conversion_OrderBy["Id"] = "id";
    Conversion_OrderBy["Timestamp"] = "timestamp";
    Conversion_OrderBy["Transaction"] = "transaction";
})(Conversion_OrderBy || (Conversion_OrderBy = {}));
export var ConverterRegistry_OrderBy;
(function (ConverterRegistry_OrderBy) {
    ConverterRegistry_OrderBy["ConnectorTokens"] = "connectorTokens";
    ConverterRegistry_OrderBy["Converters"] = "converters";
    ConverterRegistry_OrderBy["Id"] = "id";
    ConverterRegistry_OrderBy["NumConverters"] = "numConverters";
    ConverterRegistry_OrderBy["SmartTokens"] = "smartTokens";
})(ConverterRegistry_OrderBy || (ConverterRegistry_OrderBy = {}));
export var CrossDirection;
(function (CrossDirection) {
    CrossDirection["Incoming"] = "Incoming";
    CrossDirection["Outgoing"] = "Outgoing";
})(CrossDirection || (CrossDirection = {}));
export var CrossStatus;
(function (CrossStatus) {
    CrossStatus["Executed"] = "Executed";
    CrossStatus["Revoked"] = "Revoked";
    CrossStatus["Voting"] = "Voting";
})(CrossStatus || (CrossStatus = {}));
export var CrossTransfer_OrderBy;
(function (CrossTransfer_OrderBy) {
    CrossTransfer_OrderBy["Amount"] = "amount";
    CrossTransfer_OrderBy["CreatedAtTimestamp"] = "createdAtTimestamp";
    CrossTransfer_OrderBy["CreatedAtTx"] = "createdAtTx";
    CrossTransfer_OrderBy["Direction"] = "direction";
    CrossTransfer_OrderBy["ExternalChain"] = "externalChain";
    CrossTransfer_OrderBy["ExternalUser"] = "externalUser";
    CrossTransfer_OrderBy["Id"] = "id";
    CrossTransfer_OrderBy["IsSigned"] = "isSigned";
    CrossTransfer_OrderBy["OriginalTokenAddress"] = "originalTokenAddress";
    CrossTransfer_OrderBy["SideToken"] = "sideToken";
    CrossTransfer_OrderBy["SourceChainBlockHash"] = "sourceChainBlockHash";
    CrossTransfer_OrderBy["SourceChainTransactionHash"] = "sourceChainTransactionHash";
    CrossTransfer_OrderBy["Status"] = "status";
    CrossTransfer_OrderBy["Symbol"] = "symbol";
    CrossTransfer_OrderBy["Token"] = "token";
    CrossTransfer_OrderBy["TokenAddress"] = "tokenAddress";
    CrossTransfer_OrderBy["UpdatedAtTimestamp"] = "updatedAtTimestamp";
    CrossTransfer_OrderBy["UpdatedAtTx"] = "updatedAtTx";
    CrossTransfer_OrderBy["User"] = "user";
    CrossTransfer_OrderBy["Votes"] = "votes";
})(CrossTransfer_OrderBy || (CrossTransfer_OrderBy = {}));
export var DebugItem_OrderBy;
(function (DebugItem_OrderBy) {
    DebugItem_OrderBy["Amount"] = "amount";
    DebugItem_OrderBy["EmittedBy"] = "emittedBy";
    DebugItem_OrderBy["FormattedAmount"] = "formattedAmount";
    DebugItem_OrderBy["Id"] = "id";
    DebugItem_OrderBy["Timestamp"] = "timestamp";
    DebugItem_OrderBy["TotalStaked"] = "totalStaked";
    DebugItem_OrderBy["TotalVested"] = "totalVested";
    DebugItem_OrderBy["Transaction"] = "transaction";
    DebugItem_OrderBy["Type"] = "type";
})(DebugItem_OrderBy || (DebugItem_OrderBy = {}));
export var DepositCollateral_OrderBy;
(function (DepositCollateral_OrderBy) {
    DepositCollateral_OrderBy["DepositAmount"] = "depositAmount";
    DepositCollateral_OrderBy["EmittedBy"] = "emittedBy";
    DepositCollateral_OrderBy["Id"] = "id";
    DepositCollateral_OrderBy["LoanId"] = "loanId";
    DepositCollateral_OrderBy["Rate"] = "rate";
    DepositCollateral_OrderBy["Timestamp"] = "timestamp";
    DepositCollateral_OrderBy["Transaction"] = "transaction";
})(DepositCollateral_OrderBy || (DepositCollateral_OrderBy = {}));
export var Deposit_OrderBy;
(function (Deposit_OrderBy) {
    Deposit_OrderBy["Amount"] = "amount";
    Deposit_OrderBy["EmittedBy"] = "emittedBy";
    Deposit_OrderBy["Id"] = "id";
    Deposit_OrderBy["Timestamp"] = "timestamp";
    Deposit_OrderBy["To"] = "to";
    Deposit_OrderBy["Transaction"] = "transaction";
})(Deposit_OrderBy || (Deposit_OrderBy = {}));
export var FastBtcBridgeStat_OrderBy;
(function (FastBtcBridgeStat_OrderBy) {
    FastBtcBridgeStat_OrderBy["CreatedAtTx"] = "createdAtTx";
    FastBtcBridgeStat_OrderBy["Id"] = "id";
    FastBtcBridgeStat_OrderBy["TotalAmountBtcInitialized"] = "totalAmountBTCInitialized";
    FastBtcBridgeStat_OrderBy["TotalAmountBtcMined"] = "totalAmountBTCMined";
    FastBtcBridgeStat_OrderBy["TotalAmountBtcRefunded"] = "totalAmountBTCRefunded";
    FastBtcBridgeStat_OrderBy["TotalAmountBtcSending"] = "totalAmountBTCSending";
    FastBtcBridgeStat_OrderBy["TotalFeesBtc"] = "totalFeesBTC";
    FastBtcBridgeStat_OrderBy["UpdatedAtTx"] = "updatedAtTx";
    FastBtcBridgeStat_OrderBy["User"] = "user";
})(FastBtcBridgeStat_OrderBy || (FastBtcBridgeStat_OrderBy = {}));
export var Federation_OrderBy;
(function (Federation_OrderBy) {
    Federation_OrderBy["Bridge"] = "bridge";
    Federation_OrderBy["CreatedAtTx"] = "createdAtTx";
    Federation_OrderBy["Id"] = "id";
    Federation_OrderBy["IsActive"] = "isActive";
    Federation_OrderBy["Members"] = "members";
    Federation_OrderBy["TotalExecuted"] = "totalExecuted";
    Federation_OrderBy["TotalVotes"] = "totalVotes";
    Federation_OrderBy["UpdatedAtTx"] = "updatedAtTx";
})(Federation_OrderBy || (Federation_OrderBy = {}));
export var FeeSharingTokensTransferred_OrderBy;
(function (FeeSharingTokensTransferred_OrderBy) {
    FeeSharingTokensTransferred_OrderBy["Amount"] = "amount";
    FeeSharingTokensTransferred_OrderBy["Id"] = "id";
    FeeSharingTokensTransferred_OrderBy["Sender"] = "sender";
    FeeSharingTokensTransferred_OrderBy["Token"] = "token";
})(FeeSharingTokensTransferred_OrderBy || (FeeSharingTokensTransferred_OrderBy = {}));
export var FeeType;
(function (FeeType) {
    FeeType["Amm"] = "AMM";
    FeeType["Borrowing"] = "Borrowing";
    FeeType["Lending"] = "Lending";
    FeeType["Trading"] = "Trading";
})(FeeType || (FeeType = {}));
export var GovernorContract_OrderBy;
(function (GovernorContract_OrderBy) {
    GovernorContract_OrderBy["Guardian"] = "guardian";
    GovernorContract_OrderBy["Id"] = "id";
    GovernorContract_OrderBy["MajorityPercentageVotes"] = "majorityPercentageVotes";
    GovernorContract_OrderBy["ProposalMaxOperations"] = "proposalMaxOperations";
    GovernorContract_OrderBy["Proposals"] = "proposals";
    GovernorContract_OrderBy["QuorumPercentageVotes"] = "quorumPercentageVotes";
    GovernorContract_OrderBy["Staking"] = "staking";
    GovernorContract_OrderBy["Timelock"] = "timelock";
    GovernorContract_OrderBy["Timestamp"] = "timestamp";
    GovernorContract_OrderBy["Type"] = "type";
    GovernorContract_OrderBy["VotingDelay"] = "votingDelay";
    GovernorContract_OrderBy["VotingPeriod"] = "votingPeriod";
})(GovernorContract_OrderBy || (GovernorContract_OrderBy = {}));
export var GovernorType;
(function (GovernorType) {
    GovernorType["Admin"] = "Admin";
    GovernorType["Other"] = "Other";
    GovernorType["Owner"] = "Owner";
})(GovernorType || (GovernorType = {}));
export var ICandleStick_OrderBy;
(function (ICandleStick_OrderBy) {
    ICandleStick_OrderBy["BaseToken"] = "baseToken";
    ICandleStick_OrderBy["Close"] = "close";
    ICandleStick_OrderBy["High"] = "high";
    ICandleStick_OrderBy["Id"] = "id";
    ICandleStick_OrderBy["Low"] = "low";
    ICandleStick_OrderBy["Open"] = "open";
    ICandleStick_OrderBy["PeriodStartUnix"] = "periodStartUnix";
    ICandleStick_OrderBy["QuoteToken"] = "quoteToken";
    ICandleStick_OrderBy["TotalVolume"] = "totalVolume";
    ICandleStick_OrderBy["TxCount"] = "txCount";
})(ICandleStick_OrderBy || (ICandleStick_OrderBy = {}));
export var LendingHistoryItem_OrderBy;
(function (LendingHistoryItem_OrderBy) {
    LendingHistoryItem_OrderBy["Amount"] = "amount";
    LendingHistoryItem_OrderBy["Asset"] = "asset";
    LendingHistoryItem_OrderBy["EmittedBy"] = "emittedBy";
    LendingHistoryItem_OrderBy["Id"] = "id";
    LendingHistoryItem_OrderBy["Lender"] = "lender";
    LendingHistoryItem_OrderBy["LendingPool"] = "lendingPool";
    LendingHistoryItem_OrderBy["LoanTokenAmount"] = "loanTokenAmount";
    LendingHistoryItem_OrderBy["Timestamp"] = "timestamp";
    LendingHistoryItem_OrderBy["Transaction"] = "transaction";
    LendingHistoryItem_OrderBy["Type"] = "type";
    LendingHistoryItem_OrderBy["UserLendingHistory"] = "userLendingHistory";
})(LendingHistoryItem_OrderBy || (LendingHistoryItem_OrderBy = {}));
export var LendingHistoryType;
(function (LendingHistoryType) {
    /**
     * Lend is equivalent to a Mint event
     *
     */
    LendingHistoryType["Lend"] = "Lend";
    /**
     * UnLend is equivalent to a Burn event
     *
     */
    LendingHistoryType["UnLend"] = "UnLend";
})(LendingHistoryType || (LendingHistoryType = {}));
export var LendingPool_OrderBy;
(function (LendingPool_OrderBy) {
    LendingPool_OrderBy["AssetBalance"] = "assetBalance";
    LendingPool_OrderBy["Id"] = "id";
    LendingPool_OrderBy["PoolTokenBalance"] = "poolTokenBalance";
    LendingPool_OrderBy["TotalAssetLent"] = "totalAssetLent";
    LendingPool_OrderBy["UnderlyingAsset"] = "underlyingAsset";
})(LendingPool_OrderBy || (LendingPool_OrderBy = {}));
export var Liquidate_OrderBy;
(function (Liquidate_OrderBy) {
    Liquidate_OrderBy["CollateralToLoanRate"] = "collateralToLoanRate";
    Liquidate_OrderBy["CollateralToken"] = "collateralToken";
    Liquidate_OrderBy["CollateralWithdrawAmount"] = "collateralWithdrawAmount";
    Liquidate_OrderBy["CurrentMargin"] = "currentMargin";
    Liquidate_OrderBy["EmittedBy"] = "emittedBy";
    Liquidate_OrderBy["Id"] = "id";
    Liquidate_OrderBy["Lender"] = "lender";
    Liquidate_OrderBy["Liquidator"] = "liquidator";
    Liquidate_OrderBy["LoanId"] = "loanId";
    Liquidate_OrderBy["LoanToken"] = "loanToken";
    Liquidate_OrderBy["RepayAmount"] = "repayAmount";
    Liquidate_OrderBy["Timestamp"] = "timestamp";
    Liquidate_OrderBy["Transaction"] = "transaction";
    Liquidate_OrderBy["User"] = "user";
})(Liquidate_OrderBy || (Liquidate_OrderBy = {}));
export var LiquidityHistoryItem_OrderBy;
(function (LiquidityHistoryItem_OrderBy) {
    LiquidityHistoryItem_OrderBy["Amount"] = "amount";
    LiquidityHistoryItem_OrderBy["EmittedBy"] = "emittedBy";
    LiquidityHistoryItem_OrderBy["Id"] = "id";
    LiquidityHistoryItem_OrderBy["LiquidityPool"] = "liquidityPool";
    LiquidityHistoryItem_OrderBy["NewBalance"] = "newBalance";
    LiquidityHistoryItem_OrderBy["NewSupply"] = "newSupply";
    LiquidityHistoryItem_OrderBy["Provider"] = "provider";
    LiquidityHistoryItem_OrderBy["ReserveToken"] = "reserveToken";
    LiquidityHistoryItem_OrderBy["Timestamp"] = "timestamp";
    LiquidityHistoryItem_OrderBy["Transaction"] = "transaction";
    LiquidityHistoryItem_OrderBy["Type"] = "type";
    LiquidityHistoryItem_OrderBy["User"] = "user";
    LiquidityHistoryItem_OrderBy["UserLiquidityHistory"] = "userLiquidityHistory";
})(LiquidityHistoryItem_OrderBy || (LiquidityHistoryItem_OrderBy = {}));
export var LiquidityHistoryType;
(function (LiquidityHistoryType) {
    LiquidityHistoryType["Added"] = "Added";
    LiquidityHistoryType["Removed"] = "Removed";
})(LiquidityHistoryType || (LiquidityHistoryType = {}));
export var LiquidityMiningAllocationPoint_OrderBy;
(function (LiquidityMiningAllocationPoint_OrderBy) {
    LiquidityMiningAllocationPoint_OrderBy["AllocationPoint"] = "allocationPoint";
    LiquidityMiningAllocationPoint_OrderBy["AmmPoolToken"] = "ammPoolToken";
    LiquidityMiningAllocationPoint_OrderBy["Id"] = "id";
    LiquidityMiningAllocationPoint_OrderBy["LendingPoolToken"] = "lendingPoolToken";
    LiquidityMiningAllocationPoint_OrderBy["PoolTokenAddedBlock"] = "poolTokenAddedBlock";
    LiquidityMiningAllocationPoint_OrderBy["PoolTokenAddedTimestamp"] = "poolTokenAddedTimestamp";
    LiquidityMiningAllocationPoint_OrderBy["PoolTokenUpdatedBlock"] = "poolTokenUpdatedBlock";
    LiquidityMiningAllocationPoint_OrderBy["PoolTokenUpdatedTimestamp"] = "poolTokenUpdatedTimestamp";
    LiquidityMiningAllocationPoint_OrderBy["RewardPerBlock"] = "rewardPerBlock";
})(LiquidityMiningAllocationPoint_OrderBy || (LiquidityMiningAllocationPoint_OrderBy = {}));
export var LiquidityMiningGlobal_OrderBy;
(function (LiquidityMiningGlobal_OrderBy) {
    LiquidityMiningGlobal_OrderBy["Id"] = "id";
    LiquidityMiningGlobal_OrderBy["TotalAllocationPoint"] = "totalAllocationPoint";
    LiquidityMiningGlobal_OrderBy["TotalRewardPerBlock"] = "totalRewardPerBlock";
})(LiquidityMiningGlobal_OrderBy || (LiquidityMiningGlobal_OrderBy = {}));
export var LiquidityPoolToken_OrderBy;
(function (LiquidityPoolToken_OrderBy) {
    LiquidityPoolToken_OrderBy["Id"] = "id";
    LiquidityPoolToken_OrderBy["LiquidityPool"] = "liquidityPool";
    LiquidityPoolToken_OrderBy["PoolToken"] = "poolToken";
    LiquidityPoolToken_OrderBy["Token"] = "token";
    LiquidityPoolToken_OrderBy["TotalVolume"] = "totalVolume";
    LiquidityPoolToken_OrderBy["VolumeBought"] = "volumeBought";
    LiquidityPoolToken_OrderBy["VolumeSold"] = "volumeSold";
})(LiquidityPoolToken_OrderBy || (LiquidityPoolToken_OrderBy = {}));
export var LiquidityPool_OrderBy;
(function (LiquidityPool_OrderBy) {
    LiquidityPool_OrderBy["Activated"] = "activated";
    LiquidityPool_OrderBy["ConnectorTokens"] = "connectorTokens";
    LiquidityPool_OrderBy["ConversionFee"] = "conversionFee";
    LiquidityPool_OrderBy["Conversions"] = "conversions";
    LiquidityPool_OrderBy["CreatedAtBlockNumber"] = "createdAtBlockNumber";
    LiquidityPool_OrderBy["CreatedAtTimestamp"] = "createdAtTimestamp";
    LiquidityPool_OrderBy["CreatedAtTransaction"] = "createdAtTransaction";
    LiquidityPool_OrderBy["CurrentConverterRegistry"] = "currentConverterRegistry";
    LiquidityPool_OrderBy["Id"] = "id";
    LiquidityPool_OrderBy["MaxConversionFee"] = "maxConversionFee";
    LiquidityPool_OrderBy["Owner"] = "owner";
    LiquidityPool_OrderBy["PoolTokens"] = "poolTokens";
    LiquidityPool_OrderBy["SmartToken"] = "smartToken";
    LiquidityPool_OrderBy["Token0"] = "token0";
    LiquidityPool_OrderBy["Token0Balance"] = "token0Balance";
    LiquidityPool_OrderBy["Token1"] = "token1";
    LiquidityPool_OrderBy["Token1Balance"] = "token1Balance";
    LiquidityPool_OrderBy["Type"] = "type";
    LiquidityPool_OrderBy["Version"] = "version";
})(LiquidityPool_OrderBy || (LiquidityPool_OrderBy = {}));
export var LoanParamsSetup_OrderBy;
(function (LoanParamsSetup_OrderBy) {
    LoanParamsSetup_OrderBy["CollateralToken"] = "collateralToken";
    LoanParamsSetup_OrderBy["Id"] = "id";
    LoanParamsSetup_OrderBy["LoanToken"] = "loanToken";
    LoanParamsSetup_OrderBy["MaintenanceMargin"] = "maintenanceMargin";
    LoanParamsSetup_OrderBy["MaxLoanTerm"] = "maxLoanTerm";
    LoanParamsSetup_OrderBy["MinInitialMargin"] = "minInitialMargin";
    LoanParamsSetup_OrderBy["Owner"] = "owner";
    LoanParamsSetup_OrderBy["Timestamp"] = "timestamp";
    LoanParamsSetup_OrderBy["Transaction"] = "transaction";
})(LoanParamsSetup_OrderBy || (LoanParamsSetup_OrderBy = {}));
export var LoanType;
(function (LoanType) {
    /**
     * Borrow is a loan originating from a user simply borrowing funds
     *
     */
    LoanType["Borrow"] = "Borrow";
    /**
     * Trade is a loan originating from a margin trade
     *
     */
    LoanType["Trade"] = "Trade";
})(LoanType || (LoanType = {}));
export var Loan_OrderBy;
(function (Loan_OrderBy) {
    Loan_OrderBy["AverageBuyPrice"] = "averageBuyPrice";
    Loan_OrderBy["AverageSellPrice"] = "averageSellPrice";
    Loan_OrderBy["Borrow"] = "borrow";
    Loan_OrderBy["BorrowedAmount"] = "borrowedAmount";
    Loan_OrderBy["CloseWithDeposits"] = "closeWithDeposits";
    Loan_OrderBy["CloseWithSwaps"] = "closeWithSwaps";
    Loan_OrderBy["CollateralToken"] = "collateralToken";
    Loan_OrderBy["DepositCollateral"] = "depositCollateral";
    Loan_OrderBy["EndTimestamp"] = "endTimestamp";
    Loan_OrderBy["Id"] = "id";
    Loan_OrderBy["IsOpen"] = "isOpen";
    Loan_OrderBy["Liquidates"] = "liquidates";
    Loan_OrderBy["LoanToken"] = "loanToken";
    Loan_OrderBy["MaxBorrowedAmount"] = "maxBorrowedAmount";
    Loan_OrderBy["MaximumPositionSize"] = "maximumPositionSize";
    Loan_OrderBy["NextRollover"] = "nextRollover";
    Loan_OrderBy["PositionSize"] = "positionSize";
    Loan_OrderBy["RealizedPnL"] = "realizedPnL";
    Loan_OrderBy["RealizedPnLPercent"] = "realizedPnLPercent";
    Loan_OrderBy["Rollovers"] = "rollovers";
    Loan_OrderBy["StartBorrowedAmount"] = "startBorrowedAmount";
    Loan_OrderBy["StartPositionSize"] = "startPositionSize";
    Loan_OrderBy["StartRate"] = "startRate";
    Loan_OrderBy["StartTimestamp"] = "startTimestamp";
    Loan_OrderBy["TotalBought"] = "totalBought";
    Loan_OrderBy["TotalSold"] = "totalSold";
    Loan_OrderBy["Trade"] = "trade";
    Loan_OrderBy["Type"] = "type";
    Loan_OrderBy["User"] = "user";
})(Loan_OrderBy || (Loan_OrderBy = {}));
export var MarginOrderCanceled_OrderBy;
(function (MarginOrderCanceled_OrderBy) {
    MarginOrderCanceled_OrderBy["EmittedBy"] = "emittedBy";
    MarginOrderCanceled_OrderBy["Hash"] = "hash";
    MarginOrderCanceled_OrderBy["Id"] = "id";
    MarginOrderCanceled_OrderBy["Timestamp"] = "timestamp";
    MarginOrderCanceled_OrderBy["Trader"] = "trader";
    MarginOrderCanceled_OrderBy["Transaction"] = "transaction";
})(MarginOrderCanceled_OrderBy || (MarginOrderCanceled_OrderBy = {}));
export var MarginOrderFilled_OrderBy;
(function (MarginOrderFilled_OrderBy) {
    MarginOrderFilled_OrderBy["Collateral"] = "collateral";
    MarginOrderFilled_OrderBy["CollateralTokenAddress"] = "collateralTokenAddress";
    MarginOrderFilled_OrderBy["CollateralTokenSent"] = "collateralTokenSent";
    MarginOrderFilled_OrderBy["EmittedBy"] = "emittedBy";
    MarginOrderFilled_OrderBy["FilledPrice"] = "filledPrice";
    MarginOrderFilled_OrderBy["Hash"] = "hash";
    MarginOrderFilled_OrderBy["Id"] = "id";
    MarginOrderFilled_OrderBy["LeverageAmount"] = "leverageAmount";
    MarginOrderFilled_OrderBy["LoanTokenAddress"] = "loanTokenAddress";
    MarginOrderFilled_OrderBy["LoanTokenSent"] = "loanTokenSent";
    MarginOrderFilled_OrderBy["Principal"] = "principal";
    MarginOrderFilled_OrderBy["Timestamp"] = "timestamp";
    MarginOrderFilled_OrderBy["Trader"] = "trader";
    MarginOrderFilled_OrderBy["Transaction"] = "transaction";
})(MarginOrderFilled_OrderBy || (MarginOrderFilled_OrderBy = {}));
export var MultisigConfirmation_OrderBy;
(function (MultisigConfirmation_OrderBy) {
    MultisigConfirmation_OrderBy["Id"] = "id";
    MultisigConfirmation_OrderBy["MultisigTransaction"] = "multisigTransaction";
    MultisigConfirmation_OrderBy["Signer"] = "signer";
    MultisigConfirmation_OrderBy["Timestamp"] = "timestamp";
    MultisigConfirmation_OrderBy["Transaction"] = "transaction";
})(MultisigConfirmation_OrderBy || (MultisigConfirmation_OrderBy = {}));
export var MultisigContract_OrderBy;
(function (MultisigContract_OrderBy) {
    MultisigContract_OrderBy["Id"] = "id";
    MultisigContract_OrderBy["Owners"] = "owners";
    MultisigContract_OrderBy["Required"] = "required";
    MultisigContract_OrderBy["Timestamp"] = "timestamp";
    MultisigContract_OrderBy["Transaction"] = "transaction";
    MultisigContract_OrderBy["Transactions"] = "transactions";
})(MultisigContract_OrderBy || (MultisigContract_OrderBy = {}));
export var MultisigTransactionStatus;
(function (MultisigTransactionStatus) {
    MultisigTransactionStatus["Executed"] = "EXECUTED";
    MultisigTransactionStatus["Failed"] = "FAILED";
    MultisigTransactionStatus["Submitted"] = "SUBMITTED";
})(MultisigTransactionStatus || (MultisigTransactionStatus = {}));
export var MultisigTransaction_OrderBy;
(function (MultisigTransaction_OrderBy) {
    MultisigTransaction_OrderBy["Confirmations"] = "confirmations";
    MultisigTransaction_OrderBy["Data"] = "data";
    MultisigTransaction_OrderBy["Destination"] = "destination";
    MultisigTransaction_OrderBy["Id"] = "id";
    MultisigTransaction_OrderBy["MultisigContract"] = "multisigContract";
    MultisigTransaction_OrderBy["Status"] = "status";
    MultisigTransaction_OrderBy["Submitter"] = "submitter";
    MultisigTransaction_OrderBy["Timestamp"] = "timestamp";
    MultisigTransaction_OrderBy["Transaction"] = "transaction";
    MultisigTransaction_OrderBy["TransactionId"] = "transactionId";
    MultisigTransaction_OrderBy["Value"] = "value";
})(MultisigTransaction_OrderBy || (MultisigTransaction_OrderBy = {}));
export var Network;
(function (Network) {
    Network["Mainnet"] = "Mainnet";
    Network["Testnet"] = "Testnet";
})(Network || (Network = {}));
export var NewBitcoinTransferIncoming_OrderBy;
(function (NewBitcoinTransferIncoming_OrderBy) {
    NewBitcoinTransferIncoming_OrderBy["AmountWei"] = "amountWei";
    NewBitcoinTransferIncoming_OrderBy["BlockNumber"] = "blockNumber";
    NewBitcoinTransferIncoming_OrderBy["BlockTimestamp"] = "blockTimestamp";
    NewBitcoinTransferIncoming_OrderBy["BtcTxHash"] = "btcTxHash";
    NewBitcoinTransferIncoming_OrderBy["BtcTxVout"] = "btcTxVout";
    NewBitcoinTransferIncoming_OrderBy["EmittedBy"] = "emittedBy";
    NewBitcoinTransferIncoming_OrderBy["FeeWei"] = "feeWei";
    NewBitcoinTransferIncoming_OrderBy["Id"] = "id";
    NewBitcoinTransferIncoming_OrderBy["RskAddress"] = "rskAddress";
    NewBitcoinTransferIncoming_OrderBy["Timestamp"] = "timestamp";
    NewBitcoinTransferIncoming_OrderBy["Transaction"] = "transaction";
    NewBitcoinTransferIncoming_OrderBy["TransactionHash"] = "transactionHash";
})(NewBitcoinTransferIncoming_OrderBy || (NewBitcoinTransferIncoming_OrderBy = {}));
export var OrderCanceled_OrderBy;
(function (OrderCanceled_OrderBy) {
    OrderCanceled_OrderBy["EmittedBy"] = "emittedBy";
    OrderCanceled_OrderBy["Hash"] = "hash";
    OrderCanceled_OrderBy["Id"] = "id";
    OrderCanceled_OrderBy["Maker"] = "maker";
    OrderCanceled_OrderBy["Timestamp"] = "timestamp";
    OrderCanceled_OrderBy["Transaction"] = "transaction";
})(OrderCanceled_OrderBy || (OrderCanceled_OrderBy = {}));
export var OrderCreated_OrderBy;
(function (OrderCreated_OrderBy) {
    OrderCreated_OrderBy["EmittedBy"] = "emittedBy";
    OrderCreated_OrderBy["Hash"] = "hash";
    OrderCreated_OrderBy["Id"] = "id";
    OrderCreated_OrderBy["LimitPrice"] = "limitPrice";
    OrderCreated_OrderBy["Network"] = "network";
    OrderCreated_OrderBy["OrderAmountIn"] = "order_amountIn";
    OrderCreated_OrderBy["OrderAmountOutMin"] = "order_amountOutMin";
    OrderCreated_OrderBy["OrderCreated"] = "order_created";
    OrderCreated_OrderBy["OrderDeadline"] = "order_deadline";
    OrderCreated_OrderBy["OrderFromToken"] = "order_fromToken";
    OrderCreated_OrderBy["OrderMaker"] = "order_maker";
    OrderCreated_OrderBy["OrderRecipient"] = "order_recipient";
    OrderCreated_OrderBy["OrderToToken"] = "order_toToken";
    OrderCreated_OrderBy["Timestamp"] = "timestamp";
    OrderCreated_OrderBy["Transaction"] = "transaction";
})(OrderCreated_OrderBy || (OrderCreated_OrderBy = {}));
/** Defines the order direction, either ascending or descending */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export var OrderFilled_OrderBy;
(function (OrderFilled_OrderBy) {
    OrderFilled_OrderBy["AmountIn"] = "amountIn";
    OrderFilled_OrderBy["AmountOut"] = "amountOut";
    OrderFilled_OrderBy["EmittedBy"] = "emittedBy";
    OrderFilled_OrderBy["FilledPrice"] = "filledPrice";
    OrderFilled_OrderBy["Hash"] = "hash";
    OrderFilled_OrderBy["Id"] = "id";
    OrderFilled_OrderBy["Maker"] = "maker";
    OrderFilled_OrderBy["Path"] = "path";
    OrderFilled_OrderBy["Timestamp"] = "timestamp";
    OrderFilled_OrderBy["Transaction"] = "transaction";
})(OrderFilled_OrderBy || (OrderFilled_OrderBy = {}));
export var PayBorrowingFee_OrderBy;
(function (PayBorrowingFee_OrderBy) {
    PayBorrowingFee_OrderBy["Amount"] = "amount";
    PayBorrowingFee_OrderBy["EmittedBy"] = "emittedBy";
    PayBorrowingFee_OrderBy["Id"] = "id";
    PayBorrowingFee_OrderBy["LoanId"] = "loanId";
    PayBorrowingFee_OrderBy["Payer"] = "payer";
    PayBorrowingFee_OrderBy["Timestamp"] = "timestamp";
    PayBorrowingFee_OrderBy["Token"] = "token";
    PayBorrowingFee_OrderBy["Transaction"] = "transaction";
})(PayBorrowingFee_OrderBy || (PayBorrowingFee_OrderBy = {}));
export var PayInterestTransfer_OrderBy;
(function (PayInterestTransfer_OrderBy) {
    PayInterestTransfer_OrderBy["EffectiveInterest"] = "effectiveInterest";
    PayInterestTransfer_OrderBy["EmittedBy"] = "emittedBy";
    PayInterestTransfer_OrderBy["Id"] = "id";
    PayInterestTransfer_OrderBy["InterestToken"] = "interestToken";
    PayInterestTransfer_OrderBy["Lender"] = "lender";
    PayInterestTransfer_OrderBy["Timestamp"] = "timestamp";
    PayInterestTransfer_OrderBy["Transaction"] = "transaction";
})(PayInterestTransfer_OrderBy || (PayInterestTransfer_OrderBy = {}));
export var PayLendingFee_OrderBy;
(function (PayLendingFee_OrderBy) {
    PayLendingFee_OrderBy["Amount"] = "amount";
    PayLendingFee_OrderBy["EmittedBy"] = "emittedBy";
    PayLendingFee_OrderBy["Id"] = "id";
    PayLendingFee_OrderBy["Payer"] = "payer";
    PayLendingFee_OrderBy["Timestamp"] = "timestamp";
    PayLendingFee_OrderBy["Token"] = "token";
    PayLendingFee_OrderBy["Transaction"] = "transaction";
})(PayLendingFee_OrderBy || (PayLendingFee_OrderBy = {}));
export var PayTradingFee_OrderBy;
(function (PayTradingFee_OrderBy) {
    PayTradingFee_OrderBy["Amount"] = "amount";
    PayTradingFee_OrderBy["EmittedBy"] = "emittedBy";
    PayTradingFee_OrderBy["Id"] = "id";
    PayTradingFee_OrderBy["LoanId"] = "loanId";
    PayTradingFee_OrderBy["Payer"] = "payer";
    PayTradingFee_OrderBy["Timestamp"] = "timestamp";
    PayTradingFee_OrderBy["Token"] = "token";
    PayTradingFee_OrderBy["Transaction"] = "transaction";
})(PayTradingFee_OrderBy || (PayTradingFee_OrderBy = {}));
export var PoolToken_OrderBy;
(function (PoolToken_OrderBy) {
    PoolToken_OrderBy["Decimals"] = "decimals";
    PoolToken_OrderBy["Id"] = "id";
    PoolToken_OrderBy["LiquidityPool"] = "liquidityPool";
    PoolToken_OrderBy["Name"] = "name";
    PoolToken_OrderBy["Symbol"] = "symbol";
    PoolToken_OrderBy["UnderlyingAssets"] = "underlyingAssets";
})(PoolToken_OrderBy || (PoolToken_OrderBy = {}));
export var PoolVolumeItem_OrderBy;
(function (PoolVolumeItem_OrderBy) {
    PoolVolumeItem_OrderBy["BtcAmount"] = "btcAmount";
    PoolVolumeItem_OrderBy["Conversion"] = "conversion";
    PoolVolumeItem_OrderBy["Id"] = "id";
    PoolVolumeItem_OrderBy["Pool"] = "pool";
    PoolVolumeItem_OrderBy["Timestamp"] = "timestamp";
    PoolVolumeItem_OrderBy["Transaction"] = "transaction";
})(PoolVolumeItem_OrderBy || (PoolVolumeItem_OrderBy = {}));
export var ProposalState;
(function (ProposalState) {
    ProposalState["Canceled"] = "Canceled";
    ProposalState["Created"] = "Created";
    ProposalState["Executed"] = "Executed";
    ProposalState["Queued"] = "Queued";
})(ProposalState || (ProposalState = {}));
export var ProposalStateChange_OrderBy;
(function (ProposalStateChange_OrderBy) {
    ProposalStateChange_OrderBy["EmittedBy"] = "emittedBy";
    ProposalStateChange_OrderBy["Id"] = "id";
    ProposalStateChange_OrderBy["Proposal"] = "proposal";
    ProposalStateChange_OrderBy["State"] = "state";
    ProposalStateChange_OrderBy["Timestamp"] = "timestamp";
    ProposalStateChange_OrderBy["Transaction"] = "transaction";
})(ProposalStateChange_OrderBy || (ProposalStateChange_OrderBy = {}));
export var Proposal_OrderBy;
(function (Proposal_OrderBy) {
    Proposal_OrderBy["Calldatas"] = "calldatas";
    Proposal_OrderBy["Canceled"] = "canceled";
    Proposal_OrderBy["CountVotersAgainst"] = "countVotersAgainst";
    Proposal_OrderBy["CountVotersFor"] = "countVotersFor";
    Proposal_OrderBy["Created"] = "created";
    Proposal_OrderBy["Description"] = "description";
    Proposal_OrderBy["EmittedBy"] = "emittedBy";
    Proposal_OrderBy["EndBlock"] = "endBlock";
    Proposal_OrderBy["Eta"] = "eta";
    Proposal_OrderBy["Executed"] = "executed";
    Proposal_OrderBy["Id"] = "id";
    Proposal_OrderBy["MajorityPercentage"] = "majorityPercentage";
    Proposal_OrderBy["ProposalId"] = "proposalId";
    Proposal_OrderBy["Proposer"] = "proposer";
    Proposal_OrderBy["Queued"] = "queued";
    Proposal_OrderBy["Quorum"] = "quorum";
    Proposal_OrderBy["Signatures"] = "signatures";
    Proposal_OrderBy["StartBlock"] = "startBlock";
    Proposal_OrderBy["StateChanges"] = "stateChanges";
    Proposal_OrderBy["Targets"] = "targets";
    Proposal_OrderBy["Timestamp"] = "timestamp";
    Proposal_OrderBy["Values"] = "values";
    Proposal_OrderBy["Votes"] = "votes";
    Proposal_OrderBy["VotesAgainst"] = "votesAgainst";
    Proposal_OrderBy["VotesFor"] = "votesFor";
})(Proposal_OrderBy || (Proposal_OrderBy = {}));
export var ProtocolStats_OrderBy;
(function (ProtocolStats_OrderBy) {
    ProtocolStats_OrderBy["BtcUsdPrice"] = "btcUsdPrice";
    ProtocolStats_OrderBy["CurrentStakedByVestingSov"] = "currentStakedByVestingSov";
    ProtocolStats_OrderBy["CurrentVoluntarilyStakedSov"] = "currentVoluntarilyStakedSov";
    ProtocolStats_OrderBy["Id"] = "id";
    ProtocolStats_OrderBy["Tokens"] = "tokens";
    ProtocolStats_OrderBy["TotalAmmLpFeesUsd"] = "totalAmmLpFeesUsd";
    ProtocolStats_OrderBy["TotalAmmStakerFeesUsd"] = "totalAmmStakerFeesUsd";
    ProtocolStats_OrderBy["TotalAmmVolumeUsd"] = "totalAmmVolumeUsd";
    ProtocolStats_OrderBy["TotalBorrowVolumeUsd"] = "totalBorrowVolumeUsd";
    ProtocolStats_OrderBy["TotalBorrowingFeesUsd"] = "totalBorrowingFeesUsd";
    ProtocolStats_OrderBy["TotalCloseWithDepositVolumeUsd"] = "totalCloseWithDepositVolumeUsd";
    ProtocolStats_OrderBy["TotalCloseWithSwapVolumeUsd"] = "totalCloseWithSwapVolumeUsd";
    ProtocolStats_OrderBy["TotalDepositCollateralVolumeUsd"] = "totalDepositCollateralVolumeUsd";
    ProtocolStats_OrderBy["TotalLendVolumeUsd"] = "totalLendVolumeUsd";
    ProtocolStats_OrderBy["TotalLendingFeesUsd"] = "totalLendingFeesUsd";
    ProtocolStats_OrderBy["TotalLiquidateVolumeUsd"] = "totalLiquidateVolumeUsd";
    ProtocolStats_OrderBy["TotalMarginTradeVolumeUsd"] = "totalMarginTradeVolumeUsd";
    ProtocolStats_OrderBy["TotalTradingFeesUsd"] = "totalTradingFeesUsd";
    ProtocolStats_OrderBy["TotalUnlendVolumeUsd"] = "totalUnlendVolumeUsd";
    ProtocolStats_OrderBy["TotalUsers"] = "totalUsers";
    ProtocolStats_OrderBy["UsdStablecoin"] = "usdStablecoin";
})(ProtocolStats_OrderBy || (ProtocolStats_OrderBy = {}));
export var ProtocolWithdrawFee_OrderBy;
(function (ProtocolWithdrawFee_OrderBy) {
    ProtocolWithdrawFee_OrderBy["Amount"] = "amount";
    ProtocolWithdrawFee_OrderBy["AmountUsd"] = "amountUsd";
    ProtocolWithdrawFee_OrderBy["EmittedBy"] = "emittedBy";
    ProtocolWithdrawFee_OrderBy["FeeType"] = "feeType";
    ProtocolWithdrawFee_OrderBy["Id"] = "id";
    ProtocolWithdrawFee_OrderBy["Timestamp"] = "timestamp";
    ProtocolWithdrawFee_OrderBy["Token"] = "token";
    ProtocolWithdrawFee_OrderBy["Transaction"] = "transaction";
})(ProtocolWithdrawFee_OrderBy || (ProtocolWithdrawFee_OrderBy = {}));
export var RewardsEarnedAction;
(function (RewardsEarnedAction) {
    /**
     * SOV reward earned when a user margin trades or one of their trades is rolled over.
     * The reward is a percentage of the trading fee, paid in SOV.
     *
     */
    RewardsEarnedAction["EarnReward"] = "EarnReward";
    /**
     * When a user claims a liquidity mining reward
     *
     */
    RewardsEarnedAction["RewardClaimed"] = "RewardClaimed";
    /**
     * When SOV is staked by a Rewards vesting contract (eg after it has been claimed by the user)
     *
     */
    RewardsEarnedAction["RewardSovStaked"] = "RewardSovStaked";
    /**
     * When a SOV Staker withdraws their liquid SOV reward for staking
     *
     */
    RewardsEarnedAction["StakingRewardWithdrawn"] = "StakingRewardWithdrawn";
    /**
     * When an SOV Staker withdraws their share of the fees earned by the Protocol
     *
     */
    RewardsEarnedAction["UserFeeWithdrawn"] = "UserFeeWithdrawn";
})(RewardsEarnedAction || (RewardsEarnedAction = {}));
export var RewardsEarnedHistoryItem_OrderBy;
(function (RewardsEarnedHistoryItem_OrderBy) {
    RewardsEarnedHistoryItem_OrderBy["Action"] = "action";
    RewardsEarnedHistoryItem_OrderBy["Amount"] = "amount";
    RewardsEarnedHistoryItem_OrderBy["Id"] = "id";
    RewardsEarnedHistoryItem_OrderBy["Timestamp"] = "timestamp";
    RewardsEarnedHistoryItem_OrderBy["Token"] = "token";
    RewardsEarnedHistoryItem_OrderBy["Transaction"] = "transaction";
    RewardsEarnedHistoryItem_OrderBy["User"] = "user";
})(RewardsEarnedHistoryItem_OrderBy || (RewardsEarnedHistoryItem_OrderBy = {}));
export var Rollover_OrderBy;
(function (Rollover_OrderBy) {
    Rollover_OrderBy["Collateral"] = "collateral";
    Rollover_OrderBy["EmittedBy"] = "emittedBy";
    Rollover_OrderBy["EndTimestamp"] = "endTimestamp";
    Rollover_OrderBy["Id"] = "id";
    Rollover_OrderBy["Lender"] = "lender";
    Rollover_OrderBy["LoanId"] = "loanId";
    Rollover_OrderBy["Principal"] = "principal";
    Rollover_OrderBy["Reward"] = "reward";
    Rollover_OrderBy["RewardReceiver"] = "rewardReceiver";
    Rollover_OrderBy["Timestamp"] = "timestamp";
    Rollover_OrderBy["Transaction"] = "transaction";
    Rollover_OrderBy["User"] = "user";
})(Rollover_OrderBy || (Rollover_OrderBy = {}));
export var SideToken_OrderBy;
(function (SideToken_OrderBy) {
    SideToken_OrderBy["CreatedAtTx"] = "createdAtTx";
    SideToken_OrderBy["Granularity"] = "granularity";
    SideToken_OrderBy["Id"] = "id";
    SideToken_OrderBy["NewSymbol"] = "newSymbol";
    SideToken_OrderBy["OriginalTokenAddress"] = "originalTokenAddress";
    SideToken_OrderBy["SideTokenAddress"] = "sideTokenAddress";
    SideToken_OrderBy["UpdatedAtTx"] = "updatedAtTx";
})(SideToken_OrderBy || (SideToken_OrderBy = {}));
export var SmartToken_OrderBy;
(function (SmartToken_OrderBy) {
    SmartToken_OrderBy["AddedToRegistryBlockNumber"] = "addedToRegistryBlockNumber";
    SmartToken_OrderBy["AddedToRegistryTransactionHash"] = "addedToRegistryTransactionHash";
    SmartToken_OrderBy["ConnectorTokens"] = "connectorTokens";
    SmartToken_OrderBy["CurrentConverterRegistry"] = "currentConverterRegistry";
    SmartToken_OrderBy["Decimals"] = "decimals";
    SmartToken_OrderBy["Id"] = "id";
    SmartToken_OrderBy["LiquidityPool"] = "liquidityPool";
    SmartToken_OrderBy["Name"] = "name";
    SmartToken_OrderBy["Owner"] = "owner";
    SmartToken_OrderBy["SmartTokenType"] = "smartTokenType";
    SmartToken_OrderBy["Symbol"] = "symbol";
})(SmartToken_OrderBy || (SmartToken_OrderBy = {}));
export var StakeHistoryAction;
(function (StakeHistoryAction) {
    /**
     * When a user delegates voting power to another user. This can also be for voting power that the user has through a vesting contract.
     *
     */
    StakeHistoryAction["Delegate"] = "Delegate";
    StakeHistoryAction["DelegateVested"] = "DelegateVested";
    /**
     * When a user make an early unstake and the staked amount is slashed
     *
     */
    StakeHistoryAction["EarlyUnstakingPenalty"] = "EarlyUnstakingPenalty";
    /**
     * Extending an existing stake. The amount of the stake remains the same, but the lockedUntil date increases.
     *
     */
    StakeHistoryAction["ExtendStake"] = "ExtendStake";
    /**
     * When a user withdraws their share of the Protocol fees that is shared amongst stakers
     *
     */
    StakeHistoryAction["FeeWithdrawn"] = "FeeWithdrawn";
    /**
     * Increasing the amount of an existing stake. The lockedUntil date of this stake remains the same, but amount increases.
     *
     */
    StakeHistoryAction["IncreaseStake"] = "IncreaseStake";
    /**
     * Voluntarily staking SOV (ie not staked through a vesting contract)
     *
     */
    StakeHistoryAction["Stake"] = "Stake";
    /**
     * Unstake is early unstaking, when a user withdraws staked SOV before the lockedUntil date and incurs a slashing penalty.
     *
     */
    StakeHistoryAction["Unstake"] = "Unstake";
    /**
     * WithdrawStaked is when a user withdraws SOV from the staking contract after the unlock date, when the funds are no longer staked or locked
     *
     */
    StakeHistoryAction["WithdrawStaked"] = "WithdrawStaked";
})(StakeHistoryAction || (StakeHistoryAction = {}));
export var StakeHistoryItem_OrderBy;
(function (StakeHistoryItem_OrderBy) {
    StakeHistoryItem_OrderBy["Action"] = "action";
    StakeHistoryItem_OrderBy["Amount"] = "amount";
    StakeHistoryItem_OrderBy["Delegatee"] = "delegatee";
    StakeHistoryItem_OrderBy["EmittedBy"] = "emittedBy";
    StakeHistoryItem_OrderBy["Id"] = "id";
    StakeHistoryItem_OrderBy["LockedUntil"] = "lockedUntil";
    StakeHistoryItem_OrderBy["Timestamp"] = "timestamp";
    StakeHistoryItem_OrderBy["Token"] = "token";
    StakeHistoryItem_OrderBy["Transaction"] = "transaction";
    StakeHistoryItem_OrderBy["User"] = "user";
})(StakeHistoryItem_OrderBy || (StakeHistoryItem_OrderBy = {}));
export var Stake_OrderBy;
(function (Stake_OrderBy) {
    Stake_OrderBy["Amount"] = "amount";
    Stake_OrderBy["DelegatedAmount"] = "delegatedAmount";
    Stake_OrderBy["Id"] = "id";
    Stake_OrderBy["LockedUntil"] = "lockedUntil";
    Stake_OrderBy["User"] = "user";
    Stake_OrderBy["VestingAmount"] = "vestingAmount";
})(Stake_OrderBy || (Stake_OrderBy = {}));
export var SwapSetting_OrderBy;
(function (SwapSetting_OrderBy) {
    SwapSetting_OrderBy["Id"] = "id";
    SwapSetting_OrderBy["ProtocolFee"] = "protocolFee";
    SwapSetting_OrderBy["Timestamp"] = "timestamp";
})(SwapSetting_OrderBy || (SwapSetting_OrderBy = {}));
export var SwapType;
(function (SwapType) {
    SwapType["Limit"] = "Limit";
    SwapType["Market"] = "Market";
    SwapType["Other"] = "Other";
})(SwapType || (SwapType = {}));
export var Swap_OrderBy;
(function (Swap_OrderBy) {
    Swap_OrderBy["ConversionFee"] = "conversionFee";
    Swap_OrderBy["FromAmount"] = "fromAmount";
    Swap_OrderBy["FromToken"] = "fromToken";
    Swap_OrderBy["Id"] = "id";
    Swap_OrderBy["IsLimit"] = "isLimit";
    Swap_OrderBy["NumConversions"] = "numConversions";
    Swap_OrderBy["ProtocolFee"] = "protocolFee";
    Swap_OrderBy["Rate"] = "rate";
    Swap_OrderBy["SwapType"] = "swapType";
    Swap_OrderBy["Timestamp"] = "timestamp";
    Swap_OrderBy["ToAmount"] = "toAmount";
    Swap_OrderBy["ToToken"] = "toToken";
    Swap_OrderBy["Transaction"] = "transaction";
    Swap_OrderBy["User"] = "user";
})(Swap_OrderBy || (Swap_OrderBy = {}));
export var TokenSmartToken_OrderBy;
(function (TokenSmartToken_OrderBy) {
    TokenSmartToken_OrderBy["Id"] = "id";
    TokenSmartToken_OrderBy["SmartToken"] = "smartToken";
    TokenSmartToken_OrderBy["Token"] = "token";
})(TokenSmartToken_OrderBy || (TokenSmartToken_OrderBy = {}));
export var Token_OrderBy;
(function (Token_OrderBy) {
    Token_OrderBy["BtcVolume"] = "btcVolume";
    Token_OrderBy["CrossTransfers"] = "crossTransfers";
    Token_OrderBy["CurrentConverterRegistry"] = "currentConverterRegistry";
    Token_OrderBy["Decimals"] = "decimals";
    Token_OrderBy["HasBtcPool"] = "hasBtcPool";
    Token_OrderBy["HasStablecoinPool"] = "hasStablecoinPool";
    Token_OrderBy["Id"] = "id";
    Token_OrderBy["LastPriceBtc"] = "lastPriceBtc";
    Token_OrderBy["LastPriceUsd"] = "lastPriceUsd";
    Token_OrderBy["LiquidityPools"] = "liquidityPools";
    Token_OrderBy["Name"] = "name";
    Token_OrderBy["PrevPriceBtc"] = "prevPriceBtc";
    Token_OrderBy["PrevPriceUsd"] = "prevPriceUsd";
    Token_OrderBy["SmartTokens"] = "smartTokens";
    Token_OrderBy["Symbol"] = "symbol";
    Token_OrderBy["TokenVolume"] = "tokenVolume";
    Token_OrderBy["UsdVolume"] = "usdVolume";
    Token_OrderBy["Version"] = "version";
})(Token_OrderBy || (Token_OrderBy = {}));
export var TokensStaked_OrderBy;
(function (TokensStaked_OrderBy) {
    TokensStaked_OrderBy["Amount"] = "amount";
    TokensStaked_OrderBy["EmittedBy"] = "emittedBy";
    TokensStaked_OrderBy["Id"] = "id";
    TokensStaked_OrderBy["IsUserStaked"] = "isUserStaked";
    TokensStaked_OrderBy["LockedUntil"] = "lockedUntil";
    TokensStaked_OrderBy["Staker"] = "staker";
    TokensStaked_OrderBy["Timestamp"] = "timestamp";
    TokensStaked_OrderBy["TotalStaked"] = "totalStaked";
    TokensStaked_OrderBy["Transaction"] = "transaction";
    TokensStaked_OrderBy["User"] = "user";
})(TokensStaked_OrderBy || (TokensStaked_OrderBy = {}));
export var Trade_OrderBy;
(function (Trade_OrderBy) {
    Trade_OrderBy["BorrowedAmount"] = "borrowedAmount";
    Trade_OrderBy["CollateralToken"] = "collateralToken";
    Trade_OrderBy["CurrentLeverage"] = "currentLeverage";
    Trade_OrderBy["EmittedBy"] = "emittedBy";
    Trade_OrderBy["EntryLeverage"] = "entryLeverage";
    Trade_OrderBy["EntryPrice"] = "entryPrice";
    Trade_OrderBy["Id"] = "id";
    Trade_OrderBy["InterestRate"] = "interestRate";
    Trade_OrderBy["Lender"] = "lender";
    Trade_OrderBy["LoanId"] = "loanId";
    Trade_OrderBy["LoanToken"] = "loanToken";
    Trade_OrderBy["PositionSize"] = "positionSize";
    Trade_OrderBy["SettlementDate"] = "settlementDate";
    Trade_OrderBy["Timestamp"] = "timestamp";
    Trade_OrderBy["Transaction"] = "transaction";
    Trade_OrderBy["User"] = "user";
})(Trade_OrderBy || (Trade_OrderBy = {}));
export var Transaction_OrderBy;
(function (Transaction_OrderBy) {
    Transaction_OrderBy["BlockNumber"] = "blockNumber";
    Transaction_OrderBy["From"] = "from";
    Transaction_OrderBy["FunctionSignature"] = "functionSignature";
    Transaction_OrderBy["GasLimit"] = "gasLimit";
    Transaction_OrderBy["GasPrice"] = "gasPrice";
    Transaction_OrderBy["Id"] = "id";
    Transaction_OrderBy["Index"] = "index";
    Transaction_OrderBy["Timestamp"] = "timestamp";
    Transaction_OrderBy["To"] = "to";
    Transaction_OrderBy["Value"] = "value";
})(Transaction_OrderBy || (Transaction_OrderBy = {}));
export var UserLendingHistory_OrderBy;
(function (UserLendingHistory_OrderBy) {
    UserLendingHistory_OrderBy["Id"] = "id";
    UserLendingHistory_OrderBy["LendingHistory"] = "lendingHistory";
    UserLendingHistory_OrderBy["LendingPool"] = "lendingPool";
    UserLendingHistory_OrderBy["TotalLendVolume"] = "totalLendVolume";
    UserLendingHistory_OrderBy["TotalUnlendVolume"] = "totalUnlendVolume";
    UserLendingHistory_OrderBy["User"] = "user";
})(UserLendingHistory_OrderBy || (UserLendingHistory_OrderBy = {}));
export var UserLiquidityHistory_OrderBy;
(function (UserLiquidityHistory_OrderBy) {
    UserLiquidityHistory_OrderBy["Id"] = "id";
    UserLiquidityHistory_OrderBy["LiquidityHistory"] = "liquidityHistory";
    UserLiquidityHistory_OrderBy["PoolToken"] = "poolToken";
    UserLiquidityHistory_OrderBy["TotalAsset0LiquidityAdded"] = "totalAsset0LiquidityAdded";
    UserLiquidityHistory_OrderBy["TotalAsset0LiquidityRemoved"] = "totalAsset0LiquidityRemoved";
    UserLiquidityHistory_OrderBy["TotalAsset1LiquidityAdded"] = "totalAsset1LiquidityAdded";
    UserLiquidityHistory_OrderBy["TotalAsset1LiquidityRemoved"] = "totalAsset1LiquidityRemoved";
    UserLiquidityHistory_OrderBy["User"] = "user";
})(UserLiquidityHistory_OrderBy || (UserLiquidityHistory_OrderBy = {}));
export var UserRewardsEarnedHistory_OrderBy;
(function (UserRewardsEarnedHistory_OrderBy) {
    UserRewardsEarnedHistory_OrderBy["AvailableTradingRewards"] = "availableTradingRewards";
    UserRewardsEarnedHistory_OrderBy["Id"] = "id";
    UserRewardsEarnedHistory_OrderBy["RewardsEarnedHistory"] = "rewardsEarnedHistory";
    UserRewardsEarnedHistory_OrderBy["TotalFeeWithdrawn"] = "totalFeeWithdrawn";
    UserRewardsEarnedHistory_OrderBy["TotalFeesAndRewardsEarned"] = "totalFeesAndRewardsEarned";
    UserRewardsEarnedHistory_OrderBy["TotalLendingRewards"] = "totalLendingRewards";
    UserRewardsEarnedHistory_OrderBy["TotalLiquidityRewards"] = "totalLiquidityRewards";
    UserRewardsEarnedHistory_OrderBy["TotalStakingRewards"] = "totalStakingRewards";
    UserRewardsEarnedHistory_OrderBy["TotalTradingRewards"] = "totalTradingRewards";
    UserRewardsEarnedHistory_OrderBy["User"] = "user";
})(UserRewardsEarnedHistory_OrderBy || (UserRewardsEarnedHistory_OrderBy = {}));
export var UserStakeHistory_OrderBy;
(function (UserStakeHistory_OrderBy) {
    UserStakeHistory_OrderBy["Id"] = "id";
    UserStakeHistory_OrderBy["StakeHistory"] = "stakeHistory";
    UserStakeHistory_OrderBy["TotalRemaining"] = "totalRemaining";
    UserStakeHistory_OrderBy["TotalStaked"] = "totalStaked";
    UserStakeHistory_OrderBy["TotalWithdrawn"] = "totalWithdrawn";
    UserStakeHistory_OrderBy["User"] = "user";
})(UserStakeHistory_OrderBy || (UserStakeHistory_OrderBy = {}));
export var UserTotal_OrderBy;
(function (UserTotal_OrderBy) {
    UserTotal_OrderBy["Id"] = "id";
    UserTotal_OrderBy["TotalAmmLpFeesUsd"] = "totalAmmLpFeesUsd";
    UserTotal_OrderBy["TotalAmmStakerFeesUsd"] = "totalAmmStakerFeesUsd";
    UserTotal_OrderBy["TotalAmmVolumeUsd"] = "totalAmmVolumeUsd";
    UserTotal_OrderBy["TotalBorrowVolumeUsd"] = "totalBorrowVolumeUsd";
    UserTotal_OrderBy["TotalBorrowingFeesUsd"] = "totalBorrowingFeesUsd";
    UserTotal_OrderBy["TotalCloseWithDepositVolumeUsd"] = "totalCloseWithDepositVolumeUsd";
    UserTotal_OrderBy["TotalCloseWithSwapVolumeUsd"] = "totalCloseWithSwapVolumeUsd";
    UserTotal_OrderBy["TotalDepositCollateralVolumeUsd"] = "totalDepositCollateralVolumeUsd";
    UserTotal_OrderBy["TotalLendVolumeUsd"] = "totalLendVolumeUsd";
    UserTotal_OrderBy["TotalLendingFeesUsd"] = "totalLendingFeesUsd";
    UserTotal_OrderBy["TotalLiquidateVolumeUsd"] = "totalLiquidateVolumeUsd";
    UserTotal_OrderBy["TotalMarginTradeVolumeUsd"] = "totalMarginTradeVolumeUsd";
    UserTotal_OrderBy["TotalTradingFeesUsd"] = "totalTradingFeesUsd";
    UserTotal_OrderBy["TotalUnlendVolumeUsd"] = "totalUnlendVolumeUsd";
    UserTotal_OrderBy["User"] = "user";
})(UserTotal_OrderBy || (UserTotal_OrderBy = {}));
export var User_OrderBy;
(function (User_OrderBy) {
    User_OrderBy["BitcoinTransfers"] = "bitcoinTransfers";
    User_OrderBy["Borrows"] = "borrows";
    User_OrderBy["CreatedAtTimestamp"] = "createdAtTimestamp";
    User_OrderBy["CrossChainTransfer"] = "crossChainTransfer";
    User_OrderBy["CurrentStakes"] = "currentStakes";
    User_OrderBy["FastBtcBridgeStats"] = "fastBTCBridgeStats";
    User_OrderBy["Id"] = "id";
    User_OrderBy["LendingHistory"] = "lendingHistory";
    User_OrderBy["Liquidations"] = "liquidations";
    User_OrderBy["LiquidityHistory"] = "liquidityHistory";
    User_OrderBy["Loans"] = "loans";
    User_OrderBy["RewardsEarnedHistory"] = "rewardsEarnedHistory";
    User_OrderBy["StakeHistory"] = "stakeHistory";
    User_OrderBy["Swaps"] = "swaps";
    User_OrderBy["Trades"] = "trades";
    User_OrderBy["Transactions"] = "transactions";
    User_OrderBy["UserTotals"] = "userTotals";
    User_OrderBy["VestingContracts"] = "vestingContracts";
    User_OrderBy["Votes"] = "votes";
})(User_OrderBy || (User_OrderBy = {}));
export var V2DelegateChanged_OrderBy;
(function (V2DelegateChanged_OrderBy) {
    V2DelegateChanged_OrderBy["Delegate"] = "delegate";
    V2DelegateChanged_OrderBy["Id"] = "id";
    V2DelegateChanged_OrderBy["LockedUntil"] = "lockedUntil";
    V2DelegateChanged_OrderBy["PreviousDelegate"] = "previousDelegate";
    V2DelegateChanged_OrderBy["Timestamp"] = "timestamp";
    V2DelegateChanged_OrderBy["User"] = "user";
})(V2DelegateChanged_OrderBy || (V2DelegateChanged_OrderBy = {}));
export var V2ExtendedStakingDuration_OrderBy;
(function (V2ExtendedStakingDuration_OrderBy) {
    V2ExtendedStakingDuration_OrderBy["AmountStaked"] = "amountStaked";
    V2ExtendedStakingDuration_OrderBy["Id"] = "id";
    V2ExtendedStakingDuration_OrderBy["NewDate"] = "newDate";
    V2ExtendedStakingDuration_OrderBy["PreviousDate"] = "previousDate";
    V2ExtendedStakingDuration_OrderBy["Timestamp"] = "timestamp";
    V2ExtendedStakingDuration_OrderBy["User"] = "user";
})(V2ExtendedStakingDuration_OrderBy || (V2ExtendedStakingDuration_OrderBy = {}));
export var V2Stake_OrderBy;
(function (V2Stake_OrderBy) {
    V2Stake_OrderBy["Amount"] = "amount";
    V2Stake_OrderBy["Delegate"] = "delegate";
    V2Stake_OrderBy["Id"] = "id";
    V2Stake_OrderBy["LockedUntil"] = "lockedUntil";
    V2Stake_OrderBy["Timestamp"] = "timestamp";
    V2Stake_OrderBy["User"] = "user";
})(V2Stake_OrderBy || (V2Stake_OrderBy = {}));
export var V2StakingWithdrawn_OrderBy;
(function (V2StakingWithdrawn_OrderBy) {
    V2StakingWithdrawn_OrderBy["Amount"] = "amount";
    V2StakingWithdrawn_OrderBy["Id"] = "id";
    V2StakingWithdrawn_OrderBy["IsGovernance"] = "isGovernance";
    V2StakingWithdrawn_OrderBy["Receiver"] = "receiver";
    V2StakingWithdrawn_OrderBy["SlashedAmount"] = "slashedAmount";
    V2StakingWithdrawn_OrderBy["Timestamp"] = "timestamp";
    V2StakingWithdrawn_OrderBy["Until"] = "until";
    V2StakingWithdrawn_OrderBy["User"] = "user";
})(V2StakingWithdrawn_OrderBy || (V2StakingWithdrawn_OrderBy = {}));
export var V2TokensStaked_OrderBy;
(function (V2TokensStaked_OrderBy) {
    V2TokensStaked_OrderBy["Amount"] = "amount";
    V2TokensStaked_OrderBy["Id"] = "id";
    V2TokensStaked_OrderBy["LockedUntil"] = "lockedUntil";
    V2TokensStaked_OrderBy["Timestamp"] = "timestamp";
    V2TokensStaked_OrderBy["TotalStaked"] = "totalStaked";
    V2TokensStaked_OrderBy["User"] = "user";
})(V2TokensStaked_OrderBy || (V2TokensStaked_OrderBy = {}));
export var VestingContractType;
(function (VestingContractType) {
    /**
     * FISH (Babelfish governance token) vesting contracts
     *
     */
    VestingContractType["Fish"] = "Fish";
    /**
     * Babelfish team vesting contracts
     *
     */
    VestingContractType["FishTeam"] = "FishTeam";
    /**
     * Vesting contracts for strategic investors with a four-year lockup
     *
     */
    VestingContractType["FourYearVesting"] = "FourYearVesting";
    /**
     * Vesting contracts for investors who participated in the Sovryn Genesis sale
     *
     */
    VestingContractType["Genesis"] = "Genesis";
    /**
     * Vesting contracts for investors who participated in the Sovryn Origin sale
     *
     */
    VestingContractType["Origins"] = "Origins";
    /**
     * Vesting contracts for vested rewards
     *
     */
    VestingContractType["Rewards"] = "Rewards";
    /**
     * Vesting contracts for early strategic investors
     *
     */
    VestingContractType["Strategic"] = "Strategic";
    /**
     * Sovryn team vesting contracts
     *
     */
    VestingContractType["Team"] = "Team";
})(VestingContractType || (VestingContractType = {}));
export var VestingContract_OrderBy;
(function (VestingContract_OrderBy) {
    VestingContract_OrderBy["Cliff"] = "cliff";
    VestingContract_OrderBy["CreatedAtTimestamp"] = "createdAtTimestamp";
    VestingContract_OrderBy["CreatedAtTransaction"] = "createdAtTransaction";
    VestingContract_OrderBy["CurrentBalance"] = "currentBalance";
    VestingContract_OrderBy["Delegate"] = "delegate";
    VestingContract_OrderBy["Duration"] = "duration";
    VestingContract_OrderBy["EmittedBy"] = "emittedBy";
    VestingContract_OrderBy["Id"] = "id";
    VestingContract_OrderBy["StakeHistory"] = "stakeHistory";
    VestingContract_OrderBy["Staking"] = "staking";
    VestingContract_OrderBy["StartingBalance"] = "startingBalance";
    VestingContract_OrderBy["Token"] = "token";
    VestingContract_OrderBy["Type"] = "type";
    VestingContract_OrderBy["User"] = "user";
})(VestingContract_OrderBy || (VestingContract_OrderBy = {}));
export var VestingHistoryItemAction;
(function (VestingHistoryItemAction) {
    /**
     * When a user delegates their vesting contract to another user
     *
     */
    VestingHistoryItemAction["DelegateChanged"] = "DelegateChanged";
    /**
     * This is only relevant to Team tokens. For Team contracts, a vesting contract can be revoked by governance if a team member leaves the project.
     * If this happens, all tokens still locked are returned to the exchequer.
     * This is ONLY possible with Team or FishTeam vesting contracts.
     *
     */
    VestingHistoryItemAction["TeamTokensRevoked"] = "TeamTokensRevoked";
    /**
     * Tokens are staked by the Vesting contract. This happens when the Vesting contract receives funds.
     *
     */
    VestingHistoryItemAction["TokensStaked"] = "TokensStaked";
    /**
     * When a user withdraws unlocked tokens from the vesting contract
     *
     */
    VestingHistoryItemAction["TokensWithdrawn"] = "TokensWithdrawn";
})(VestingHistoryItemAction || (VestingHistoryItemAction = {}));
export var VestingHistoryItem_OrderBy;
(function (VestingHistoryItem_OrderBy) {
    VestingHistoryItem_OrderBy["Action"] = "action";
    VestingHistoryItem_OrderBy["Amount"] = "amount";
    VestingHistoryItem_OrderBy["Delegatee"] = "delegatee";
    VestingHistoryItem_OrderBy["EmittedBy"] = "emittedBy";
    VestingHistoryItem_OrderBy["Id"] = "id";
    VestingHistoryItem_OrderBy["LockedUntil"] = "lockedUntil";
    VestingHistoryItem_OrderBy["Staker"] = "staker";
    VestingHistoryItem_OrderBy["Timestamp"] = "timestamp";
    VestingHistoryItem_OrderBy["TotalStaked"] = "totalStaked";
    VestingHistoryItem_OrderBy["Transaction"] = "transaction";
})(VestingHistoryItem_OrderBy || (VestingHistoryItem_OrderBy = {}));
export var VoteCast_OrderBy;
(function (VoteCast_OrderBy) {
    VoteCast_OrderBy["EmittedBy"] = "emittedBy";
    VoteCast_OrderBy["Id"] = "id";
    VoteCast_OrderBy["Proposal"] = "proposal";
    VoteCast_OrderBy["ProposalId"] = "proposalId";
    VoteCast_OrderBy["Support"] = "support";
    VoteCast_OrderBy["Timestamp"] = "timestamp";
    VoteCast_OrderBy["Transaction"] = "transaction";
    VoteCast_OrderBy["Voter"] = "voter";
    VoteCast_OrderBy["Votes"] = "votes";
})(VoteCast_OrderBy || (VoteCast_OrderBy = {}));
export var Withdrawal_OrderBy;
(function (Withdrawal_OrderBy) {
    Withdrawal_OrderBy["Amount"] = "amount";
    Withdrawal_OrderBy["EmittedBy"] = "emittedBy";
    Withdrawal_OrderBy["Id"] = "id";
    Withdrawal_OrderBy["Receiver"] = "receiver";
    Withdrawal_OrderBy["Timestamp"] = "timestamp";
    Withdrawal_OrderBy["Transaction"] = "transaction";
})(Withdrawal_OrderBy || (Withdrawal_OrderBy = {}));
export var _SubgraphErrorPolicy_;
(function (_SubgraphErrorPolicy_) {
    /** Data will be returned even if the subgraph has indexing errors */
    _SubgraphErrorPolicy_["Allow"] = "allow";
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    _SubgraphErrorPolicy_["Deny"] = "deny";
})(_SubgraphErrorPolicy_ || (_SubgraphErrorPolicy_ = {}));
export const GetActiveLoansDocument = gql `
  query getActiveLoans($user: String) {
    loans(where: { user: $user, type: Borrow }) {
      id
      loanToken {
        id
        lastPriceBtc
        lastPriceUsd
        symbol
      }
      collateralToken {
        id
        lastPriceBtc
        lastPriceUsd
        symbol
      }
      borrowedAmount
      positionSize
      nextRollover
      borrow(first: 1, orderBy: timestamp, orderDirection: desc) {
        interestRate
      }
    }
  }
`;
/**
 * __useGetActiveLoansQuery__
 *
 * To run a query within a React component, call `useGetActiveLoansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveLoansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveLoansQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetActiveLoansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetActiveLoansDocument, options);
}
export function useGetActiveLoansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetActiveLoansDocument, options);
}
export const GetBitcoinTxIdDocument = gql `
  query getBitcoinTxId($createdAtTx: String) {
    bitcoinTransfers(where: { createdAtTx: $createdAtTx }, first: 1) {
      bitcoinTxHash
    }
  }
`;
/**
 * __useGetBitcoinTxIdQuery__
 *
 * To run a query within a React component, call `useGetBitcoinTxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBitcoinTxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBitcoinTxIdQuery({
 *   variables: {
 *      createdAtTx: // value for 'createdAtTx'
 *   },
 * });
 */
export function useGetBitcoinTxIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetBitcoinTxIdDocument, options);
}
export function useGetBitcoinTxIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetBitcoinTxIdDocument, options);
}
export const GetBorrowHistoryDocument = gql `
  query getBorrowHistory(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: Borrow_orderBy
    $orderDirection: OrderDirection
  ) {
    borrows(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      loanToken
      collateralToken
      newPrincipal
      newCollateral
      interestRate
      interestDuration
      collateralToLoanRate
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetBorrowHistoryQuery__
 *
 * To run a query within a React component, call `useGetBorrowHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBorrowHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBorrowHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetBorrowHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetBorrowHistoryDocument, options);
}
export function useGetBorrowHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetBorrowHistoryDocument, options);
}
export const GetCloseWithDepositsDocument = gql `
  query getCloseWithDeposits(
    $loanIds: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: CloseWithDeposit_orderBy
    $orderDirection: OrderDirection
  ) {
    closeWithDeposits(
      where: { loanId_in: $loanIds }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      collateralToken
      loanToken
      repayAmount
      collateralWithdrawAmount
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetCloseWithDepositsQuery__
 *
 * To run a query within a React component, call `useGetCloseWithDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloseWithDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloseWithDepositsQuery({
 *   variables: {
 *      loanIds: // value for 'loanIds'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetCloseWithDepositsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCloseWithDepositsDocument, options);
}
export function useGetCloseWithDepositsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCloseWithDepositsDocument, options);
}
export const GetCloseWithSwapsDocument = gql `
  query getCloseWithSwaps(
    $loanIds: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: CloseWithSwap_orderBy
    $orderDirection: OrderDirection
  ) {
    closeWithSwaps(
      where: { loanId_in: $loanIds }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      collateralToken
      loanToken
      positionCloseSize
      loanCloseAmount
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetCloseWithSwapsQuery__
 *
 * To run a query within a React component, call `useGetCloseWithSwapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloseWithSwapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloseWithSwapsQuery({
 *   variables: {
 *      loanIds: // value for 'loanIds'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetCloseWithSwapsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCloseWithSwapsDocument, options);
}
export function useGetCloseWithSwapsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCloseWithSwapsDocument, options);
}
export const GetConversionFeeDocument = gql `
  query getConversionFee($smartToken: String) {
    liquidityPools(where: { smartToken_contains: $smartToken }) {
      conversionFee
      maxConversionFee
    }
  }
`;
/**
 * __useGetConversionFeeQuery__
 *
 * To run a query within a React component, call `useGetConversionFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversionFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversionFeeQuery({
 *   variables: {
 *      smartToken: // value for 'smartToken'
 *   },
 * });
 */
export function useGetConversionFeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetConversionFeeDocument, options);
}
export function useGetConversionFeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetConversionFeeDocument, options);
}
export const GetDelegateChangesDocument = gql `
  query getDelegateChanges(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: V2DelegateChanged_orderBy
    $orderDirection: OrderDirection
  ) {
    v2DelegateChangeds(
      where: { user: $user, previousDelegate_not: null }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      delegate {
        id
      }
      timestamp
      previousDelegate {
        id
      }
      user {
        id
      }
      lockedUntil
    }
  }
`;
/**
 * __useGetDelegateChangesQuery__
 *
 * To run a query within a React component, call `useGetDelegateChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDelegateChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDelegateChangesQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetDelegateChangesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDelegateChangesDocument, options);
}
export function useGetDelegateChangesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDelegateChangesDocument, options);
}
export const GetDepositCollateralsDocument = gql `
  query getDepositCollaterals(
    $loanIds: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: DepositCollateral_orderBy
    $orderDirection: OrderDirection
  ) {
    depositCollaterals(
      where: { loanId_in: $loanIds }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      depositAmount
      rate
      timestamp
      emittedBy
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetDepositCollateralsQuery__
 *
 * To run a query within a React component, call `useGetDepositCollateralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositCollateralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositCollateralsQuery({
 *   variables: {
 *      loanIds: // value for 'loanIds'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetDepositCollateralsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDepositCollateralsDocument, options);
}
export function useGetDepositCollateralsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDepositCollateralsDocument, options);
}
export const GetExtendedStakingDurationsDocument = gql `
  query getExtendedStakingDurations(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: V2ExtendedStakingDuration_orderBy
    $orderDirection: OrderDirection
  ) {
    v2ExtendedStakingDurations(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      amountStaked
      newDate
      previousDate
      timestamp
      user {
        id
      }
    }
  }
`;
/**
 * __useGetExtendedStakingDurationsQuery__
 *
 * To run a query within a React component, call `useGetExtendedStakingDurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendedStakingDurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendedStakingDurationsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetExtendedStakingDurationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetExtendedStakingDurationsDocument, options);
}
export function useGetExtendedStakingDurationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetExtendedStakingDurationsDocument, options);
}
export const GetFastBtcDepositRskTransactionDocument = gql `
  query getFastBtcDepositRskTransaction($bitcoinTxHash: String, $user: String) {
    bitcoinTransfers(where: { bitcoinTxHash: $bitcoinTxHash, user: $user }) {
      updatedAtTx {
        id
      }
    }
  }
`;
/**
 * __useGetFastBtcDepositRskTransactionQuery__
 *
 * To run a query within a React component, call `useGetFastBtcDepositRskTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFastBtcDepositRskTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFastBtcDepositRskTransactionQuery({
 *   variables: {
 *      bitcoinTxHash: // value for 'bitcoinTxHash'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetFastBtcDepositRskTransactionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetFastBtcDepositRskTransactionDocument, options);
}
export function useGetFastBtcDepositRskTransactionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetFastBtcDepositRskTransactionDocument, options);
}
export const GetFundingDocument = gql `
  query getFunding(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: BitcoinTransfer_orderBy
    $orderDirection: OrderDirection
  ) {
    bitcoinTransfers(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      user {
        id
      }
      createdAtTx {
        id
      }
      createdAtTimestamp
      bitcoinTxHash
      direction
      amountBTC
      feeBTC
      totalAmountBTC
    }
  }
`;
/**
 * __useGetFundingQuery__
 *
 * To run a query within a React component, call `useGetFundingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundingQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetFundingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetFundingDocument, options);
}
export function useGetFundingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetFundingDocument, options);
}
export const GetLastVestingWithdrawDocument = gql `
  query getLastVestingWithdraw($vestingAddress: ID) {
    vestingContracts(where: { id: $vestingAddress }) {
      id
      stakeHistory(
        where: { action: TokensWithdrawn }
        orderBy: timestamp
        orderDirection: desc
        first: 1
      ) {
        timestamp
      }
    }
  }
`;
/**
 * __useGetLastVestingWithdrawQuery__
 *
 * To run a query within a React component, call `useGetLastVestingWithdrawQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastVestingWithdrawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastVestingWithdrawQuery({
 *   variables: {
 *      vestingAddress: // value for 'vestingAddress'
 *   },
 * });
 */
export function useGetLastVestingWithdrawQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLastVestingWithdrawDocument, options);
}
export function useGetLastVestingWithdrawLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLastVestingWithdrawDocument, options);
}
export const GetLendHistoryDocument = gql `
  query getLendHistory($user: ID!) {
    user(id: $user) {
      lendingHistory {
        lendingHistory(orderBy: timestamp) {
          type
          timestamp
          asset {
            id
            symbol
          }
          amount
          loanTokenAmount
          emittedBy
          transaction {
            id
            timestamp
          }
        }
      }
    }
  }
`;
/**
 * __useGetLendHistoryQuery__
 *
 * To run a query within a React component, call `useGetLendHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLendHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLendHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetLendHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLendHistoryDocument, options);
}
export function useGetLendHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLendHistoryDocument, options);
}
export const GetLiquidatesDocument = gql `
  query getLiquidates(
    $loanIds: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: Liquidate_orderBy
    $orderDirection: OrderDirection
  ) {
    liquidates(
      where: { loanId_in: $loanIds }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      collateralToken
      loanToken
      repayAmount
      collateralWithdrawAmount
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetLiquidatesQuery__
 *
 * To run a query within a React component, call `useGetLiquidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiquidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiquidatesQuery({
 *   variables: {
 *      loanIds: // value for 'loanIds'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetLiquidatesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLiquidatesDocument, options);
}
export function useGetLiquidatesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLiquidatesDocument, options);
}
export const GetLiquidityHistoryDocument = gql `
  query getLiquidityHistory(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderDirection: OrderDirection
  ) {
    liquidityHistoryItems(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: timestamp
      orderDirection: $orderDirection
    ) {
      amount
      type
      emittedBy
      timestamp
      reserveToken {
        id
        symbol
      }
      transaction {
        id
      }
      liquidityPool {
        id
      }
    }
  }
`;
/**
 * __useGetLiquidityHistoryQuery__
 *
 * To run a query within a React component, call `useGetLiquidityHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiquidityHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiquidityHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetLiquidityHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLiquidityHistoryDocument, options);
}
export function useGetLiquidityHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLiquidityHistoryDocument, options);
}
export const GetLiquidityMiningAllocationPointsDocument = gql `
  query getLiquidityMiningAllocationPoints {
    liquidityMiningAllocationPoints {
      id
      rewardPerBlock
      ammPoolToken {
        id
        symbol
      }
      lendingPoolToken {
        id
      }
    }
  }
`;
/**
 * __useGetLiquidityMiningAllocationPointsQuery__
 *
 * To run a query within a React component, call `useGetLiquidityMiningAllocationPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiquidityMiningAllocationPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiquidityMiningAllocationPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLiquidityMiningAllocationPointsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLiquidityMiningAllocationPointsDocument, options);
}
export function useGetLiquidityMiningAllocationPointsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLiquidityMiningAllocationPointsDocument, options);
}
export const GetLoanParamsSetupsDocument = gql `
  query getLoanParamsSetups($loanToken: String) {
    loanParamsSetups(
      orderBy: timestamp
      orderDirection: desc
      where: { loanToken: $loanToken }
    ) {
      collateralToken {
        id
      }
      id
      loanToken {
        id
      }
      maintenanceMargin
      minInitialMargin
      maxLoanTerm
      owner
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetLoanParamsSetupsQuery__
 *
 * To run a query within a React component, call `useGetLoanParamsSetupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanParamsSetupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanParamsSetupsQuery({
 *   variables: {
 *      loanToken: // value for 'loanToken'
 *   },
 * });
 */
export function useGetLoanParamsSetupsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLoanParamsSetupsDocument, options);
}
export function useGetLoanParamsSetupsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLoanParamsSetupsDocument, options);
}
export const GetLoansDocument = gql `
  query getLoans($user: String) {
    loans(where: { user: $user, type: Borrow }) {
      id
      loanToken {
        id
        symbol
      }
      collateralToken {
        id
        symbol
      }
      borrowedAmount
      positionSize
      nextRollover
      borrow(first: 1, orderBy: timestamp, orderDirection: desc) {
        interestRate
      }
    }
  }
`;
/**
 * __useGetLoansQuery__
 *
 * To run a query within a React component, call `useGetLoansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetLoansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLoansDocument, options);
}
export function useGetLoansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLoansDocument, options);
}
export const GetProposalDocument = gql `
  query getProposal($id: ID!) {
    proposal(id: $id) {
      id
      canceled {
        id
      }
      executed {
        id
      }
      queued {
        id
      }
      created {
        id
      }
      votesFor
      votesAgainst
      countVotersFor
      countVotersAgainst
      quorum
      proposer
      majorityPercentage
      eta
      proposalId
      targets
      values
      signatures
      calldatas
      startBlock
      endBlock
      description
      timestamp
      votes {
        voter {
          id
        }
        votes
        support
        transaction {
          id
        }
        timestamp
      }
      emittedBy {
        id
        votingDelay
        votingPeriod
        quorumPercentageVotes
        majorityPercentageVotes
      }
      stateChanges {
        id
        state
        timestamp
      }
    }
  }
`;
/**
 * __useGetProposalQuery__
 *
 * To run a query within a React component, call `useGetProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProposalQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetProposalDocument, options);
}
export function useGetProposalLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetProposalDocument, options);
}
export const GetProposalsDocument = gql `
  query getProposals {
    proposals(orderBy: timestamp, orderDirection: desc) {
      id
      canceled {
        id
      }
      executed {
        id
      }
      queued {
        id
      }
      created {
        id
      }
      votesFor
      votesAgainst
      countVotersFor
      countVotersAgainst
      quorum
      majorityPercentage
      eta
      proposalId
      proposer
      targets
      values
      signatures
      calldatas
      startBlock
      endBlock
      description
      timestamp
      votes {
        voter {
          id
        }
        votes
        support
        transaction {
          id
        }
      }
      emittedBy {
        id
        votingDelay
        votingPeriod
        quorumPercentageVotes
        majorityPercentageVotes
      }
      stateChanges {
        id
        state
        timestamp
      }
    }
  }
`;
/**
 * __useGetProposalsQuery__
 *
 * To run a query within a React component, call `useGetProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProposalsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetProposalsDocument, options);
}
export function useGetProposalsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetProposalsDocument, options);
}
export const GetProtocolFeeDocument = gql `
  query getProtocolFee {
    swapSettings {
      protocolFee
    }
  }
`;
/**
 * __useGetProtocolFeeQuery__
 *
 * To run a query within a React component, call `useGetProtocolFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProtocolFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProtocolFeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProtocolFeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetProtocolFeeDocument, options);
}
export function useGetProtocolFeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetProtocolFeeDocument, options);
}
export const GetRewardsEarnedHistoryDocument = gql `
  query getRewardsEarnedHistory(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: RewardsEarnedHistoryItem_orderBy
    $orderDirection: OrderDirection
    $actions: [RewardsEarnedAction!]
  ) {
    rewardsEarnedHistoryItems(
      where: { user: $user, amount_gt: 0, action_in: $actions }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      action
      amount
      token
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetRewardsEarnedHistoryQuery__
 *
 * To run a query within a React component, call `useGetRewardsEarnedHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsEarnedHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsEarnedHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useGetRewardsEarnedHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRewardsEarnedHistoryDocument, options);
}
export function useGetRewardsEarnedHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRewardsEarnedHistoryDocument, options);
}
export const GetRolloversDocument = gql `
  query getRollovers(
    $loanIds: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: Rollover_orderBy
    $orderDirection: OrderDirection
  ) {
    rollovers(
      where: { loanId_in: $loanIds }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      user {
        id
      }
      loanId {
        id
        collateralToken {
          id
          symbol
        }
        loanToken {
          id
          symbol
        }
      }
      lender
      principal
      collateral
      endTimestamp
      rewardReceiver {
        id
      }
      reward
      timestamp
      emittedBy
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetRolloversQuery__
 *
 * To run a query within a React component, call `useGetRolloversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolloversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolloversQuery({
 *   variables: {
 *      loanIds: // value for 'loanIds'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetRolloversQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRolloversDocument, options);
}
export function useGetRolloversLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRolloversDocument, options);
}
export const GetSmartTokensDocument = gql `
  query getSmartTokens(
    $skip: Int!
    $pageSize: Int!
    $orderBy: SmartToken_orderBy
    $orderDirection: OrderDirection
    $filters: SmartToken_filter
  ) {
    smartTokens(
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $filters
    ) {
      id
      name
      decimals
      symbol
      owner
      addedToRegistryBlockNumber
    }
  }
`;
/**
 * __useGetSmartTokensQuery__
 *
 * To run a query within a React component, call `useGetSmartTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartTokensQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSmartTokensQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetSmartTokensDocument, options);
}
export function useGetSmartTokensLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetSmartTokensDocument, options);
}
export const GetStakeHistoryDocument = gql `
  query getStakeHistory(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: V2TokensStaked_orderBy
    $orderDirection: OrderDirection
  ) {
    v2TokensStakeds(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      amount
      totalStaked
      timestamp
      lockedUntil
      user {
        id
      }
    }
  }
`;
/**
 * __useGetStakeHistoryQuery__
 *
 * To run a query within a React component, call `useGetStakeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStakeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStakeHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetStakeHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStakeHistoryDocument, options);
}
export function useGetStakeHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStakeHistoryDocument, options);
}
export const GetStakingWithdrawsDocument = gql `
  query getStakingWithdraws(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: V2StakingWithdrawn_orderBy
    $orderDirection: OrderDirection
  ) {
    v2StakingWithdrawns(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      amount
      slashedAmount
      isGovernance
      receiver {
        id
      }
      user {
        id
      }
      timestamp
      until
    }
  }
`;
/**
 * __useGetStakingWithdrawsQuery__
 *
 * To run a query within a React component, call `useGetStakingWithdrawsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStakingWithdrawsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStakingWithdrawsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetStakingWithdrawsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStakingWithdrawsDocument, options);
}
export function useGetStakingWithdrawsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStakingWithdrawsDocument, options);
}
export const GetSwapHistoryDocument = gql `
  query getSwapHistory(
    $user: String
    $skip: Int!
    $pageSize: Int!
    $orderBy: Swap_orderBy
    $orderDirection: OrderDirection
  ) {
    swaps(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      fromToken {
        id
        symbol
      }
      toToken {
        id
        symbol
      }
      transaction {
        id
        timestamp
      }
      fromAmount
      toAmount
      conversionFee
      protocolFee
    }
  }
`;
/**
 * __useGetSwapHistoryQuery__
 *
 * To run a query within a React component, call `useGetSwapHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwapHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwapHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetSwapHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetSwapHistoryDocument, options);
}
export function useGetSwapHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetSwapHistoryDocument, options);
}
export const GetTokenDocument = gql `
  query getToken($id: ID!) {
    token(id: $id) {
      lastPriceUsd
      lastPriceBtc
    }
  }
`;
/**
 * __useGetTokenQuery__
 *
 * To run a query within a React component, call `useGetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTokenQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTokenDocument, options);
}
export function useGetTokenLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTokenDocument, options);
}
export const GetTokenRatesDocument = gql `
  query getTokenRates {
    tokens {
      id
      symbol
      lastPriceBtc
      lastPriceUsd
    }
  }
`;
/**
 * __useGetTokenRatesQuery__
 *
 * To run a query within a React component, call `useGetTokenRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTokenRatesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTokenRatesDocument, options);
}
export function useGetTokenRatesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTokenRatesDocument, options);
}
export const GetTradeVolumeDocument = gql `
  query getTradeVolume($pool: String, $timestamp: Int!) {
    poolVolumeItems(where: { pool: $pool, timestamp_gt: $timestamp }) {
      btcAmount
    }
  }
`;
/**
 * __useGetTradeVolumeQuery__
 *
 * To run a query within a React component, call `useGetTradeVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeVolumeQuery({
 *   variables: {
 *      pool: // value for 'pool'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useGetTradeVolumeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTradeVolumeDocument, options);
}
export function useGetTradeVolumeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTradeVolumeDocument, options);
}
export const GetTradingRewardsDocument = gql `
  query getTradingRewards($id: ID!) {
    userRewardsEarnedHistory(id: $id) {
      availableTradingRewards
      totalTradingRewards
      totalLendingRewards
      totalLiquidityRewards
      totalStakingRewards
      totalFeeWithdrawn
    }
  }
`;
/**
 * __useGetTradingRewardsQuery__
 *
 * To run a query within a React component, call `useGetTradingRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradingRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradingRewardsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTradingRewardsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTradingRewardsDocument, options);
}
export function useGetTradingRewardsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTradingRewardsDocument, options);
}
export const GetTransactionsDocument = gql `
  query getTransactions($limit: Int!) {
    transactions(first: $limit) {
      id
      gasPrice
      gasLimit
      to
      blockNumber
      timestamp
      from {
        id
      }
    }
  }
`;
/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTransactionsDocument, options);
}
export function useGetTransactionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTransactionsDocument, options);
}
export const GetUserRewardsEarnedHistoryDocument = gql `
  query getUserRewardsEarnedHistory($user: ID!) {
    userRewardsEarnedHistory(id: $user) {
      totalFeeWithdrawn
    }
  }
`;
/**
 * __useGetUserRewardsEarnedHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserRewardsEarnedHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRewardsEarnedHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRewardsEarnedHistoryQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetUserRewardsEarnedHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserRewardsEarnedHistoryDocument, options);
}
export function useGetUserRewardsEarnedHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserRewardsEarnedHistoryDocument, options);
}
export const GetVestingContractsDocument = gql `
  query getVestingContracts($user: String, $skip: Int!, $pageSize: Int!) {
    vestingContracts(
      where: { user: $user }
      first: $pageSize
      skip: $skip
      orderBy: currentBalance
      orderDirection: desc
    ) {
      id
      cliff
      duration
      currentBalance
      type
      createdAtTimestamp
    }
  }
`;
/**
 * __useGetVestingContractsQuery__
 *
 * To run a query within a React component, call `useGetVestingContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestingContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestingContractsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetVestingContractsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVestingContractsDocument, options);
}
export function useGetVestingContractsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVestingContractsDocument, options);
}
export const GetVestingContractsIdDocument = gql `
  query getVestingContractsId($user: String) {
    vestingContracts(where: { user: $user }) {
      id
    }
  }
`;
/**
 * __useGetVestingContractsIdQuery__
 *
 * To run a query within a React component, call `useGetVestingContractsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestingContractsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestingContractsIdQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetVestingContractsIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVestingContractsIdDocument, options);
}
export function useGetVestingContractsIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVestingContractsIdDocument, options);
}
export const GetUserVestingContractsDocument = gql `
  query getUserVestingContracts($userAddress: String!) {
    vestingContracts(where: { user: $userAddress }) {
      id
    }
  }
`;
/**
 * __useGetUserVestingContractsQuery__
 *
 * To run a query within a React component, call `useGetUserVestingContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVestingContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVestingContractsQuery({
 *   variables: {
 *      userAddress: // value for 'userAddress'
 *   },
 * });
 */
export function useGetUserVestingContractsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserVestingContractsDocument, options);
}
export function useGetUserVestingContractsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserVestingContractsDocument, options);
}
export const GetDelegateChangesForVestingsDocument = gql `
  query getDelegateChangesForVestings(
    $vestingContracts: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: VestingHistoryItem_orderBy
    $orderDirection: OrderDirection
  ) {
    vestingHistoryItems(
      where: { staker_in: $vestingContracts, action: DelegateChanged }
      orderBy: $orderBy
      orderDirection: $orderDirection
      first: $pageSize
      skip: $skip
    ) {
      id
      delegatee {
        id
      }
      amount
      timestamp
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetDelegateChangesForVestingsQuery__
 *
 * To run a query within a React component, call `useGetDelegateChangesForVestingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDelegateChangesForVestingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDelegateChangesForVestingsQuery({
 *   variables: {
 *      vestingContracts: // value for 'vestingContracts'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetDelegateChangesForVestingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDelegateChangesForVestingsDocument, options);
}
export function useGetDelegateChangesForVestingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDelegateChangesForVestingsDocument, options);
}
export const GetVestingHistoryDocument = gql `
  query getVestingHistory($vestingAddress: ID) {
    vestingContracts(where: { id: $vestingAddress }) {
      id
      stakeHistory(
        where: { action: TokensStaked }
        orderBy: lockedUntil
        orderDirection: desc
        first: 250
      ) {
        id
        amount
        lockedUntil
      }
    }
  }
`;
/**
 * __useGetVestingHistoryQuery__
 *
 * To run a query within a React component, call `useGetVestingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestingHistoryQuery({
 *   variables: {
 *      vestingAddress: // value for 'vestingAddress'
 *   },
 * });
 */
export function useGetVestingHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVestingHistoryDocument, options);
}
export function useGetVestingHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVestingHistoryDocument, options);
}
export const GetUserVestingsOfTypeDocument = gql `
  query getUserVestingsOfType($user: String, $type: VestingContractType) {
    vestingContracts(where: { user: $user, type: $type }) {
      id
      stakeHistory(
        where: { action: TokensStaked }
        orderBy: lockedUntil
        orderDirection: desc
        first: 250
      ) {
        id
        amount
        lockedUntil
      }
    }
  }
`;
/**
 * __useGetUserVestingsOfTypeQuery__
 *
 * To run a query within a React component, call `useGetUserVestingsOfTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVestingsOfTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVestingsOfTypeQuery({
 *   variables: {
 *      user: // value for 'user'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserVestingsOfTypeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserVestingsOfTypeDocument, options);
}
export function useGetUserVestingsOfTypeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserVestingsOfTypeDocument, options);
}
export const GetLastWithdrawOfVestingTypeDocument = gql `
  query getLastWithdrawOfVestingType(
    $user: String
    $type: VestingContractType
  ) {
    vestingContracts(where: { user: $user, type: $type }) {
      id
      stakeHistory(
        where: { action: TokensWithdrawn }
        orderBy: timestamp
        orderDirection: desc
        first: 1
      ) {
        id
        amount
        timestamp
      }
    }
  }
`;
/**
 * __useGetLastWithdrawOfVestingTypeQuery__
 *
 * To run a query within a React component, call `useGetLastWithdrawOfVestingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastWithdrawOfVestingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastWithdrawOfVestingTypeQuery({
 *   variables: {
 *      user: // value for 'user'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLastWithdrawOfVestingTypeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLastWithdrawOfVestingTypeDocument, options);
}
export function useGetLastWithdrawOfVestingTypeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLastWithdrawOfVestingTypeDocument, options);
}
export const GetVestingHistoryItemsDocument = gql `
  query getVestingHistoryItems(
    $stakers: [String!]
    $skip: Int!
    $pageSize: Int!
    $orderBy: VestingHistoryItem_orderBy
    $orderDirection: OrderDirection
  ) {
    vestingHistoryItems(
      where: { action: TokensWithdrawn, staker_in: $stakers }
      first: $pageSize
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      timestamp
      action
      amount
      transaction {
        id
      }
    }
  }
`;
/**
 * __useGetVestingHistoryItemsQuery__
 *
 * To run a query within a React component, call `useGetVestingHistoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestingHistoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestingHistoryItemsQuery({
 *   variables: {
 *      stakers: // value for 'stakers'
 *      skip: // value for 'skip'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetVestingHistoryItemsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVestingHistoryItemsDocument, options);
}
export function useGetVestingHistoryItemsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVestingHistoryItemsDocument, options);
}
export const GetVestingUnlockBalanceDocument = gql `
  query getVestingUnlockBalance(
    $vestingAddress: ID
    $timestamp: Int
    $currentTimestamp: Int
  ) {
    vestingContracts(where: { id: $vestingAddress }) {
      id
      stakeHistory(
        where: {
          action: TokensStaked
          lockedUntil_gt: $timestamp
          lockedUntil_lte: $currentTimestamp
        }
        orderBy: lockedUntil
        orderDirection: asc
        first: 250
      ) {
        timestamp
        amount
        lockedUntil
      }
    }
  }
`;
/**
 * __useGetVestingUnlockBalanceQuery__
 *
 * To run a query within a React component, call `useGetVestingUnlockBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVestingUnlockBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVestingUnlockBalanceQuery({
 *   variables: {
 *      vestingAddress: // value for 'vestingAddress'
 *      timestamp: // value for 'timestamp'
 *      currentTimestamp: // value for 'currentTimestamp'
 *   },
 * });
 */
export function useGetVestingUnlockBalanceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVestingUnlockBalanceDocument, options);
}
export function useGetVestingUnlockBalanceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVestingUnlockBalanceDocument, options);
}
export const GetVoteDocument = gql `
  query getVote($id: String, $voter: String) {
    voteCasts(where: { proposal: $id, voter: $voter }) {
      emittedBy
      id
      proposalId
      support
      timestamp
      transaction {
        id
      }
      voter {
        id
      }
      votes
    }
  }
`;
/**
 * __useGetVoteQuery__
 *
 * To run a query within a React component, call `useGetVoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      voter: // value for 'voter'
 *   },
 * });
 */
export function useGetVoteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVoteDocument, options);
}
export function useGetVoteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVoteDocument, options);
}
