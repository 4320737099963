export enum TabSize {
  small = 'small',
  normal = 'normal',
}

export enum TabType {
  primary = 'primary',
  secondary = 'secondary',
  slider = 'slider',
}
