import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useAccount } from '../../../../../../hooks/useAccount';
import { rskClient } from '../../../../../../utils/clients';
import { useGetLastWithdrawOfVestingTypeQuery, useGetUserVestingsOfTypeQuery, VestingContractType, } from '../../../../../../utils/graphql/rsk/generated';
export const useGetUnclaimedUserVestingCount = () => {
    const { account } = useAccount();
    const { data, loading } = useGetUserVestingsOfTypeQuery({
        variables: {
            user: account.toLowerCase(),
            type: VestingContractType.Rewards,
        },
        client: rskClient,
    });
    const { data: withdraws, loading: loadingWithdraws } = useGetLastWithdrawOfVestingTypeQuery({
        variables: {
            user: account,
            type: VestingContractType.Rewards,
        },
        client: rskClient,
    });
    const lastWithdrawTimestamp = useMemo(() => {
        var _a, _b, _c;
        return ((_c = (_b = (_a = withdraws === null || withdraws === void 0 ? void 0 : withdraws.vestingContracts[0]) === null || _a === void 0 ? void 0 : _a.stakeHistory) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.timestamp) ||
            dayjs().subtract(4, 'year').unix();
    }, [withdraws === null || withdraws === void 0 ? void 0 : withdraws.vestingContracts]);
    const result = useMemo(() => {
        var _a, _b;
        const stakeHistoryItems = (_b = (_a = data === null || data === void 0 ? void 0 : data.vestingContracts[0]) === null || _a === void 0 ? void 0 : _a.stakeHistory) === null || _b === void 0 ? void 0 : _b.map(item => ({
            amount: item.amount,
            lockedUntil: item.lockedUntil,
        }));
        if (!stakeHistoryItems) {
            return 0;
        }
        const normalizedStakeHistoryItems = stakeHistoryItems.reduce((map, obj) => {
            const { lockedUntil, amount } = obj;
            map.set(lockedUntil, (map.get(lockedUntil) || 0) + Number(amount));
            return map;
        }, new Map());
        const unlockDates = Array.from(normalizedStakeHistoryItems, ([lockedUntil, amount]) => ({
            lockedUntil,
            amount,
            isUnlocked: lockedUntil <= dayjs().unix(),
        })).sort((a, b) => a.lockedUntil - b.lockedUntil);
        const pastDatesLength = unlockDates === null || unlockDates === void 0 ? void 0 : unlockDates.filter(item => item.isUnlocked).filter(item => item.lockedUntil > lastWithdrawTimestamp).length;
        return pastDatesLength;
    }, [data === null || data === void 0 ? void 0 : data.vestingContracts, lastWithdrawTimestamp]);
    return loading || loadingWithdraws ? 0 : result;
};
