export var BorrowHistoryType;
(function (BorrowHistoryType) {
    BorrowHistoryType["lineOfCredit"] = "lineOfCredit";
    BorrowHistoryType["newLoan"] = "newLoan";
    BorrowHistoryType["depositCollateralLoan"] = "depositCollateralLoan";
    BorrowHistoryType["closeWithSwapLoan"] = "closeWithSwapLoan";
    BorrowHistoryType["closeWithDepositLoan"] = "closeWithDepositLoan";
    BorrowHistoryType["liquidationLoan"] = "liquidationLoan";
    BorrowHistoryType["rolloversLoan"] = "rolloversLoan";
    BorrowHistoryType["collateralSurplus"] = "collateralSurplus";
})(BorrowHistoryType || (BorrowHistoryType = {}));
