var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { ethers } from 'ethers';
import { useCacheCall } from '../../../../hooks';
import { useAccount } from '../../../../hooks/useAccount';
import { useCurrentChain } from '../../../../hooks/useChainStore';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';
import { useInterval } from '../../../../hooks/useInterval';
import { RuneContext, defaultValue, tokenABI, } from '../contexts/rune';
export const RuneContextProvider = ({ children, }) => {
    const [state, setState] = React.useState(defaultValue);
    const chainId = useCurrentChain();
    const { provider, account } = useAccount();
    const runeBridgeContract = useGetProtocolContract('runeBridge', chainId);
    const { value: listTokens } = useCacheCall('runeBridge/tokens', chainId, () => __awaiter(void 0, void 0, void 0, function* () { return runeBridgeContract === null || runeBridgeContract === void 0 ? void 0 : runeBridgeContract.listTokens(); }));
    const requestTokenBalances = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!listTokens) {
            console.warn('RuneBridge contract not loaded, cannot refresh token balances');
            return;
        }
        const tokenBalances = [];
        for (const tokenAddress of listTokens) {
            const tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);
            const balance = yield tokenContract.balanceOf(account);
            const symbol = yield tokenContract.symbol();
            const name = yield tokenContract.name();
            const decimals = yield tokenContract.decimals();
            tokenBalances.push({
                symbol: symbol,
                balance: ethers.utils.formatUnits(balance, decimals),
                decimals: decimals,
                name: name,
                tokenContractAddress: tokenAddress,
            });
        }
        if (tokenBalances) {
            setState(state => (Object.assign(Object.assign({}, state), { tokenBalances })));
        }
    }), [account, provider, listTokens]);
    const value = React.useMemo(() => (Object.assign(Object.assign({}, state), { runeBridgeContract, set: setState, requestTokenBalances })), [requestTokenBalances, state, runeBridgeContract]);
    // we cannot use immediate: true because runeBridgeContract is not loaded on the initial render
    useInterval(requestTokenBalances, 10000);
    useEffect(() => {
        const timeout = setTimeout(requestTokenBalances, 1000);
        return () => clearTimeout(timeout);
    }, [requestTokenBalances]);
    return React.createElement(RuneContext.Provider, { value: value }, children);
};
