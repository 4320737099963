// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
<g id="Group_6283" data-name="Group 6283" transform="translate(-72 -803)">
  <g id="Group_6006" data-name="Group 6006" transform="translate(72 803)">
    <path id="Path_6089" data-name="Path 6089" d="M19,0A19,19,0,1,1,0,19,19,19,0,0,1,19,0Z" fill="#e9eae9"/>
  </g>
  <g id="Group_6005" data-name="Group 6005" transform="translate(83.146 809.08)">
    <path id="Path_2577" data-name="Path 2577" d="M383.677,0,383.5.6V18.005l.177.176,8.079-4.776Z" transform="translate(-375.637 0)" fill="#525252"/>
    <path id="Path_2578" data-name="Path 2578" d="M8.08,0,0,13.406l8.08,4.776V0Z" transform="translate(0 0)" fill="#b1b1b1"/>
    <path id="Path_2579" data-name="Path 2579" d="M387.339,729.663l-.1.121v6.2l.1.29,8.084-11.385Z" transform="translate(-379.299 -710.016)" fill="#3e3e3e"/>
    <path id="Path_2580" data-name="Path 2580" d="M8.08,736.275v-6.612L0,724.89Z" transform="translate(0 -710.016)" fill="#b1b1b1"/>
    <path id="Path_2581" data-name="Path 2581" d="M392.07,480.778,400.149,476l-8.079-3.672Z" transform="translate(-384.03 -462.631)"/>
    <path id="Path_2582" data-name="Path 2582" d="M0,476l8.08,4.776V472.33Z" transform="translate(0 -462.631)" fill="#6c6c6c"/>
  </g>
</g>
</svg>`;
