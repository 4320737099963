import React from 'react';
import classNames from 'classnames';
import { applyDataAttr, Icon, IconNames, StatusType } from '@sovryn/ui';
const getStatusImage = (status) => {
    switch (status) {
        case StatusType.error:
            return IconNames.FAILED_TX;
        case StatusType.success:
            return IconNames.SUCCESS_ICON;
        default:
            return IconNames.PENDING;
    }
};
export const getStatusClass = (status) => {
    switch (status) {
        case StatusType.success:
            return 'text-success';
        case StatusType.error:
            return 'text-error-light';
        case StatusType.pending:
            return 'text-sov-white animate-spin';
    }
};
const getDataAttributeSuffix = (status) => {
    switch (status) {
        case StatusType.error:
            return 'failed';
        case StatusType.success:
            return 'confirmed';
        default:
            return 'pending';
    }
};
export const StatusIcon = ({ status, isInline = false, dataAttribute, }) => (React.createElement("div", { className: "flex justify-center" },
    React.createElement(Icon, Object.assign({ icon: getStatusImage(status), className: classNames(getStatusClass(status), isInline ? 'h-auto flex-initial' : 'h-20 w-20') }, applyDataAttr(`${dataAttribute}-${getDataAttributeSuffix(status)}`)))));
