import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Icon, applyDataAttr } from '@sovryn/ui';
import styles from './MenuItemWithRouter.module.css';
export const MenuItemWithRouter = ({ className, onClick, icon, text, label, disabled, isActive, dataAttribute, link, }) => (React.createElement("li", Object.assign({ className: classNames(styles.host, className) }, applyDataAttr(dataAttribute)),
    React.createElement(NavLink, { to: link, className: classNames(styles.button, className, {
            [styles.disabled]: disabled,
            [styles.active]: isActive,
        }), onClick: onClick },
        React.createElement("div", { className: styles.hostBlock },
            React.createElement("div", { className: styles.hostFlex },
                icon && React.createElement(Icon, { icon: icon, className: styles.icon }),
                React.createElement("span", { className: classNames(styles.text) }, text)),
            label && React.createElement("span", { className: styles.label }, label)))));
