var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ESTIMATOR_URI } from '../../constants/infrastructure';
import { FAKE_SIMULATOR_TX_DATA } from './helpers';
const prepareBody = (networkId, tx) => JSON.stringify({
    network_id: String(networkId),
    tx,
});
export const simulateTx = (networkId, tx, signal) => __awaiter(void 0, void 0, void 0, function* () {
    if (!ESTIMATOR_URI) {
        const fakeResponse = tx.length === 2
            ? [FAKE_SIMULATOR_TX_DATA, FAKE_SIMULATOR_TX_DATA]
            : [FAKE_SIMULATOR_TX_DATA];
        return Promise.resolve(fakeResponse);
    }
    return fetch(ESTIMATOR_URI, {
        method: 'post',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
        },
        body: prepareBody(networkId, tx),
        signal,
    }).then(response => {
        if (response.ok) {
            return response.json();
        }
        throw new Error('Request failed');
    });
});
