export var URIType;
(function (URIType) {
    URIType["BITCOIN"] = "bitcoin:";
})(URIType || (URIType = {}));
export var ReceiveEvents;
(function (ReceiveEvents) {
    ReceiveEvents["txAmount"] = "txAmount";
    ReceiveEvents["depositTx"] = "depositTx";
    ReceiveEvents["transferTx"] = "transferTx";
    ReceiveEvents["disconnect"] = "disconnect";
    ReceiveEvents["getDepositAddress"] = "getDepositAddress";
    ReceiveEvents["getDepositHistory"] = "getDepositHistory";
})(ReceiveEvents || (ReceiveEvents = {}));
