var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import { DEPOSITS_PATH, LAST_SCANNED_BTC_BLOCK_PATH } from '../constants';
import { ReceiveFlowContext, defaultValue, } from '../contexts/receiveflow';
import { useRuneBridgeApiClient } from '../hooks/useRuneBridgeApiClient';
export const ReceiveFlowContextProvider = ({ children }) => {
    const [state, setState] = React.useState(defaultValue);
    const runeBridgeApiClient = useRuneBridgeApiClient();
    const requestLastScannedBlock = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const reqOptions = {
            method: 'GET',
        };
        return yield runeBridgeApiClient.request(LAST_SCANNED_BTC_BLOCK_PATH, reqOptions);
    }), [runeBridgeApiClient]);
    const getRuneDepositStatus = useCallback((userEvmAddress, lastScannedBlockHash) => __awaiter(void 0, void 0, void 0, function* () {
        const path = `${DEPOSITS_PATH}/${userEvmAddress}/${lastScannedBlockHash}`;
        const reqOptions = {
            method: 'GET',
        };
        return yield runeBridgeApiClient.request(path, reqOptions);
    }), [runeBridgeApiClient]);
    const value = React.useMemo(() => (Object.assign(Object.assign({}, state), { set: setState, requestLastScannedBlock,
        getRuneDepositStatus })), [getRuneDepositStatus, requestLastScannedBlock, state]);
    return (React.createElement(ReceiveFlowContext.Provider, { value: value }, children));
};
