// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11462_154)">
<path d="M9.99968 18.9469C15.2317 18.9469 19.4731 14.8233 19.4731 9.73659C19.4731 4.64989 15.2317 0.526306 9.99968 0.526306C4.76765 0.526306 0.526245 4.64989 0.526245 9.73659C0.526245 14.8233 4.76765 18.9469 9.99968 18.9469Z" fill="white"/>
<path d="M4.56146 7.43823L5.72774 10.7966L6.83297 7.43823H8.51293V12.356H7.28244V9.37714L6.19352 12.356H5.16775L4.07883 9.37714V12.356H2.84729V7.43823H4.56146Z" fill="#56BEB7"/>
<path d="M9.02609 11.5197C8.86691 11.2188 8.78369 10.8835 8.78369 10.5431C8.78369 10.2027 8.86691 9.86749 9.02609 9.56659C9.18613 9.28672 9.42548 9.06055 9.71396 8.91661C10.0412 8.7663 10.397 8.68848 10.7571 8.68848C11.1172 8.68848 11.473 8.7663 11.8002 8.91661C12.0889 9.06047 12.3284 9.28665 12.4886 9.56659C12.6476 9.86754 12.7307 10.2028 12.7307 10.5431C12.7307 10.8835 12.6476 11.2187 12.4886 11.5197C12.3284 11.7996 12.0889 12.0258 11.8002 12.1697C11.473 12.3201 11.117 12.398 10.7568 12.398C10.3966 12.398 10.0407 12.3201 9.71344 12.1697C9.42516 12.0257 9.18599 11.7995 9.02609 11.5197V11.5197ZM11.4955 10.5429C11.5068 10.3123 11.4372 10.085 11.2987 9.90027C11.2308 9.824 11.1475 9.76297 11.0543 9.72118C10.9612 9.67939 10.8602 9.65779 10.7581 9.65779C10.656 9.65779 10.5551 9.67939 10.4619 9.72118C10.3688 9.76297 10.2855 9.824 10.2176 9.90027C10.0894 10.0902 10.0209 10.314 10.0209 10.5431C10.0209 10.7723 10.0894 10.9961 10.2176 11.186C10.2855 11.2623 10.3688 11.3233 10.4619 11.3651C10.5551 11.4069 10.656 11.4285 10.7581 11.4285C10.8602 11.4285 10.9612 11.4069 11.0543 11.3651C11.1475 11.3233 11.2308 11.2623 11.2987 11.186C11.4364 11.0009 11.506 10.7739 11.4955 10.5434V10.5429Z" fill="#56BEB7"/>
<path d="M14.0843 7.70559C14.4846 7.49154 14.9336 7.38511 15.3874 7.39666C15.7573 7.39018 16.1234 7.47209 16.4553 7.63559C16.7599 7.78674 17.0192 8.0157 17.2069 8.29927C17.3968 8.59031 17.5081 8.92556 17.53 9.2724H16.2369C16.2003 9.04765 16.0904 8.84131 15.9243 8.68556C15.7579 8.53638 15.5403 8.45721 15.3169 8.46453C15.1648 8.45858 15.0136 8.49157 14.8777 8.56036C14.7419 8.62916 14.6259 8.7315 14.5406 8.85768C14.3588 9.17413 14.2632 9.53269 14.2632 9.89764C14.2632 10.2626 14.3588 10.6212 14.5406 10.9376C14.6259 11.0638 14.7419 11.1661 14.8777 11.2349C15.0136 11.3037 15.1648 11.3367 15.3169 11.3308C15.5403 11.3381 15.7579 11.2589 15.9243 11.1097C16.0904 10.954 16.2003 10.7476 16.2369 10.5229H17.5295C17.5074 10.8697 17.3961 11.2049 17.2063 11.496C17.0187 11.7796 16.7594 12.0085 16.4548 12.1597C16.1231 12.3239 15.7569 12.4062 15.3869 12.3997C14.9331 12.4111 14.4841 12.3045 14.0838 12.0902C13.7216 11.89 13.427 11.5869 13.237 11.2192C13.0427 10.8063 12.942 10.3555 12.942 9.89922C12.942 9.44288 13.0427 8.99218 13.237 8.57925C13.4267 8.21055 13.7216 7.90648 14.0843 7.70559V7.70559Z" fill="#56BEB7"/>
<path d="M9.99974 1.23786C12.1717 1.23922 14.2657 2.0474 15.8753 3.50558C17.485 4.96376 18.4956 6.96793 18.7109 9.12917C18.9262 11.2904 18.331 13.4546 17.0407 15.2017C15.7503 16.9488 13.857 18.1542 11.728 18.5841C9.59903 19.014 7.38628 18.6376 5.51916 17.528C3.65204 16.4185 2.26371 14.6548 1.62359 12.5793C0.983467 10.5039 1.13721 8.26461 2.05499 6.2961C2.97276 4.3276 4.58911 2.77025 6.59036 1.92627C7.66942 1.47134 8.8287 1.23726 9.99974 1.23786ZM9.99974 0C8.02198 0 6.08863 0.586474 4.44419 1.68526C2.79974 2.78404 1.51805 4.34579 0.761189 6.173C0.0043327 8.00022 -0.193695 10.0108 0.192147 11.9506C0.577989 13.8903 1.53037 15.6721 2.92886 17.0706C4.32735 18.4691 6.10913 19.4215 8.04889 19.8073C9.98865 20.1932 11.9993 19.9951 13.8265 19.2383C15.6537 18.4814 17.2154 17.1997 18.3142 15.5553C19.413 13.9108 19.9995 11.9775 19.9995 9.99974C19.9995 7.34764 18.9459 4.80417 17.0706 2.92886C15.1953 1.05354 12.6518 0 9.99974 0V0Z" fill="#56BEB7"/>
</g>
<defs>
<clipPath id="clip0_11462_154">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
`;
