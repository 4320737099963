import React from 'react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { useRouteError } from 'react-router-dom';
import { Paragraph } from '@sovryn/ui';
import { ErrorRenderer } from '../../1_atoms/ErrorRenderer/ErrorRenderer';
import { Footer, Header } from '../../3_organisms';
import { translations } from '../../../locales/i18n';
import { isDevEnvironment } from '../../../utils/helpers';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';
export const ErrorPage = () => {
    const error = useRouteError();
    return (error === null || error === void 0 ? void 0 : error.status) === 404 ? (React.createElement(NotFoundPage, null)) : (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, t(translations.errorPage.meta.title))),
        React.createElement(Header, null),
        React.createElement(ErrorRenderer, { title: "", error: React.createElement(React.Fragment, null,
                React.createElement(Paragraph, { className: "w-full p-3" },
                    React.createElement("i", null, error.statusText || error.message)),
                isDevEnvironment() && (React.createElement("pre", null, JSON.stringify(error, undefined, 2)))) }),
        React.createElement(Footer, null)));
};
