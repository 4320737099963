var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import QRCode from 'qrcode.react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@sovryn/tailwindcss-config';
import { prettyTx, Icon, IconNames, Paragraph, NotificationType, Lottie, ParagraphStyle, applyDataAttr, } from '@sovryn/ui';
import { useNotificationContext } from '../../../../../../contexts/NotificationContext';
import { translations } from '../../../../../../locales/i18n';
import { DepositContext } from '../../../contexts/deposit-context';
import { useValidateFederators } from '../../../hooks/useValidateFederators';
import { URIType } from '../../../types';
import { TransferPolicies } from './TransferPolicies';
const config = resolveConfig(tailwindConfig);
export const AddressForm = () => {
    var _a, _b;
    const { address } = useContext(DepositContext);
    const { addNotification } = useNotificationContext();
    const { isSignatureValid, loading } = useValidateFederators();
    const formattedAddress = useMemo(() => prettyTx(address, 12, 12), [address]);
    const copyAddress = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield navigator.clipboard.writeText(address);
        addNotification({
            type: NotificationType.success,
            title: t(translations.fastBtc.addressForm.copyAddressSuccess),
            content: '',
            dismissible: true,
            id: nanoid(),
        });
    }), [addNotification, address]);
    const hasValidationBeenUnsuccessful = useMemo(() => !loading && !isSignatureValid, [isSignatureValid, loading]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "full" },
            React.createElement(TransferPolicies, null),
            React.createElement(Paragraph, { className: "font-medium mb-2" },
                t(translations.fastBtc.addressForm.title),
                ":"),
            React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50  text-xs relative" },
                (loading || !isSignatureValid) && (React.createElement("div", { className: "absolute w-full h-full backdrop-blur-md" },
                    loading && React.createElement(Lottie, { animation: "progressDots" }),
                    hasValidationBeenUnsuccessful && (React.createElement("div", { className: "flex items-center h-full" },
                        React.createElement(Paragraph, { children: t(translations.fastBtc.addressForm.invalidSignatures), style: ParagraphStyle.tall, className: "px-14 flex-items-center text-center" }))))),
                React.createElement("div", { className: "p-6" },
                    React.createElement("div", { className: classNames('h-48 justify-center items-center flex rounded', {
                            'bg-white': !hasValidationBeenUnsuccessful,
                        }) },
                        React.createElement(QRCode, { value: `${URIType.BITCOIN}${address}`, renderAs: "svg", bgColor: "white", fgColor: ((_b = (_a = config === null || config === void 0 ? void 0 : config.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b['gray-80']) || 'black', includeMargin: false, className: "rounded w-36 h-36" })),
                    React.createElement("div", { className: "flex justify-between mt-5 items-center bg-gray-70 border rounded border-gray-50 py-2 pl-3 pr-2 text-gray-30" },
                        React.createElement("div", null, formattedAddress),
                        React.createElement("span", Object.assign({ className: "cursor-pointer rounded", onClick: copyAddress }, applyDataAttr('funding-receive-address-copy')),
                            React.createElement(Icon, { icon: IconNames.COPY }))))))));
};
