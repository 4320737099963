// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2944)">
<path d="M9.99996 0C15.5227 0 19.9999 4.47718 19.9999 9.99996C19.9999 15.5227 15.5227 19.9999 9.99996 19.9999C4.47726 19.9999 0 15.5228 0 9.99996C0 4.47718 4.47706 0 9.99996 0Z" fill="black"/>
<path d="M10.3551 15.9823C10.3202 16.044 10.2696 16.0953 10.2085 16.1309C10.1473 16.1666 10.0777 16.1855 10.0069 16.1855C9.93604 16.1855 9.86648 16.1666 9.8053 16.1309C9.74412 16.0953 9.69351 16.044 9.65866 15.9823L3.8642 5.73053C3.8298 5.66967 3.81195 5.60086 3.81242 5.53095C3.8129 5.46105 3.83169 5.39249 3.86692 5.33211C3.90216 5.27173 3.9526 5.22163 4.01322 5.18682C4.07384 5.152 4.14253 5.13369 4.21244 5.13369L15.8013 5.13369C15.8712 5.13369 15.9399 5.152 16.0005 5.18682C16.0611 5.22163 16.1116 5.27173 16.1468 5.33211C16.182 5.39249 16.2008 5.46105 16.2013 5.53095C16.2018 5.60086 16.1839 5.66967 16.1495 5.73053L10.3551 15.9823Z" fill="#E9EAE9"/>
<path d="M10.0069 11.6261C11.386 11.6261 12.504 10.5081 12.504 9.129C12.504 7.7499 11.386 6.63193 10.0069 6.63193C8.6278 6.63193 7.50983 7.7499 7.50983 9.129C7.50983 10.5081 8.6278 11.6261 10.0069 11.6261Z" fill="black"/>
<path d="M10.0069 10.5861C10.8117 10.5861 11.464 9.93373 11.464 9.12901C11.464 8.32429 10.8117 7.67194 10.0069 7.67194C9.20222 7.67194 8.54987 8.32429 8.54987 9.12901C8.54987 9.93373 9.20222 10.5861 10.0069 10.5861Z" fill="#E9EAE9"/>
</g>
<defs>
<clipPath id="clip0_9856_2944">
<rect width="19.9999" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
