import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, Heading, HeadingType, Icon, IconNames, StatusType, } from '@sovryn/ui';
import { StatusIcon } from '../../../../../2_molecules/StatusIcon/StatusIcon';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BITCOIN } from '../../../../../../constants/currencies';
import { BTC_IN_SATOSHIS } from '../../../../../../constants/general';
import { useAccount } from '../../../../../../hooks/useAccount';
import { translations } from '../../../../../../locales/i18n';
import { getBtcExplorerUrl, getRskExplorerUrl, } from '../../../../../../utils/helpers';
import { formatValue, fromWei, toWei } from '../../../../../../utils/math';
import { DEPOSIT_FEE_SATS } from '../../../constants';
import { DepositContext, DepositStep } from '../../../contexts/deposit-context';
const translation = translations.fastBtc.receive.statusScreen;
const rskExplorerUrl = getRskExplorerUrl();
const btcExplorerUrl = getBtcExplorerUrl();
export const StatusScreen = ({ onClose }) => {
    const { account } = useAccount();
    const { step, depositTx, transferTx, depositRskTransactionHash } = useContext(DepositContext);
    const isProcessing = useMemo(() => step === DepositStep.PROCESSING, [step]);
    const feeAmount = useMemo(() => toWei(DEPOSIT_FEE_SATS / BTC_IN_SATOSHIS), []);
    const amount = useMemo(() => {
        if (depositTx) {
            return depositTx.value;
        }
        if (transferTx) {
            return toWei((transferTx === null || transferTx === void 0 ? void 0 : transferTx.value) || 0)
                .add(feeAmount)
                .toString();
        }
        return 0;
    }, [depositTx, transferTx, feeAmount]);
    const receiveAmount = useMemo(() => fromWei(toWei(amount).sub(feeAmount)), [amount, feeAmount]);
    const items = useMemo(() => [
        {
            label: t(translation.to),
            value: (React.createElement(TxIdWithNotification, { value: account, href: `${rskExplorerUrl}/address/${account}` })),
        },
        {
            label: t(translation.sending),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(amount), 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.serviceFee),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(fromWei(feeAmount)), 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.receiving),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(receiveAmount), 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.bitcoinTxId),
            value: depositTx ? (React.createElement(React.Fragment, null,
                React.createElement(TxIdWithNotification, { value: depositTx.txHash, href: `${btcExplorerUrl}/tx/${depositTx.txHash}` }))) : (React.createElement(Icon, { icon: IconNames.PENDING })),
        },
        {
            label: t(translation.rootstockTxId),
            value: depositRskTransactionHash ? (React.createElement(React.Fragment, null,
                React.createElement(TxIdWithNotification, { value: depositRskTransactionHash, href: `${rskExplorerUrl}/tx/${depositRskTransactionHash}` }))) : (React.createElement(Icon, { icon: IconNames.PENDING })),
        },
    ], [
        account,
        amount,
        depositRskTransactionHash,
        depositTx,
        feeAmount,
        receiveAmount,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-center" },
            React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-6" }, isProcessing
                ? t(translation.statusTitleProcessing)
                : t(translation.statusTitleComplete)),
            React.createElement("div", { className: "mb-6" },
                React.createElement(StatusIcon, { status: isProcessing ? StatusType.pending : StatusType.success, dataAttribute: "funding-receive-status" })),
            React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                    'mb-3': index !== items.length - 1,
                }), key: label },
                React.createElement("span", null,
                    label,
                    " "),
                React.createElement("span", null, value)))))),
        React.createElement(Button, { text: t(translations.common.buttons.done), onClick: onClose, disabled: isProcessing, className: "mt-8 w-full", dataAttribute: "funding-receive-done" })));
};
