var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { BigNumber, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { Button, ErrorBadge, ErrorLevel, Icon, IconNames, StatusType, } from '@sovryn/ui';
import { APPROVAL_FUNCTION } from '../../../../../constants/general';
import { useAccount } from '../../../../../hooks/useAccount';
import { useCurrentChain } from '../../../../../hooks/useChainStore';
import { useNativeAssetBalance } from '../../../../../hooks/useNativeAssetBalance';
import { translations } from '../../../../../locales/i18n';
import { findNativeAsset } from '../../../../../utils/asset';
import { sleep } from '../../../../../utils/helpers';
import { fromWei, toWei } from '../../../../../utils/math';
import { TransactionReceiptStatus, } from '../../TransactionStepDialog.types';
import { isMessageSignatureRequest, isSignTransactionDataRequest, isTransactionRequest, isTypedDataRequest, } from '../../helpers';
import { sendOrSimulateTx } from '../../utils';
import { TransactionStep } from '../TransactionStep/TransactionStep';
export const TransactionSteps = ({ transactions, onSuccess, onClose, gasPrice, onTxStatusChange, setTxTrigger, }) => {
    const chainId = useCurrentChain();
    const [stepData, setStepData] = useState([]);
    const [step, setStep] = useState(-1);
    const [error, setError] = useState(false);
    const [estimatedGasFee, setEstimatedGasFee] = useState(0);
    const { balance: nativeBalance, loading } = useNativeAssetBalance(chainId);
    const { account } = useAccount();
    const hasEnoughBalance = useMemo(() => account && !loading && nativeBalance.sub(estimatedGasFee).gt(0), [account, loading, nativeBalance, estimatedGasFee]);
    const nativeAsset = useMemo(() => findNativeAsset(chainId), [chainId]);
    useEffect(() => {
        const initialize = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const steps = [];
            for (let i = 0; i < transactions.length; i++) {
                const { request } = transactions[i];
                const item = {
                    transaction: transactions[i],
                    receipt: {
                        status: TransactionReceiptStatus.pending,
                        request,
                    },
                    config: {},
                };
                if (isTransactionRequest(request)) {
                    const { contract, fnName, args: requestArgs, gasLimit, value, } = request;
                    const args = [...requestArgs];
                    if (fnName === APPROVAL_FUNCTION) {
                        args[1] = ethers.constants.MaxUint256;
                    }
                    item.config.gasLimit =
                        gasLimit !== null && gasLimit !== void 0 ? gasLimit : (yield contract.estimateGas[fnName](...[...args, { value: value !== null && value !== void 0 ? value : 0 }])
                            .then(gas => gas.toString())
                            .catch(() => BigNumber.from(6000000).toString()));
                    item.config.gasLimit &&
                        setEstimatedGasFee(Number(fromWei(toWei(gasPrice)
                            .mul(item.config.gasLimit)
                            .div(Math.pow(10, 9)))));
                    item.config.amount =
                        fnName === APPROVAL_FUNCTION ? requestArgs[1] : undefined;
                    item.config.unlimitedAmount =
                        fnName === APPROVAL_FUNCTION ? false : undefined;
                    item.config.gasPrice = (_a = request.gasPrice) !== null && _a !== void 0 ? _a : gasPrice;
                }
                else if (isSignTransactionDataRequest(request)) {
                    const { signer, data, to, gasLimit, value } = request;
                    item.config.gasLimit =
                        gasLimit !== null && gasLimit !== void 0 ? gasLimit : (yield signer
                            .estimateGas({
                            to,
                            data,
                            value: value !== null && value !== void 0 ? value : 0,
                        })
                            .catch(() => BigNumber.from(6000000))).toString();
                    item.config.gasPrice = (_b = request.gasPrice) !== null && _b !== void 0 ? _b : gasPrice;
                }
                steps.push(item);
            }
            setStepData(steps);
        });
        if (gasPrice) {
            initialize();
        }
    }, [gasPrice, transactions]);
    const updateConfig = useCallback((index, config) => {
        setStepData(items => {
            if (items[index]) {
                const updatedItems = [...items];
                updatedItems[index] = Object.assign(Object.assign({}, updatedItems[index]), { config });
                if (config.gasLimit && config.gasPrice) {
                    const gasFee = fromWei(toWei(config.gasPrice)
                        .mul(config.gasLimit)
                        .div(Math.pow(10, 9)));
                    setEstimatedGasFee(Number(gasFee));
                }
                return updatedItems;
            }
            return items;
        });
    }, [setStepData]);
    const updateReceipt = useCallback((index, receipt) => {
        setStepData(items => {
            if (items[index]) {
                const copy = [...items];
                copy[index].receipt = receipt;
                return copy;
            }
            return items;
        });
    }, [setStepData]);
    const handleUpdates = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const items = [...stepData];
        yield Promise.all(items.map((item, i) => __awaiter(void 0, void 0, void 0, function* () {
            if (item.transaction.updateHandler) {
                item.transaction.request = yield item.transaction.updateHandler(item.transaction.request, items.map(i => i.receipt));
            }
            return item;
        })));
        setStepData(items);
    }), [stepData]);
    const submit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        try {
            let i = 0;
            if (error) {
                setError(false);
                i = step;
            }
            for (; i < transactions.length; i++) {
                setStep(i);
                const config = stepData[i].config;
                const { request } = transactions[i];
                if (isTransactionRequest(request)) {
                    const args = [...request.args];
                    if (request.fnName === APPROVAL_FUNCTION) {
                        args[1] = config.unlimitedAmount
                            ? ethers.constants.MaxUint256
                            : config.amount;
                    }
                    const tx = yield sendOrSimulateTx(request, args, config);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.pending,
                        request,
                        response: tx.hash,
                    });
                    (_b = (_a = transactions[i]).onStart) === null || _b === void 0 ? void 0 : _b.call(_a, tx.hash);
                    (_d = (_c = transactions[i]).onChangeStatus) === null || _d === void 0 ? void 0 : _d.call(_c, StatusType.pending);
                    yield tx.wait();
                    (_f = (_e = transactions[i]).onChangeStatus) === null || _f === void 0 ? void 0 : _f.call(_e, StatusType.success);
                    (_h = (_g = transactions[i]).onComplete) === null || _h === void 0 ? void 0 : _h.call(_g, tx.hash);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: tx.hash,
                    });
                    onTxStatusChange === null || onTxStatusChange === void 0 ? void 0 : onTxStatusChange(StatusType.success);
                    yield handleUpdates();
                }
                else if (isMessageSignatureRequest(request)) {
                    const signature = yield request.signer.signMessage(request.message);
                    (_k = (_j = transactions[i]).onChangeStatus) === null || _k === void 0 ? void 0 : _k.call(_j, StatusType.success);
                    (_m = (_l = transactions[i]).onComplete) === null || _m === void 0 ? void 0 : _m.call(_l, signature);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: signature,
                    });
                    yield handleUpdates();
                }
                else if (isTypedDataRequest(request)) {
                    const signature = yield request.signer._signTypedData(request.domain, request.types, request.values);
                    const verifiedAddress = ethers.utils.verifyTypedData(request.domain, request.types, request.values, signature);
                    if (verifiedAddress.toLowerCase() !==
                        (yield request.signer.getAddress()).toLowerCase()) {
                        throw new Error('Failed to verify signature. ');
                    }
                    (_p = (_o = transactions[i]).onChangeStatus) === null || _p === void 0 ? void 0 : _p.call(_o, StatusType.success);
                    (_r = (_q = transactions[i]).onComplete) === null || _r === void 0 ? void 0 : _r.call(_q, signature);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: signature,
                    });
                    yield handleUpdates();
                }
                else if (isSignTransactionDataRequest(request)) {
                    const gasLimit = config.gasLimit
                        ? (_s = config.gasLimit) === null || _s === void 0 ? void 0 : _s.toString()
                        : undefined;
                    const gasPrice = config.gasPrice
                        ? parseUnits(((_t = config.gasPrice) === null || _t === void 0 ? void 0 : _t.toString()) || '0', 9)
                        : undefined;
                    const from = yield request.signer.getAddress();
                    const nonce = yield request.signer.getTransactionCount();
                    const tx = yield request.signer.sendTransaction({
                        data: request.data,
                        to: request.to,
                        value: request.value,
                        gasLimit,
                        gasPrice,
                        from,
                        nonce,
                    });
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.pending,
                        request,
                        response: tx.hash,
                    });
                    (_v = (_u = transactions[i]).onStart) === null || _v === void 0 ? void 0 : _v.call(_u, tx.hash);
                    (_x = (_w = transactions[i]).onChangeStatus) === null || _x === void 0 ? void 0 : _x.call(_w, StatusType.pending);
                    yield tx.wait();
                    (_z = (_y = transactions[i]).onChangeStatus) === null || _z === void 0 ? void 0 : _z.call(_y, StatusType.success);
                    (_1 = (_0 = transactions[i]).onComplete) === null || _1 === void 0 ? void 0 : _1.call(_0, tx.hash);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: tx.hash,
                    });
                    onTxStatusChange === null || onTxStatusChange === void 0 ? void 0 : onTxStatusChange(StatusType.success);
                    yield handleUpdates();
                }
                else {
                    // unknown type
                    (_3 = (_2 = transactions[i]).onChangeStatus) === null || _3 === void 0 ? void 0 : _3.call(_2, StatusType.error);
                }
                if (i < transactions.length - 1) {
                    // allow wallet to update before next transaction
                    yield sleep(500);
                }
            }
            setStep(transactions.length);
            setTimeout(() => setTxTrigger(nanoid()), 1000);
        }
        catch (error) {
            onTxStatusChange === null || onTxStatusChange === void 0 ? void 0 : onTxStatusChange(StatusType.error);
            console.error('error:', error);
            (_5 = (_4 = transactions[0]).onChangeStatus) === null || _5 === void 0 ? void 0 : _5.call(_4, StatusType.error);
            handleUpdates();
            setError(true);
        }
    }), [
        error,
        transactions,
        step,
        stepData,
        updateReceipt,
        onTxStatusChange,
        handleUpdates,
        setTxTrigger,
    ]);
    const getStatus = useCallback((i) => {
        if (i < step) {
            return StatusType.success;
        }
        if (i === step) {
            if (error) {
                return StatusType.error;
            }
            return StatusType.pending;
        }
        return StatusType.idle;
    }, [error, step]);
    const isLoading = useMemo(() => step > -1 && step < transactions.length && !error && !loading, [error, step, transactions.length, loading]);
    useEffect(() => {
        if (transactions.length > 0 && transactions.length === step) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
    }, [onSuccess, step, transactions.length]);
    const getConfig = useCallback((i) => stepData[i].config, [stepData]);
    const getReceipt = useCallback((i) => stepData[i].receipt, [stepData]);
    if (!stepData.length) {
        return (React.createElement(Icon, { size: 30, className: "animate-spin mx-auto my-4", icon: IconNames.PENDING }));
    }
    return (React.createElement("div", { className: "flex flex-col gap-4" },
        transactions.map((tx, i) => (React.createElement(TransactionStep, { key: i, transaction: tx, step: i + 1, status: getStatus(i), isLoading: isLoading, config: getConfig(i), receipt: getReceipt(i), updateConfig: (config) => updateConfig(i, config), gasPrice: gasPrice }))),
        !isLoading && transactions.length > step && (React.createElement(React.Fragment, null,
            React.createElement(Button, { className: classNames('w-full', hasEnoughBalance && 'mt-7'), text: t(translations.common.buttons[error ? 'retry' : 'confirm']), onClick: submit, dataAttribute: `tx-dialog-${error ? 'retry' : 'confirm'}`, disabled: !hasEnoughBalance }),
            !hasEnoughBalance && (React.createElement(ErrorBadge, { level: ErrorLevel.Critical, message: t(translations.transactionStep.notEnoughBalance, {
                    asset: nativeAsset.symbol,
                }) })))),
        onClose && transactions.length === step && (React.createElement(Button, { text: t(translations.common.buttons.done), onClick: onClose, className: "w-full mt-7", dataAttribute: "tx-dialog-done" }))));
};
