var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defer } from 'react-router-dom';
import { decimalic } from '../../../utils/math';
import { getZeroProvider } from './utils/zero-provider';
export const zeroPageLoader = () => __awaiter(void 0, void 0, void 0, function* () {
    const { provider, ethers } = yield getZeroProvider();
    return defer({
        liquity: ethers,
        provider,
        deferredData: Promise.all([
            ethers.getPrice().then(result => decimalic(result.toString())),
        ]),
    });
});
