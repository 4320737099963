import React, { useCallback, useState, useMemo } from 'react';
import { Select } from '@sovryn/ui';
import { BorrowHistoryType } from './BorrowHistory.types';
import { borrowHistoryOptions } from './BorrowHistory.utils';
import { CloseWithDepositLoanFrame } from './components/CloseWithDepositLoanFrame/CloseWithDepositLoanFrame';
import { CloseWithSwapLoanFrame } from './components/CloseWithSwapLoanFrame/CloseWithSwapLoanFrame';
import { CollateralSurplusHistoryFrame } from './components/CollateralSurplusFrame/CollateralSurplusHistoryFrame';
import { DepositCollateralLoanFrame } from './components/DepositCollateralLoanFrame/DepositCollateralLoanFrame';
import { LiquidationLoanFrame } from './components/LiquidationLoanFrame/LiquidationLoanFrame';
import { NewLoanHistoryFrame } from './components/NewLoanFrame/NewLoanHistoryFrame';
import { RolloverLoanHistoryFrame } from './components/RolloverLoanFrame/RolloverLoanHistoryFrame';
import { TransactionHistoryFrame } from './components/TransactionHistoryFrame';
export const BorrowHistory = () => {
    const [selectedHistoryType, setSelectedHistoryType] = useState(BorrowHistoryType.lineOfCredit);
    const onChangeRewardHistory = useCallback((value) => {
        setSelectedHistoryType(value);
    }, []);
    const selectComponent = useMemo(() => (React.createElement(Select, { dataAttribute: `borrow-history-${selectedHistoryType}`, value: selectedHistoryType, onChange: onChangeRewardHistory, options: borrowHistoryOptions, className: "min-w-36 w-full lg:w-auto" })), [selectedHistoryType, onChangeRewardHistory]);
    const renderHistoryFrame = useMemo(() => {
        switch (selectedHistoryType) {
            case BorrowHistoryType.lineOfCredit:
                return (React.createElement(TransactionHistoryFrame, null, selectComponent));
            case BorrowHistoryType.newLoan:
                return React.createElement(NewLoanHistoryFrame, null, selectComponent);
            case BorrowHistoryType.depositCollateralLoan:
                return (React.createElement(DepositCollateralLoanFrame, null, selectComponent));
            case BorrowHistoryType.closeWithSwapLoan:
                return (React.createElement(CloseWithSwapLoanFrame, null, selectComponent));
            case BorrowHistoryType.closeWithDepositLoan:
                return (React.createElement(CloseWithDepositLoanFrame, null, selectComponent));
            case BorrowHistoryType.liquidationLoan:
                return React.createElement(LiquidationLoanFrame, null, selectComponent);
            case BorrowHistoryType.rolloversLoan:
                return (React.createElement(RolloverLoanHistoryFrame, null, selectComponent));
            case BorrowHistoryType.collateralSurplus:
                return (React.createElement(CollateralSurplusHistoryFrame, null, selectComponent));
            default:
                return null;
        }
    }, [selectedHistoryType, selectComponent]);
    return React.createElement(React.Fragment, null, renderHistoryFrame);
};
