import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { t } from 'i18next';
import { ErrorBadge, ErrorLevel, OrderDirection, Pagination, Paragraph, ParagraphSize, Table, } from '@sovryn/ui';
import { RSK_CHAIN_ID } from '../../../../../config/chains';
import { AmountRenderer } from '../../../../2_molecules/AmountRenderer/AmountRenderer';
import { AssetRenderer } from '../../../../2_molecules/AssetRenderer/AssetRenderer';
import { ExportCSV } from '../../../../2_molecules/ExportCSV/ExportCSV';
import { TxIdWithNotification } from '../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BTC_RENDER_PRECISION } from '../../../../../constants/currencies';
import { DEFAULT_HISTORY_FRAME_PAGE_SIZE } from '../../../../../constants/general';
import { useBlockNumber } from '../../../../../hooks/useBlockNumber';
import { useMaintenance } from '../../../../../hooks/useMaintenance';
import { translations } from '../../../../../locales/i18n';
import { getChainById } from '../../../../../utils/chain';
import { dateFormat } from '../../../../../utils/helpers';
import { useGetCloseDepositLoans } from './hooks/useGetCloseDepositLoans';
const pageSize = DEFAULT_HISTORY_FRAME_PAGE_SIZE;
export const CloseWithDepositLoanFrame = ({ children, }) => {
    const [page, setPage] = useState(0);
    const chain = getChainById(RSK_CHAIN_ID);
    const { value: block } = useBlockNumber();
    const [orderOptions, setOrderOptions] = useState({
        orderBy: 'timestamp',
        orderDirection: OrderDirection.Desc,
    });
    const { data, loading, refetch, exportData } = useGetCloseDepositLoans(pageSize, page, orderOptions);
    useEffect(() => {
        refetch();
    }, [refetch, block]);
    const generateRowTitle = useCallback((row) => (React.createElement(Paragraph, { size: ParagraphSize.small, className: "text-left" },
        t(translations.borrowHistory.transactionTypes.closeDeposit),
        ' - ',
        dateFormat(row.timestamp))), []);
    const columns = useMemo(() => [
        {
            id: 'timestamp',
            title: t(translations.common.tables.columnTitles.timestamp),
            cellRenderer: (tx) => dateFormat(tx.timestamp),
            sortable: true,
        },
        {
            id: 'transactionType',
            title: t(translations.common.tables.columnTitles.transactionType),
            cellRenderer: () => t(translations.borrowHistory.transactionTypes.closeDeposit),
        },
        {
            id: 'collateralWithdrawn',
            title: t(translations.loanHistory.table.collateralWithdrawn),
            cellRenderer: tx => (React.createElement("div", { className: "inline-flex gap-1 items-center" },
                React.createElement(AmountRenderer, { value: tx.collateralWithdrawAmount, precision: BTC_RENDER_PRECISION }),
                React.createElement(AssetRenderer, { asset: tx.collateralToken }))),
        },
        {
            id: 'debtRepaid',
            title: t(translations.loanHistory.table.debtRepaid),
            cellRenderer: tx => (React.createElement("div", { className: "inline-flex gap-1 items-center" },
                React.createElement(AmountRenderer, { value: tx.repayAmount, precision: BTC_RENDER_PRECISION }),
                React.createElement(AssetRenderer, { asset: tx.loanToken }))),
        },
        {
            id: 'loanId',
            title: t(translations.loanHistory.table.loanID),
            cellRenderer: tx => (React.createElement(TxIdWithNotification, { href: "", value: tx.loanId, dataAttribute: "loan-id" })),
        },
        {
            id: 'transactionID',
            title: t(translations.common.tables.columnTitles.transactionID),
            cellRenderer: (tx) => (React.createElement(TxIdWithNotification, { href: `${chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl}/tx/${tx.hash}`, value: tx.hash, dataAttribute: "transaction-id" })),
        },
    ], [chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl]);
    const onPageChange = useCallback((value) => {
        if ((data === null || data === void 0 ? void 0 : data.length) < pageSize && value > page) {
            return;
        }
        setPage(value);
    }, [page, data]);
    const isNextButtonDisabled = useMemo(() => !loading && (data === null || data === void 0 ? void 0 : data.length) < pageSize, [loading, data]);
    useEffect(() => {
        setPage(0);
    }, [orderOptions]);
    const { checkMaintenance, States } = useMaintenance();
    const exportLocked = checkMaintenance(States.ZERO_EXPORT_CSV);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-row items-center gap-4 mb-7 flex justify-center lg:justify-start" },
            children,
            React.createElement("div", { className: "flex-row items-center ml-2 gap-4 hidden lg:inline-flex" },
                React.createElement(ExportCSV, { getData: exportData, filename: "close with deposit loans", disabled: !data || data.length === 0 || exportLocked }),
                exportLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.featureDisabled) })))),
        React.createElement("div", { className: "bg-gray-80 py-4 px-4 rounded" },
            React.createElement(Table, { setOrderOptions: setOrderOptions, orderOptions: orderOptions, columns: columns, rows: data, rowTitle: generateRowTitle, isLoading: loading, className: "bg-gray-80 text-gray-10 lg:px-6 lg:py-4", noData: t(translations.common.tables.noData), loadingData: t(translations.common.tables.loading), dataAttribute: "close-with-deposits-table" }),
            React.createElement(Pagination, { page: page, className: "lg:pb-6 mt-3 lg:mt-6 justify-center lg:justify-start", onChange: onPageChange, itemsPerPage: pageSize, isNextButtonDisabled: isNextButtonDisabled, dataAttribute: "close-with-deposits-pagination" }))));
};
