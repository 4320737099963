import React from 'react';
import { t } from 'i18next';
import { applyDataAttr } from '@sovryn/ui';
import { getTokenDisplayName } from '../../../constants/tokens';
import { translations } from '../../../locales/i18n';
import { AmountRenderer } from '../AmountRenderer/AmountRenderer';
export const MaxButton = ({ value, onClick, precision = 4, token, dataAttribute, label = t(translations.common.max), chainId, }) => (React.createElement("button", Object.assign({ onClick: onClick, className: "text-xs font-medium underline whitespace-nowrap" }, applyDataAttr(dataAttribute)),
    `(${label} `,
    React.createElement(AmountRenderer, { value: value, precision: precision, suffix: getTokenDisplayName(token, chainId), useTooltip: false }),
    `)`));
