import React, { useCallback, useEffect, useState } from 'react';
import { useAccount } from '../../../hooks/useAccount';
import { sharedState, } from '../../../store/rxjs/shared-state';
import { EmailNotificationSettingsDialog } from '../EmailNotificationSettingsDialog/EmailNotificationSettingsDialog';
import { FastBtcDialog } from '../FastBtcDialog/FastBtcDialog';
import { RuneBridgeDialog } from '../RuneBridgeDialog/RuneBridgeDialog';
export const SharedStateProvider = ({ children, }) => {
    const { account } = useAccount();
    const [fastBtcDialog, setFastBtcDialog] = useState(sharedState.get().fastBtcDialog);
    const [runeBridgeDialog, setRuneBridgeDialog] = useState(sharedState.get().runeBridgeDialog);
    const [emailNotificationSettingsDialog, setEmailNotificationSettingsDialog] = useState(sharedState.get().emailNotificationSettingsDialog);
    useEffect(() => {
        const fastBtcDialogSubscription = sharedState
            .select('fastBtcDialog')
            .subscribe(setFastBtcDialog);
        return () => {
            fastBtcDialogSubscription.unsubscribe();
        };
    }, []);
    useEffect(() => {
        const emailNotificationSettingsDialogSubscription = sharedState
            .select('emailNotificationSettingsDialog')
            .subscribe(setEmailNotificationSettingsDialog);
        return () => {
            emailNotificationSettingsDialogSubscription.unsubscribe();
        };
    }, []);
    useEffect(() => {
        const runeBridgeDialogSubscription = sharedState
            .select('runeBridgeDialog')
            .subscribe(setRuneBridgeDialog);
        return () => {
            runeBridgeDialogSubscription.unsubscribe();
        };
    }, []);
    const handleFastBtcDialogClose = useCallback(() => sharedState.actions.closeFastBtcDialog(), []);
    const handleEmailNotificationSettingsDialogClose = useCallback(() => sharedState.actions.closeEmailNotificationSettingsDialog(), []);
    const handleRuneBridgeDialogClose = useCallback(() => sharedState.actions.closeRuneBridgeDialog(), []);
    // Close the dialog if there is no wallet connected
    useEffect(() => {
        if (fastBtcDialog.isOpen && !account) {
            handleFastBtcDialogClose();
        }
    }, [account, fastBtcDialog.isOpen, handleFastBtcDialogClose]);
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(FastBtcDialog, { isOpen: fastBtcDialog.isOpen, shouldHideSend: fastBtcDialog.shouldHideSend, onClose: handleFastBtcDialogClose, step: fastBtcDialog.step }),
        React.createElement(EmailNotificationSettingsDialog, { isOpen: emailNotificationSettingsDialog.isOpen, onClose: handleEmailNotificationSettingsDialogClose }),
        React.createElement(RuneBridgeDialog, { isOpen: runeBridgeDialog.isOpen, onClose: handleRuneBridgeDialogClose, step: runeBridgeDialog.step })));
};
