import React, { useMemo } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Heading, HeadingType, Link } from '@sovryn/ui';
import { HELPDESK_LINK } from '../../../../constants/links';
import { translations } from '../../../../locales/i18n';
export const Instructions = ({ isReceive }) => {
    const translation = useMemo(() => isReceive
        ? translations.fastBtc.receiveInstructions
        : translations.fastBtc.sendInstructions, [isReceive]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium leading-[1.375rem]" },
            t(translation.title),
            ":"),
        React.createElement("ul", { className: "list-disc list-inside text-xs leading-5 font-medium text-gray-30 mt-4 mb-12" },
            React.createElement("li", { className: "mb-4" }, t(translation.line1)),
            !isReceive && (React.createElement("li", { className: "mb-4" }, t(translations.fastBtc.sendInstructions.supportedFormats))),
            React.createElement("li", { className: "mb-4" }, t(translation.line2)),
            React.createElement("li", { className: "mb-4" }, t(translation.line3)),
            React.createElement("li", { className: "mb-4" }, t(translation.line4, { hours: 1.5 })),
            React.createElement("li", null,
                React.createElement(Trans, { i18nKey: t(translation.line5), tOptions: { hours: 1.5 }, components: [
                        React.createElement(Link, { text: t(translation.line5cta), href: HELPDESK_LINK }),
                    ] })))));
};
