var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { formatUnits } from 'ethers/lib/utils';
import { getProvider } from '@sovryn/ethers-provider';
import { Decimal } from '@sovryn/utils';
import { RSK_CHAIN_ID } from '../config/chains';
import { decimalic } from '../utils/math';
export const useGasPrice = (chainId = RSK_CHAIN_ID) => {
    const [gasPrice, setGasPrice] = useState(Decimal.ZERO);
    useEffect(() => {
        const getGasPrice = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const gas = formatUnits(yield getProvider(chainId).getGasPrice(), 'gwei');
                setGasPrice(decimalic(gas));
            }
            catch (error) { }
        });
        getGasPrice();
    }, [chainId]);
    return gasPrice;
};
