import { contracts } from '@sovryn/contracts';
import { ChainIds, getNetworkByChainId, } from '@sovryn/ethers-provider';
import { RSK_CHAIN_ID } from '../config/chains';
export const findAsset = (asset, chainId) => {
    var _a;
    return (_a = contracts.assets[getNetworkByChainId(chainId)]) === null || _a === void 0 ? void 0 : _a.find(item => item.symbol.toLowerCase() === asset.toLowerCase());
};
export const findAssetByAddress = (address, chainId) => {
    var _a;
    return (_a = contracts.assets[getNetworkByChainId(chainId)]) === null || _a === void 0 ? void 0 : _a.find(item => item.address.toLowerCase() === address.toLowerCase());
};
export const findNativeAsset = (chainId) => { var _a; return (_a = contracts.assets[getNetworkByChainId(chainId)]) === null || _a === void 0 ? void 0 : _a.find(item => item.isNative); };
export const listAssetsOfChain = (chainId) => contracts.assets[getNetworkByChainId(chainId)] || [];
export const COMMON_SYMBOLS = {
    BTC: 'BTC',
    WBTC: 'WBTC',
    SOV: 'SOV',
    OSSOV: 'OSSOV',
    DLLR: 'DLLR',
    ZUSD: 'ZUSD',
    XUSD: 'XUSD',
    DOC: 'DOC',
    BPRO: 'BPRO',
    RUSDT: 'RUSDT',
    ETH: 'ETH',
};
export const compareAssets = (asset1, asset2) => (asset1 === null || asset1 === void 0 ? void 0 : asset1.toUpperCase()) === (asset2 === null || asset2 === void 0 ? void 0 : asset2.toUpperCase());
export const maybeWrappedAsset = (asset, chainId = RSK_CHAIN_ID) => {
    var _a;
    asset = asset.toUpperCase();
    if (asset === COMMON_SYMBOLS.BTC &&
        [ChainIds.RSK_MAINNET, ChainIds.RSK_TESTNET].includes(chainId)) {
        return COMMON_SYMBOLS.WBTC;
    }
    return ((_a = findAsset(asset, chainId)) === null || _a === void 0 ? void 0 : _a.symbol) || asset.toUpperCase();
};
export const maybeUnwrappedAsset = (asset, chainId = RSK_CHAIN_ID) => {
    var _a;
    asset = asset.toUpperCase();
    if (compareAssets(asset, COMMON_SYMBOLS.WBTC) &&
        [ChainIds.RSK_MAINNET, ChainIds.RSK_TESTNET].includes(chainId)) {
        return COMMON_SYMBOLS.BTC;
    }
    return ((_a = findAsset(asset, chainId)) === null || _a === void 0 ? void 0 : _a.symbol) || asset.toUpperCase();
};
