// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2994)">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF5800"/>
<path d="M8.47998 13.08C7.76202 12.7698 7.13545 12.281 6.65998 11.66L7.92998 10.43C8.46434 11.0347 9.15706 11.478 9.92998 11.71C10.4 11.82 10.66 11.71 10.72 11.51C11 10.66 7.40998 10 7.99998 7.62C8.11758 7.1285 8.4069 6.69517 8.81577 6.39814C9.22465 6.1011 9.72619 5.95991 10.23 6L11.11 2.44L9.74998 2.13L9.20998 4.28L6.05998 3.5L5.70998 4.91C5.70998 4.91 6.70998 5.13 6.70998 5.14C6.89238 5.16257 7.0586 5.25597 7.17273 5.40003C7.28686 5.5441 7.33975 5.72728 7.31998 5.91L5.79998 11.81C5.78182 11.8703 5.75172 11.9264 5.71147 11.9749C5.67122 12.0233 5.62163 12.0632 5.56566 12.0921C5.50968 12.121 5.44847 12.1384 5.38565 12.1432C5.32283 12.148 5.25969 12.1401 5.19998 12.12L4.19998 11.89L3.53998 13.39L5.24998 13.81L6.71998 14.19L6.17998 16.37L7.52998 16.71L8.47998 13.08Z" fill="#F5F5F5"/>
<path d="M13.33 10C13.7786 9.90732 14.1845 9.67053 14.486 9.3257C14.7875 8.98087 14.968 8.54693 15 8.09C15.0681 7.53141 14.9411 6.96644 14.6404 6.4908C14.3397 6.01515 13.8838 5.65805 13.35 5.48H13.29L13.86 3.18L12.5 2.84L12 4.92L11.67 6.26C12.5128 6.53092 13.2253 7.10451 13.67 7.87L12.33 8.87C11.9286 8.3804 11.3825 8.03036 10.77 7.87C10.32 7.77 10.06 7.87 10 8.11C9.84 8.81 13.37 9.6 12.83 11.87C12.56 12.99 11.52 13.7 9.83 13.5L9.47 14.96L9 17.07L10.38 17.4L10.95 15.11H11.12C12.76 15.24 13.98 14.82 14.56 13.17C14.7026 12.8835 14.7838 12.5704 14.7984 12.2507C14.8131 11.931 14.7609 11.6117 14.6451 11.3133C14.5293 11.015 14.3526 10.7441 14.1261 10.5179C13.8997 10.2918 13.6285 10.1154 13.33 10Z" fill="#F5F5F5"/>
</g>
<defs>
<clipPath id="clip0_9856_2994">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
