var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from 'react';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { NotificationType } from '@sovryn/ui';
import { BITCOIN, BTC_RENDER_PRECISION, } from '../../../../../../constants/currencies';
import { EXPORT_RECORD_LIMIT } from '../../../../../../constants/general';
import { useNotificationContext } from '../../../../../../contexts/NotificationContext';
import { translations } from '../../../../../../locales/i18n';
import { zeroClient } from '../../../../../../utils/clients';
import { useGetCollSurplusChangesLazyQuery, useGetCollSurplusChangesQuery, } from '../../../../../../utils/graphql/zero/generated';
import { dateFormat } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
export const useGetCollateralSurplusWithdrawals = (account, pageSize, page, orderOptions) => {
    const { addNotification } = useNotificationContext();
    const config = useMemo(() => ({
        skip: page * pageSize,
        pageSize,
        orderBy: orderOptions.orderBy || undefined,
        orderDirection: orderOptions.orderDirection || undefined,
        filters: {
            user: (account === null || account === void 0 ? void 0 : account.toLowerCase()) || '',
            collSurplusAfter: '0',
        },
    }), [page, orderOptions, pageSize, account]);
    const { loading, data, refetch } = useGetCollSurplusChangesQuery({
        variables: config,
        client: zeroClient,
    });
    const collSurplusChanges = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.collSurplusChanges.map(tx => ({
            id: tx.id,
            sequenceNumber: tx.sequenceNumber,
            collSurplusChange: tx.collSurplusChange,
            user: tx.user.id,
            timestamp: tx.transaction.timestamp,
            hash: tx.transaction.id,
        }));
    }, [data]);
    const [getCollSurplusChanges] = useGetCollSurplusChangesLazyQuery({
        client: zeroClient,
    });
    const exportData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield getCollSurplusChanges({
            variables: Object.assign(Object.assign({}, config), { skip: 0, pageSize: EXPORT_RECORD_LIMIT }),
        });
        let list = (data === null || data === void 0 ? void 0 : data.collSurplusChanges) || [];
        if (!list || !list.length) {
            addNotification({
                type: NotificationType.warning,
                title: t(translations.common.tables.actions.noDataToExport),
                content: '',
                dismissible: true,
                id: nanoid(),
            });
        }
        return list.map(tx => ({
            timestamp: dateFormat(tx.transaction.timestamp),
            collateralChange: formatValue(Math.abs(Number(tx.collSurplusChange)), BTC_RENDER_PRECISION),
            collateralChangeToken: BITCOIN,
            transactionType: t(translations.collateralSurplusHistory.table.withdrawSurplus),
            transactionID: tx.transaction.id,
        }));
    }), [addNotification, config, getCollSurplusChanges]);
    return { loading, data: collSurplusChanges, refetch, exportData };
};
