// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11380_373)">
<path d="M16.3079 4.19732L15.7588 4.74647C17.0691 6.17944 17.7958 8.05091 17.7958 9.99267C17.7958 11.9344 17.0691 13.8059 15.7588 15.2389L16.3079 15.788C17.7652 14.2074 18.5743 12.1362 18.5743 9.98627C18.5743 7.83636 17.7652 5.76518 16.3079 4.18451V4.19732Z" fill="#5A5564"/>
<path d="M4.75935 4.24491C6.19232 2.93455 8.06379 2.20788 10.0056 2.20788C11.9473 2.20788 13.8188 2.93455 15.2518 4.24491L15.8009 3.69576C14.2202 2.2385 12.1491 1.42944 9.99915 1.42944C7.84924 1.42944 5.77806 2.2385 4.19739 3.69576L4.75935 4.24491Z" fill="#5A5564"/>
<path d="M4.24491 15.2444C2.9361 13.8117 2.21039 11.9414 2.21039 10.0009C2.21039 8.06044 2.9361 6.19011 4.24491 4.75746L3.69576 4.20831C2.2385 5.78898 1.42944 7.86016 1.42944 10.0101C1.42944 12.16 2.2385 14.2312 3.69576 15.8118L4.24491 15.2444Z" fill="#5A5564"/>
<path d="M15.2443 15.7514C13.8113 17.0618 11.9399 17.7884 9.99811 17.7884C8.05635 17.7884 6.18488 17.0618 4.75191 15.7514L4.20276 16.3006C5.78343 17.7578 7.85461 18.5669 10.0045 18.5669C12.1544 18.5669 14.2256 17.7578 15.8063 16.3006L15.2443 15.7514Z" fill="#5A5564"/>
<path d="M13.467 8.16769C13.3571 7.0218 12.3687 6.63739 11.1185 6.52024V4.94235H10.1519V6.49095C9.89751 6.49095 9.63758 6.49095 9.37948 6.49095V4.94235H8.4203V6.53122H6.45984V7.56545C6.45984 7.56545 7.17373 7.55264 7.16275 7.56545C7.29147 7.55129 7.42066 7.58765 7.5231 7.66687C7.62554 7.74609 7.69323 7.86198 7.7119 7.99013V12.3394C7.70915 12.3846 7.69736 12.4288 7.67724 12.4693C7.65712 12.5099 7.62907 12.546 7.59475 12.5755C7.56107 12.6056 7.52166 12.6286 7.47887 12.6431C7.43609 12.6575 7.39083 12.6632 7.3458 12.6597C7.35861 12.6707 6.64289 12.6597 6.64289 12.6597L6.45984 13.8148H8.402V15.4293H9.3685V13.8386H10.141V15.4219H11.1093V13.8257C12.7421 13.7269 13.8807 13.3242 14.0234 11.7957C14.1388 10.5656 13.5603 10.0165 12.6359 9.795C13.1979 9.51859 13.5457 9.00605 13.467 8.16769ZM12.1124 11.6054C12.1124 12.8062 10.0549 12.6689 9.39962 12.6689V10.5382C10.0549 10.54 12.1124 10.3515 12.1124 11.6054ZM11.6639 8.60334C11.6639 9.70164 9.94693 9.56802 9.40145 9.56802V7.63135C9.94693 7.63135 11.6639 7.45928 11.6639 8.60334Z" fill="#F09242"/>
<path d="M9.99817 20C8.02052 19.9996 6.08737 19.4129 4.44316 18.3139C2.79895 17.215 1.51751 15.6532 0.760859 13.826C0.00420949 11.9988 -0.19367 9.98829 0.192241 8.04865C0.578152 6.10901 1.53052 4.32735 2.92894 2.92894C4.32735 1.53052 6.10901 0.578152 8.04865 0.192241C9.98829 -0.19367 11.9988 0.00420949 13.826 0.760859C15.6532 1.51751 17.215 2.79895 18.3139 4.44316C19.4129 6.08737 19.9996 8.02052 20 9.99817C20.0002 11.3117 19.7417 12.6124 19.2391 13.826C18.7366 15.0396 17.9999 16.1423 17.0711 17.0711C16.1423 17.9999 15.0396 18.7366 13.826 19.2391C12.6124 19.7417 11.3117 20.0002 9.99817 20ZM9.99817 0.779796C8.17614 0.781244 6.39544 1.32282 4.88113 2.33606C3.36682 3.3493 2.18688 4.78873 1.49046 6.47242C0.794033 8.1561 0.612381 10.0085 0.968463 11.7954C1.32454 13.5822 2.20237 15.2235 3.491 16.5116C4.77963 17.7997 6.4212 18.6769 8.20824 19.0323C9.99528 19.3876 11.8476 19.2052 13.531 18.5082C15.2144 17.8111 16.6533 16.6305 17.666 15.1158C18.6786 13.6011 19.2195 11.8202 19.2202 9.99817C19.2207 8.78716 18.9825 7.58792 18.5191 6.46904C18.0558 5.35016 17.3765 4.33358 16.52 3.47743C15.6635 2.62128 14.6467 1.94235 13.5276 1.47947C12.4085 1.01659 11.2092 0.778833 9.99817 0.779796Z" fill="#282138"/>
</g>
<defs>
<clipPath id="clip0_11380_373">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
