import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Heading, HeadingType, Link } from '@sovryn/ui';
import { HELPDESK_LINK } from '../../../../constants/links';
import { translations } from '../../../../locales/i18n';
import { MIN_POSTAGE_SATS, MIN_POSTAGE_BTC, ORD_WALLET_LINK, } from '../constants';
export const Instructions = ({ isReceive }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium leading-[1.375rem]" },
            t(translations.runeBridge.instructions.title),
            ":"),
        React.createElement("ul", { className: "list-disc list-inside text-xs leading-5 font-medium text-gray-30 mt-4 mb-12" },
            isReceive ? (React.createElement(React.Fragment, null,
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.receive.onlyRunes)),
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.receive.onlySupportedRunes)),
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.receive.postage, {
                    postageSat: MIN_POSTAGE_SATS,
                    postageBtc: MIN_POSTAGE_BTC,
                })))) : (React.createElement(React.Fragment, null,
                React.createElement("li", { className: "mb-4" },
                    React.createElement(Trans, { i18nKey: t(translations.runeBridge.instructions.send.wallet), components: [
                            React.createElement(Link, { text: t(translations.runeBridge.instructions.send.walletCta), href: ORD_WALLET_LINK }),
                        ] })),
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.send.supportedFormats)),
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.send.minAmount)),
                React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.send.maxAmount)))),
            React.createElement("li", { className: "mb-4" }, t(translations.runeBridge.instructions.upToNHours, { hours: 1.5 })),
            React.createElement("li", null,
                React.createElement(Trans, { i18nKey: t(translations.runeBridge.instructions.createSupportTicket), tOptions: { hours: 1.5 }, components: [
                        React.createElement(Link, { text: t(translations.runeBridge.instructions.createSupportTicketCta), href: HELPDESK_LINK }),
                    ] })))));
};
