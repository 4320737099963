import React, { createContext, useContext, useEffect, useState, } from 'react';
import { getProvider } from '@sovryn/ethers-provider';
import { CrocEnv } from '@sovryn/sdex';
import { useAccount } from '../hooks/useAccount';
import { useCurrentChain } from '../hooks/useChainStore';
const defaultContextValue = {
    croc: undefined,
};
const CrocContext = createContext(defaultContextValue);
export const useCrocContext = () => useContext(CrocContext);
export const CrocContextProvider = ({ children }) => {
    const chainId = useCurrentChain();
    const { account, signer } = useAccount();
    const [croc, setCroc] = useState(defaultContextValue.croc);
    useEffect(() => {
        if (!croc && account) {
            setCroc(new CrocEnv(getProvider(chainId), signer));
        }
    }, [croc, account, signer, chainId]);
    return (React.createElement(CrocContext.Provider, { value: { croc } }, children));
};
