import React from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { applyDataAttr } from '@sovryn/ui';
import { RSK_CHAIN_ID } from '../../../config/chains';
import { DappLocked } from '../../1_atoms/DappLocked/DappLocked';
import { Header, Footer } from '../../3_organisms';
import { UnclaimcedVestingAlert } from '../../5_pages/RewardsPage/components/Vesting/components/UnclaimedVestingAlert/UnclaimedVestingAlert';
import { useIsDappLocked } from '../../../hooks/maintenances/useIsDappLocked';
import { useAccount } from '../../../hooks/useAccount';
import { useCurrentChain } from '../../../hooks/useChainStore';
export const PageContainer = ({ className, contentClassName, dataAttribute, }) => {
    const dappLocked = useIsDappLocked();
    const { account } = useAccount();
    const chainID = useCurrentChain();
    return (React.createElement("div", Object.assign({ className: classNames('flex flex-col flex-grow', className) }, applyDataAttr(dataAttribute)),
        dappLocked ? (React.createElement(DappLocked, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Header, null),
            account && chainID === RSK_CHAIN_ID && React.createElement(UnclaimcedVestingAlert, null),
            React.createElement("div", { className: classNames('my-2 px-4 flex flex-grow', contentClassName) },
                React.createElement(Outlet, null)))),
        React.createElement("div", { className: "shrink" },
            React.createElement(Footer, null))));
};
