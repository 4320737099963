var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useState } from 'react';
import { BigNumber, constants } from 'ethers';
import { getAssetData } from '@sovryn/contracts';
import { getProvider } from '@sovryn/ethers-provider';
import { Decimal } from '@sovryn/utils';
import { RSK_CHAIN_ID } from '../config/chains';
import { idHash, observeCall, startCall, } from '../store/rxjs/provider-cache';
import { fromWei, decimalic } from '../utils/math';
import { useBlockNumber } from './useBlockNumber';
import { useIsMounted } from './useIsMounted';
import { useWalletConnect } from './useWalletConnect';
export const useAssetBalance = (asset, chainId = RSK_CHAIN_ID, address = null, walletIndex = 0, options) => {
    const { value: block } = useBlockNumber(chainId);
    const { wallets } = useWalletConnect();
    const isMounted = useIsMounted();
    const account = useMemo(() => { var _a, _b; return address === null ? (_b = (_a = wallets[walletIndex]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address : address; }, [address, walletIndex, wallets]);
    const [state, setState] = useState({
        balance: Decimal.ZERO,
        weiBalance: '0',
        bigNumberBalance: BigNumber.from(0),
        loading: false,
        error: null,
        hashedArgs: '',
    });
    useEffect(() => {
        if (!isMounted() || !account) {
            return;
        }
        let sub;
        const runAsync = () => __awaiter(void 0, void 0, void 0, function* () {
            const tokenDetails = yield getAssetData(asset, chainId);
            const hashedArgs = idHash([
                'balance',
                chainId,
                tokenDetails.address,
                tokenDetails.address === constants.AddressZero
                    ? 'nativeBalance'
                    : 'balanceOf',
                account,
            ]);
            if (hashedArgs === state.hashedArgs || state.loading) {
                return;
            }
            setState(prevState => (Object.assign(Object.assign({}, prevState), { hashedArgs, loading: true })));
            sub = observeCall(hashedArgs).subscribe(e => {
                const decimal = decimalic(fromWei(e.result.value === null ? 0 : e.result.value, tokenDetails.decimals));
                const bn = decimal.toBigNumber();
                setState(Object.assign(Object.assign({}, e.result), { weiBalance: bn.toString(), bigNumberBalance: bn, balance: decimal, decimalPrecision: tokenDetails.decimals, loading: false }));
            });
            const callback = tokenDetails.isNative
                ? () => getProvider(chainId)
                    .getBalance(account)
                    .then(result => result.toString())
                : () => tokenDetails
                    .contract(getProvider(chainId))
                    .balanceOf(account)
                    .then(result => result.toString());
            startCall(hashedArgs, callback, Object.assign(Object.assign({}, options), { blockNumber: (options === null || options === void 0 ? void 0 : options.blockNumber) || block }));
        });
        runAsync().catch(e => setState(prev => (Object.assign(Object.assign({}, prev), { weiBalance: '0', balance: Decimal.ZERO, bigNumberBalance: BigNumber.from(0), loading: false, error: e, hashedArgs: '' }))));
        return () => {
            if (sub) {
                sub.unsubscribe();
            }
        };
    }, [
        account,
        asset,
        chainId,
        isMounted,
        options,
        block,
        state.hashedArgs,
        state.loading,
    ]);
    return useMemo(() => (Object.assign(Object.assign({}, state), { weiBalance: state.weiBalance === null ? '0' : state.weiBalance, bigNumberBalance: state.weiBalance === null ? BigNumber.from(0) : state.bigNumberBalance })), [state]);
};
