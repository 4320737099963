import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, Heading, HeadingType, Icon, IconNames, StatusType, } from '@sovryn/ui';
import { StatusIcon } from '../../../../../2_molecules/StatusIcon/StatusIcon';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { translations } from '../../../../../../locales/i18n';
import { getBtcExplorerUrl } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
import { useSendFlowContext } from '../../../contexts/sendflow';
import { useChainDetails } from '../../../hooks/useChainDetails';
const translation = translations.runeBridge.send.confirmationScreens;
const getTitle = (status) => {
    switch (status) {
        case StatusType.error:
            return t(translation.statusTitleFailed);
        case StatusType.success:
            return t(translation.statusTitleComplete);
        default:
            return t(translation.statusTitleProcessing);
    }
};
const btcExplorerUrl = getBtcExplorerUrl();
export const StatusScreen = ({ from, to, amount, feesPaid, receiveAmount, txHash, txStatus, onClose, onRetry, }) => {
    const { selectedToken } = useSendFlowContext();
    const { chainName, baseCurrency, explorerUrl } = useChainDetails();
    const items = useMemo(() => [
        {
            label: t(translation.from),
            value: (React.createElement(TxIdWithNotification, { value: from, href: `${explorerUrl}/address/${from}` })),
        },
        {
            label: t(translation.to),
            value: (React.createElement(TxIdWithNotification, { value: to, href: `${btcExplorerUrl}/address/${to}` })),
        },
        {
            label: t(translation.sending),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(amount), 8),
                " ",
                selectedToken.symbol)),
        },
        {
            label: t(translation.serviceFee),
            value: (React.createElement(React.Fragment, null,
                formatValue(feesPaid.rune, 8),
                " ",
                selectedToken.symbol,
                " +",
                ' ',
                formatValue(feesPaid.baseCurrency, 8),
                " ",
                baseCurrency)),
        },
        {
            label: t(translation.receiving),
            value: (React.createElement(React.Fragment, null,
                formatValue(receiveAmount, 8),
                " ",
                selectedToken.symbol)),
        },
        {
            label: t(translation.hash, { chainName }),
            value: txHash ? (React.createElement(TxIdWithNotification, { value: txHash, href: `${explorerUrl}/tx/${txHash}` })) : (React.createElement(Icon, { icon: IconNames.PENDING })),
        },
        // We don't yet have an API for this
        // {
        //   label: t(translation.bitcoinTxId),
        //   value: bitcoinTxHash ? (
        //     <TxIdWithNotification
        //       value={bitcoinTxHash}
        //       href={`${btcExplorerUrl}/tx/${bitcoinTxHash}`}
        //     />
        //   ) : (
        //     <Icon icon={IconNames.PENDING} />
        //   ),
        // },
    ], [
        amount,
        baseCurrency,
        feesPaid.baseCurrency,
        feesPaid.rune,
        from,
        receiveAmount,
        selectedToken.symbol,
        chainName,
        to,
        txHash,
        explorerUrl,
    ]);
    const status = useMemo(() => {
        if (txStatus !== StatusType.success) {
            return txStatus;
        }
        return StatusType.success;
    }, [txStatus]);
    const hasTransactionFailed = useMemo(() => status === StatusType.error, [status]);
    const isDoneButtonDisabled = useMemo(() => status === StatusType.pending, [status]);
    return (React.createElement("div", { className: "text-center" },
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-6" }, getTitle(status)),
        React.createElement("div", { className: "mb-6" },
            React.createElement(StatusIcon, { status: status, dataAttribute: "funding-send-status" })),
        React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                'mb-3': index !== items.length - 1,
            }), key: label },
            React.createElement("span", null,
                label,
                " "),
            React.createElement("span", null, value))))),
        React.createElement(Button, { text: t(translations.common.buttons[hasTransactionFailed ? 'retry' : 'done']), onClick: hasTransactionFailed ? onRetry : onClose, disabled: isDoneButtonDisabled, className: "mt-8 w-full", dataAttribute: `funding-send-${hasTransactionFailed ? 'retry' : 'done'}` })));
};
