// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11462_155)">
<path d="M9.99999 19.4737C15.2322 19.4737 19.4737 15.35 19.4737 10.2632C19.4737 5.17632 15.2322 1.05263 9.99999 1.05263C4.76782 1.05263 0.526306 5.17632 0.526306 10.2632C0.526306 15.35 4.76782 19.4737 9.99999 19.4737Z" fill="white"/>
<path d="M10 0.922105C11.7954 0.922105 13.5506 1.45451 15.0434 2.45201C16.5363 3.4495 17.6998 4.86727 18.3869 6.52604C19.074 8.18481 19.2537 10.0101 18.9035 11.771C18.5532 13.5319 17.6886 15.1495 16.419 16.419C15.1495 17.6886 13.532 18.5532 11.771 18.9035C10.0101 19.2537 8.18481 19.074 6.52604 18.3869C4.86728 17.6998 3.4495 16.5363 2.45201 15.0434C1.45452 13.5506 0.92211 11.7954 0.92211 10C0.922668 7.59256 1.87927 5.28389 3.58158 3.58158C5.28389 1.87926 7.59257 0.922663 10 0.922105M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.34591 0.761209 6.17317C0.00433282 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0Z" fill="#00A651"/>
<path d="M7.2658 11.5479C7.04092 11.9496 6.70556 12.2784 6.29948 12.4953C5.84087 12.7313 5.33039 12.8486 4.81474 12.8363H2.79211V7.48578H4.81369C5.33066 7.47318 5.84277 7.58814 6.30475 7.82052C6.71011 8.03259 7.04565 8.35716 7.27106 8.75526C7.50203 9.18144 7.61743 9.66065 7.6058 10.1453C7.61106 10.6338 7.49418 11.116 7.2658 11.5479V11.5479ZM5.86948 11.2847C6.13851 10.9719 6.28644 10.5729 6.28644 10.1603C6.28644 9.74763 6.13851 9.34866 5.86948 9.03578C5.54555 8.75369 5.12343 8.61071 4.69474 8.63789H4.08106V11.6953H4.69369C5.12342 11.7116 5.54331 11.5641 5.86843 11.2826L5.86948 11.2847Z" fill="#00A651"/>
<path d="M11.1242 8.82474C11.4493 8.99633 11.7188 9.25704 11.901 9.57632C12.0878 9.93079 12.1853 10.3254 12.1853 10.7261C12.1853 11.1267 12.0878 11.5213 11.901 11.8758C11.7206 12.1964 11.4506 12.4576 11.1242 12.6274C10.7769 12.806 10.3904 12.895 9.99998 12.8863C9.60984 12.892 9.22412 12.8031 8.87577 12.6274C8.54752 12.4579 8.27548 12.1968 8.0926 11.8758C7.90587 11.5213 7.80829 11.1267 7.80829 10.7261C7.80829 10.3254 7.90587 9.93079 8.0926 9.57632C8.27368 9.25393 8.5462 8.9924 8.87577 8.82474C9.22331 8.64675 9.60959 8.55777 9.99998 8.56579C10.3902 8.55944 10.7761 8.64832 11.1242 8.82474V8.82474ZM9.3805 9.95526C9.19743 10.1709 9.10667 10.45 9.12787 10.7321C9.10657 11.0123 9.19745 11.2895 9.3805 11.5026C9.46054 11.5878 9.55753 11.6552 9.66523 11.7005C9.77293 11.7458 9.88894 11.7681 10.0058 11.7658C10.1217 11.7686 10.237 11.7466 10.3437 11.7012C10.4505 11.6559 10.5463 11.5881 10.6247 11.5026C10.789 11.278 10.8775 11.007 10.8775 10.7287C10.8775 10.4504 10.789 10.1794 10.6247 9.95474C10.5463 9.86924 10.4505 9.80151 10.3437 9.75612C10.237 9.71073 10.1217 9.68873 10.0058 9.69158C9.88848 9.68577 9.77139 9.70639 9.66316 9.75194C9.55493 9.7975 9.45833 9.86681 9.3805 9.95474V9.95526Z" fill="#00A651"/>
<path d="M16.6963 7.97842C17.1479 8.34493 17.4546 8.85994 17.5616 9.43158H16.1932C16.0995 9.19581 15.9365 8.99397 15.7258 8.85263C15.5047 8.70753 15.2449 8.63267 14.9805 8.63789C14.8061 8.63243 14.6328 8.66719 14.474 8.73948C14.3152 8.81176 14.1752 8.91963 14.0648 9.05473C13.8196 9.37173 13.6964 9.76615 13.7174 10.1663C13.6953 10.5647 13.8188 10.9575 14.0648 11.2716C14.1765 11.4047 14.3169 11.5109 14.4755 11.582C14.6341 11.6531 14.8068 11.6873 14.9805 11.6821C15.2387 11.6895 15.493 11.6178 15.7093 11.4767C15.9256 11.3356 16.0937 11.1319 16.1911 10.8926H17.5595C17.4508 11.4619 17.1443 11.9744 16.6942 12.3395C16.2106 12.7127 15.6116 12.9048 15.0011 12.8826C14.5228 12.8918 14.0507 12.7741 13.6327 12.5416C13.2417 12.3151 12.9256 11.9792 12.7232 11.5753C12.5111 11.1337 12.401 10.6501 12.401 10.1603C12.401 9.67041 12.5111 9.18683 12.7232 8.74526C12.9293 8.34393 13.2445 8.00897 13.6327 7.77895C14.1116 7.52441 14.652 7.40847 15.1932 7.44418C15.7345 7.47988 16.255 7.6658 16.6963 7.98105V7.97842Z" fill="#00A651"/>
</g>
<defs>
<clipPath id="clip0_11462_155">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
