import { createContext, useContext } from 'react';
import { BigNumber } from 'ethers';
import { noop } from '@sovryn/ui';
export var SendFlowStep;
(function (SendFlowStep) {
    SendFlowStep[SendFlowStep["MAIN"] = 0] = "MAIN";
    SendFlowStep[SendFlowStep["AMOUNT"] = 1] = "AMOUNT";
    SendFlowStep[SendFlowStep["ADDRESS"] = 2] = "ADDRESS";
    SendFlowStep[SendFlowStep["REVIEW"] = 3] = "REVIEW";
    SendFlowStep[SendFlowStep["CONFIRM"] = 4] = "CONFIRM";
    SendFlowStep[SendFlowStep["PROCESSING"] = 5] = "PROCESSING";
    SendFlowStep[SendFlowStep["COMPLETED"] = 6] = "COMPLETED";
})(SendFlowStep || (SendFlowStep = {}));
export var AddressValidationState;
(function (AddressValidationState) {
    AddressValidationState["NONE"] = "NONE";
    AddressValidationState["LOADING"] = "LOADING";
    AddressValidationState["VALID"] = "VALID";
    AddressValidationState["INVALID"] = "INVALID";
})(AddressValidationState || (AddressValidationState = {}));
export const defaultValue = {
    step: SendFlowStep.MAIN,
    amount: '',
    address: '',
    limits: {
        min: 0,
        max: 0,
        flatFeeBaseCurrency: 0,
        flatFeeBaseCurrencyWei: BigNumber.from(0),
        dynamicFeeTokens: 0,
        flatFeeTokens: 0,
        loading: true,
    },
    selectedToken: {
        symbol: '',
        balance: '0',
        decimals: 18,
        name: '',
        tokenContractAddress: '',
    },
    addressValidationState: AddressValidationState.NONE,
    set: noop,
};
export const SendFlowContext = createContext(defaultValue);
export const useSendFlowContext = () => {
    return useContext(SendFlowContext);
};
