var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { BigNumber } from 'ethers';
import { t } from 'i18next';
import { StatusType } from '@sovryn/ui';
import { useTransactionContext } from '../../../../../../contexts/TransactionContext';
import { useAccount } from '../../../../../../hooks/useAccount';
import { useGetProtocolContract } from '../../../../../../hooks/useGetContract';
import { translations } from '../../../../../../locales/i18n';
import { fromWei, toWei } from '../../../../../../utils/math';
import { TransactionType } from '../../../../TransactionStepDialog/TransactionStepDialog.types';
import { GAS_LIMIT_FAST_BTC_WITHDRAW } from '../../../constants';
import { WithdrawContext, WithdrawStep, } from '../../../contexts/withdraw-context';
import { ReviewScreen } from './ReviewScreen';
import { StatusScreen } from './StatusScreen';
export const ConfirmationScreens = ({ onClose, }) => {
    const { account } = useAccount();
    const { step, address, amount, set } = useContext(WithdrawContext);
    const { setTransactions, setTitle, setIsOpen } = useTransactionContext();
    const [txHash, setTxHash] = useState(undefined);
    const [txStatus, setTxStatus] = useState(StatusType.idle);
    const [currentFeeWei, setCurrentFeeWei] = useState(BigNumber.from(0));
    const fastBtcBridgeContract = useGetProtocolContract('fastBtcBridge');
    const weiAmount = useMemo(() => toWei(amount), [amount]);
    const getCurrentFeeWei = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const currentFeeWei = yield (fastBtcBridgeContract === null || fastBtcBridgeContract === void 0 ? void 0 : fastBtcBridgeContract.calculateCurrentFeeWei(weiAmount));
        setCurrentFeeWei(currentFeeWei);
    }), [fastBtcBridgeContract, weiAmount]);
    useEffect(() => {
        getCurrentFeeWei().then();
    }, [getCurrentFeeWei]);
    const feesPaid = useMemo(() => currentFeeWei && currentFeeWei.gt(0) ? Number(fromWei(currentFeeWei)) : 0, [currentFeeWei]);
    const receiveAmount = useMemo(() => Number(fromWei(currentFeeWei && currentFeeWei.gt(0)
        ? weiAmount.sub(currentFeeWei)
        : weiAmount)), [currentFeeWei, weiAmount]);
    const handleConfirm = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (fastBtcBridgeContract) {
            setTransactions([
                {
                    title: t(translations.fastBtc.send.txDialog.sendBTC),
                    request: {
                        type: TransactionType.signTransaction,
                        contract: fastBtcBridgeContract,
                        fnName: 'transferToBtc',
                        args: [address],
                        value: toWei(amount),
                        gasLimit: GAS_LIMIT_FAST_BTC_WITHDRAW,
                    },
                    onStart: hash => {
                        setTxHash(hash);
                        set(prevState => (Object.assign(Object.assign({}, prevState), { step: WithdrawStep.CONFIRM })));
                        setIsOpen(false);
                    },
                    onChangeStatus: setTxStatus,
                },
            ]);
            setTitle(t(translations.fastBtc.send.txDialog.title));
            setIsOpen(true);
        }
    }), [
        address,
        amount,
        fastBtcBridgeContract,
        set,
        setIsOpen,
        setTitle,
        setTransactions,
    ]);
    const handleRetry = useCallback(() => {
        set(prevState => (Object.assign(Object.assign({}, prevState), { step: WithdrawStep.REVIEW })));
        handleConfirm();
    }, [handleConfirm, set]);
    if (step === WithdrawStep.REVIEW) {
        return (React.createElement(ReviewScreen, { onConfirm: handleConfirm, feesPaid: feesPaid, receiveAmount: receiveAmount, from: account, to: address, amount: amount }));
    }
    return (React.createElement(StatusScreen, { txHash: txHash, txStatus: txStatus, onClose: onClose, feesPaid: feesPaid, receiveAmount: receiveAmount, from: account, to: address, amount: amount, onRetry: handleRetry }));
};
