import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Menu, applyDataAttr } from '@sovryn/ui';
import { MenuItemWithRouter } from '../../../../2_molecules/MenuItemWithRouter/MenuItemWithRouter';
import { NavLink } from '../../../../2_molecules/NavLink/NavLink';
import { useIsMobile } from '../../../../../hooks/useIsMobile';
import { NavDropdown } from './NavDropdown';
export const NavItem = ({ item, onClick }) => {
    const { isMobile } = useIsMobile();
    const { pathname } = useLocation();
    const isPathActive = useCallback((path) => pathname.startsWith(path), [pathname]);
    return item.submenu ? (React.createElement(NavDropdown, { text: item.text, className: classNames('rounded-b-none text-gray-30 font-normal text-sm hover:bg-gray-70 hover:text-gray-10 min-w-auto w-full lg:w-auto lg:rounded', {
            'bg-transparent border-none': !isPathActive(item.url),
            'bg-gray-70 border-gray-70': isMobile,
        }), active: isPathActive(item.url), closeOnClick: !isMobile },
        React.createElement(Menu, { className: "rounded-t-none rounded-b px-2 py-3 lg:rounded lg:p-1" }, item.submenu.map(({ text, label, url }) => (React.createElement(MenuItemWithRouter, { key: text, text: text, label: !isMobile && label, link: url, dataAttribute: `dapp-menu-${text.toLowerCase().replace(' ', '-')}`, isActive: isPathActive(url), className: "no-underline", onClick: onClick })))))) : (React.createElement(NavLink, Object.assign({ key: item.text, to: item.url, end: true, onClick: onClick }, applyDataAttr(`dapp-menu-${item.text.toLowerCase()}`)), item.text));
};
