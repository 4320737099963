// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9856_2933)">
<path d="M17.3109 10.193C17.3109 14.6761 13.6766 18.3104 9.19353 18.3104C4.71044 18.3104 1.07617 14.6761 1.07617 10.193C1.07617 5.70995 4.71044 2.07568 9.19353 2.07568C13.6766 2.07568 17.3109 5.70995 17.3109 10.193Z" fill="#F8941C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3863 10.1931C18.3863 15.2704 14.2704 19.3863 9.19315 19.3863C4.11592 19.3863 0 15.2704 0 10.1931C0 5.11592 4.11592 1 9.19315 1C14.2704 1 18.3863 5.11592 18.3863 10.1931ZM9.19315 18.3105C13.6762 18.3105 17.3105 14.6762 17.3105 10.1931C17.3105 5.71006 13.6762 2.07579 9.19315 2.07579C4.71006 2.07579 1.07579 5.71006 1.07579 10.1931C1.07579 14.6762 4.71006 18.3105 9.19315 18.3105Z" fill="white"/>
<path d="M13.0653 9.1849C13.2272 8.10382 12.4029 7.5227 11.2756 7.13503L11.6413 5.67061L10.7484 5.44849L10.3924 6.87435C10.1577 6.8159 9.91665 6.76083 9.67707 6.70623L10.0356 5.27095L9.14334 5.04883L8.77745 6.51277C8.58321 6.46862 8.39242 6.42498 8.20733 6.379L8.20836 6.37439L6.97709 6.06741L6.73958 7.01952C6.73958 7.01952 7.40201 7.17113 7.38805 7.18046C7.7496 7.27055 7.81499 7.50952 7.80413 7.69892L7.38757 9.36724C7.41247 9.37355 7.44476 9.38269 7.48039 9.39698C7.45061 9.3896 7.41891 9.38154 7.38602 9.37367L6.80214 11.7108C6.75795 11.8204 6.6458 11.985 6.39301 11.9225C6.40196 11.9355 5.74407 11.7608 5.74407 11.7608L5.30078 12.7812L6.46269 13.0704C6.67885 13.1245 6.89067 13.1812 7.09927 13.2344L6.7298 14.7156L7.62162 14.9378L7.98751 13.4723C8.23115 13.5383 8.46759 13.5992 8.69906 13.6566L8.3344 15.1152L9.2273 15.3373L9.59673 13.8589C11.1192 14.1466 12.2641 14.0306 12.7459 12.6557C13.1342 11.5487 12.7266 10.9102 11.9256 10.4938C12.509 10.3595 12.9481 9.97628 13.0653 9.1849ZM11.0253 12.0409C10.7494 13.1479 8.8826 12.5495 8.27737 12.3994L8.76766 10.4371C9.37286 10.5879 11.3137 10.8865 11.0253 12.0409ZM11.3014 9.16886C11.0497 10.1758 9.49599 9.66421 8.99197 9.53878L9.43649 7.75904C9.94051 7.88448 11.5635 8.11859 11.3014 9.16886Z" fill="white"/>
<path d="M15.2355 4.48083C13.8758 5.2449 13.3007 6.95701 13.4182 7.97306C14.378 8.54205 16.2345 8.97174 17.6449 8.17923C19.0046 7.41516 19.5645 5.65944 19.447 4.6434C18.4872 4.07441 16.6459 3.68831 15.2355 4.48083Z" fill="#006E3C"/>
<path d="M17.572 8.22866C18.9415 7.48233 19.5242 5.73404 19.4199 4.71655L13.3483 7.96756C14.3007 8.54898 16.1514 9.00277 17.572 8.22866Z" fill="#06B23C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.4618 4.58727C16.7813 4.52559 16.0847 4.62076 15.4992 4.94978C14.9408 5.26357 14.5254 5.78355 14.2618 6.35714C14.1633 6.57143 14.0891 6.7862 14.0371 6.99159L15.4511 6.20901C15.711 6.06515 16.0383 6.15924 16.1822 6.41916C16.326 6.67907 16.2319 7.0064 15.972 7.15026L14.6223 7.8973C14.8711 7.96809 15.1401 8.02264 15.4186 8.05175C16.1039 8.12338 16.8006 8.03677 17.3815 7.71032C17.9355 7.39901 18.3474 6.87225 18.6089 6.28657C18.818 5.81808 18.9152 5.34661 18.9238 4.97471C18.5322 4.78997 18.0169 4.63759 17.4618 4.58727ZM13.3986 8.57456C13.922 8.83721 14.5943 9.04723 15.3068 9.12171C16.1423 9.20905 17.0791 9.11425 17.9085 8.64818C18.7142 8.19542 19.2622 7.46229 19.5912 6.72508C19.9179 5.99318 20.0533 5.20305 19.9815 4.58161L19.9508 4.3167L19.7214 4.18071C19.1679 3.85258 18.3896 3.59117 17.5589 3.51587C16.7262 3.44038 15.797 3.54842 14.9722 4.01192C14.1708 4.4622 13.6187 5.18031 13.2843 5.9078C13.0195 6.48388 12.8764 7.09789 12.8661 7.63971L12.1799 8.01954C11.9199 8.1634 11.8259 8.49072 11.9697 8.75064C12.1136 9.01055 12.4409 9.10464 12.7008 8.96078L13.3986 8.57456Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_9856_2933">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;
