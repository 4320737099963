import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ChainIds } from '@sovryn/ethers-provider';
import { SUBGRAPH } from '../constants/general';
import { SEPOLIA } from '../constants/infrastructure/sepolia';
import { isRskChain } from './chain';
export const rskClient = new ApolloClient({
    uri: SUBGRAPH.RSK,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export const zeroClient = new ApolloClient({
    uri: SUBGRAPH.ZERO,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export const myntClient = new ApolloClient({
    uri: SUBGRAPH.MYNT,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export const bobClient = new ApolloClient({
    uri: SUBGRAPH.BOB,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export const sepoliaSdexClient = new ApolloClient({
    uri: SEPOLIA.subgraph.testnet,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export const bobStakingClient = new ApolloClient({
    uri: SUBGRAPH.BOB_STAKING,
    cache: new InMemoryCache({
        resultCaching: false,
    }),
});
export var SubgraphType;
(function (SubgraphType) {
    SubgraphType[SubgraphType["GENERAL"] = 0] = "GENERAL";
    SubgraphType[SubgraphType["STAKING"] = 1] = "STAKING";
})(SubgraphType || (SubgraphType = {}));
export const getSubgraphClient = (type, chainId) => {
    if (isRskChain(chainId)) {
        return rskClient;
    }
    if (chainId === ChainIds.SEPOLIA) {
        return sepoliaSdexClient;
    }
    switch (type) {
        case SubgraphType.STAKING:
            return bobStakingClient;
        default:
            return bobClient;
    }
};
