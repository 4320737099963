var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { _getContracts } from '@sovryn-zero/lib-ethers/dist/src/EthersLiquityConnection';
import { Decimal } from '@sovryn/utils';
import { getZeroProvider } from './zero-provider';
let cachedParams;
export const getLiquityBaseParams = () => __awaiter(void 0, void 0, void 0, function* () {
    if (cachedParams) {
        return cachedParams;
    }
    try {
        const { ethers } = yield getZeroProvider();
        const contract = _getContracts(ethers.connection).liquityBaseParams;
        const [minBorrowingFee, maxBorrowingFee] = yield Promise.all([
            contract.BORROWING_FEE_FLOOR(),
            contract.MAX_BORROWING_FEE(),
        ]);
        const minBorrowingFeeRate = Decimal.fromBigNumberString(minBorrowingFee.toString());
        const maxBorrowingFeeRate = Decimal.fromBigNumberString(maxBorrowingFee.toString());
        cachedParams = {
            minBorrowingFeeRate,
            maxBorrowingFeeRate,
        };
        return cachedParams;
    }
    catch (error) {
        throw error;
    }
});
