var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import QRCode from 'qrcode.react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@sovryn/tailwindcss-config';
import { applyDataAttr, ErrorBadge, ErrorLevel, Icon, IconNames, NotificationType, Paragraph, } from '@sovryn/ui';
import { useNotificationContext } from '../../../../../../contexts/NotificationContext';
import { translations } from '../../../../../../locales/i18n';
import { URIType } from '../../../../FastBtcDialog/types';
import { DEPOSIT_FEE_RUNE_PERCENTAGE, MIN_POSTAGE_BTC, } from '../../../constants';
import { useRuneContext } from '../../../contexts/rune';
import { useRuneBridgeLocked } from '../../../hooks/useRuneBridgeLocked';
import { TransferPolicies } from '../../TransferPolicies';
const config = resolveConfig(tailwindConfig);
export const AddressForm = () => {
    var _a, _b;
    const { depositAddress, tokenBalances } = useRuneContext();
    const { addNotification } = useNotificationContext();
    const runeBridgeLocked = useRuneBridgeLocked();
    const copyAddress = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield navigator.clipboard.writeText(depositAddress);
        addNotification({
            type: NotificationType.success,
            title: t(translations.runeBridge.addressForm.copyAddressSuccess),
            content: '',
            dismissible: true,
            id: nanoid(),
        });
    }), [addNotification, depositAddress]);
    if (runeBridgeLocked) {
        return (React.createElement("div", { className: "full" },
            React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.runeBridge) })));
    }
    return (React.createElement("div", { className: "full" },
        React.createElement(TransferPolicies, { serviceFee: `${DEPOSIT_FEE_RUNE_PERCENTAGE} %`, minimumPostage: `${MIN_POSTAGE_BTC} BTC`, supportedRunes: tokenBalances.map(tokenBalance => tokenBalance.name), className: "mb-6" }),
        React.createElement(Paragraph, { className: "font-medium mb-2" },
            t(translations.runeBridge.addressForm.title),
            ":"),
        React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50  text-xs relative" },
            React.createElement("div", { className: "p-6" },
                React.createElement("div", { className: classNames('h-48 justify-center items-center flex rounded bg-white') },
                    React.createElement(QRCode, { value: `${URIType.BITCOIN}${depositAddress}`, renderAs: "svg", bgColor: "white", fgColor: ((_b = (_a = config === null || config === void 0 ? void 0 : config.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b['gray-80']) || 'black', includeMargin: false, className: "rounded w-36 h-36" })),
                React.createElement("div", { className: "flex justify-between mt-5 items-center bg-gray-70 border rounded border-gray-50 py-2 pl-3 pr-2 text-gray-30" },
                    React.createElement("div", { className: "break-words max-w-11/12" }, depositAddress),
                    React.createElement("span", Object.assign({ className: "cursor-pointer rounded", onClick: copyAddress }, applyDataAttr('funding-receive-address-copy')),
                        React.createElement(Icon, { icon: IconNames.COPY })))))));
};
